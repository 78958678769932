import { getLicensingStatus } from 'core/core.selectors';
import { CleanLicensingStatus } from 'entities/company/company.actions';
import { useDefineHomePageByEntitlement, useSetNewCurrentWorkSpace } from 'pages/hall-of-entities/hall-of-entities-mfe.hooks';
import { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

const useRedirectToPropertyDeckOfApps = () => {
  const handleOpenHomePage = useDefineHomePageByEntitlement();
  const licensingStatus = useSelector(getLicensingStatus);
  const { setNewCurrentWorkspaceId } = useSetNewCurrentWorkSpace();
  const { isPropertyPath, workspace, organization, farm } = useGetDataFromPath();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(CleanLicensingStatus());
    if (isPropertyPath && workspace) {
      const obj = {
        workspace: {
          id: workspace,
          name: ''
        }
      };
      setNewCurrentWorkspaceId(obj);
    }
  }, [dispatch, isPropertyPath, setNewCurrentWorkspaceId, workspace]);

  useEffect(() => {
    if (licensingStatus && organization && farm && workspace) {
      handleOpenHomePage({
        companyID: organization,
        propertyID: farm
      });
    }
  }, [farm, handleOpenHomePage, licensingStatus, organization, workspace]);
};

const useGetDataFromPath = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const organization = queryParams.get('organization');
  const farm = queryParams.get('farm');
  const workspace = queryParams.get('workspace');

  return {
    isPropertyPath: Boolean(organization && farm && workspace),
    isWorkspacePath: Boolean(workspace && !organization && !farm),
    isOrganizationPath: Boolean(workspace && organization && !farm),
    organization,
    farm,
    workspace
  };
};

const useGetCorrectPathToRedirect = () => {
  const { isPropertyPath, isWorkspacePath, isOrganizationPath, workspace, organization, farm } = useGetDataFromPath();

  const path = useMemo(() => {
    if (isPropertyPath) {
      return `/redirect?farm=${farm}&organization=${organization}&workspace=${workspace}`;
    }

    if (isWorkspacePath) {
      return `/hall-of-companies/workspace/${workspace}`;
    }

    if (isOrganizationPath) {
      return `/hall-of-companies/workspace/${workspace}/organization/${organization}`;
    }
    return '';
  }, [farm, isOrganizationPath, isPropertyPath, isWorkspacePath, organization, workspace]);

  return path;
};

const useRedirectDeckOfApp = () => {
  const navigate = useNavigate();
  const path = useGetCorrectPathToRedirect();

  useEffect(() => {
    if (path) {
      navigate(path);
    }
  }, [navigate, path]);
};

export { useRedirectDeckOfApp, useRedirectToPropertyDeckOfApps };
