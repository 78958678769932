import { notification } from 'antd';
import { selectSelectedCompany, selectSelectedProperty } from 'core/core.reducer';
import type { Action } from 'core/utils/basic.models';
import { deleteTrapViewDevice } from 'entities/integrations/integrations.service';
import { getSelectedProperty } from 'entities/property/property.reducer';
import { ExternalIntegrationSource, SamplingBehaviour, StaticPointType } from 'entities/static-points/static-points.models';
import { getStaticPoint } from 'entities/static-points/static-points.service';
import _ from 'lodash';
import StaticPointsMapper from 'pages/static-points/map/static-points-mapper';
import type { ActionsObservable, StateObservable } from 'redux-observable';
import { ofType } from 'redux-observable';
import type { AppState } from 'redux/app-state';
import { EMPTY, concat, of } from 'rxjs';
import { catchError, concatMap, exhaustMap, map, mergeMap, withLatestFrom } from 'rxjs/operators';
import sentryService from 'sentry/service';
import type { CreateStationSuccess } from './stations.actions';
import {
  DeleteStationSuccess,
  DeleteTrapViewDevice,
  EditStationFailed,
  EditStationSuccess,
  LoadAnalyticResultsHistogramFailure,
  LoadAnalyticResultsHistogramSuccess,
  LoadStationsFailure,
  LoadStationsSuccess,
  NoAnalyticResultsHistogramToLoad,
  StationsActionsTypes,
  ToggleStationLoading,
  ToggleStationSuccess
} from './stations.actions';
import type {
  AnalyticResultsHistogramResponse,
  DeleteStationType,
  EditStationType,
  HistogramIndicators,
  LoadSeveritiesRequestType,
  LoadSeveritiesSuccessParamsType,
  PayloadStationType,
  Station,
  StationPayloadType
} from './stations.models';
import { StationActionType } from './stations.models';
import { selectStation } from './stations.reducer';
import { editStation, getAllStations, getAnalyticResultsHistogramIdsPaginated, updateStation } from './stations.service';

const { mapStaticPointToStaticPointTemplate, mapStaticPointTemplateToStation } = StaticPointsMapper;

export const handleLoadStationsPaginated = (action$: ActionsObservable<Action<PayloadStationType>>, state$: StateObservable<AppState>) =>
  action$.pipe(
    ofType(StationsActionsTypes.LOAD_STATIONS),
    map(action => action.payload),
    withLatestFrom(state$.pipe(map((state: AppState) => getSelectedProperty(state)))),
    exhaustMap(([payload, selectedProperty]) => {
      const id = payload?.propertyId ?? selectedProperty?.id;

      if (!id) return of(LoadStationsFailure('No property id'));

      return getAllStations(id).pipe(
        map(staticPoints => LoadStationsSuccess(staticPoints)),
        catchError((error: string) => of(LoadStationsFailure(error)))
      );
    })
  );

const calculateAccumulativeMonitorings = (
  allActions: boolean,
  response: AnalyticResultsHistogramResponse[]
): AnalyticResultsHistogramResponse[] => {
  return response.flat(Infinity).map(trap => {
    const isAccumulative = !!trap?.analytic_results_histogram?.result_histogram?.find(
      rh => rh.sampling_behaviour === SamplingBehaviour.ACCUMULATIVE
    );
    if (allActions || !isAccumulative || trap.analytic_results_histogram.result_histogram.length < 2) {
      return trap;
    }
    const histogram = _.sortBy(trap.analytic_results_histogram.result_histogram, 'local_date').reverse();
    const lastHistogramIndicators = histogram[0].indicators.map((indicator: HistogramIndicators) => {
      const previousIndicator = histogram[1].indicators.find(prevIndicator => prevIndicator.id === indicator.id);
      const updatedValue = indicator.value - (previousIndicator?.value ?? 0);
      if (updatedValue < 0) {
        return indicator;
      }
      return {
        ...indicator,
        value: updatedValue
      };
    });
    return {
      ...trap,
      analytic_results_histogram: {
        ...trap.analytic_results_histogram,
        result_histogram: [
          {
            ...histogram[0],
            indicators: lastHistogramIndicators
          }
        ]
      }
    };
  });
};

export const handleLoadAnalyticResultsHistogram = (
  action$: ActionsObservable<Action<LoadSeveritiesRequestType>>,
  state$: StateObservable<AppState>
) =>
  action$.pipe(
    ofType(StationsActionsTypes.LOAD_SEVERITIES),
    map(action => action.payload),
    withLatestFrom(
      state$.pipe(map((state: AppState) => getSelectedProperty(state))),
      state$.pipe(map((state: AppState) => state.entities.stations.analyticResultsHistogramIds)),
      state$.pipe(map((state: AppState) => state.entities.stations.analyticResultsHistogramIdsWithAllActions))
    ),
    concatMap(([payload, selectedProperty, analyticResultsHistogramIds, analyticResultsHistogramIdsWithAllActions]) => {
      const propertyId = selectedProperty?.id;

      let ids = payload?.ids;

      if (!payload?.clearCache) {
        ids = analyticResultsHistogramIdsWithAllActions ? ids?.filter(id => !analyticResultsHistogramIdsWithAllActions?.includes(id)) : ids;

        if (!payload?.allActions) {
          ids = analyticResultsHistogramIds ? ids?.filter(id => !analyticResultsHistogramIds?.includes(id)) : ids;
        }

        if (!ids?.length) {
          return of(NoAnalyticResultsHistogramToLoad(payload?.allActions as unknown as LoadSeveritiesSuccessParamsType));
        }
      }

      if (!propertyId || !payload || !ids?.length)
        return of(
          LoadAnalyticResultsHistogramFailure(
            `Missing some properties to proceed: propertyId: ${propertyId} | payload: ${JSON.stringify(payload)} | ids: ${JSON.stringify(
              ids
            )}`
          )
        );

      return getAnalyticResultsHistogramIdsPaginated(
        propertyId,
        payload.startDate,
        payload.endDate,
        payload.templateId,
        ids,
        payload.allActions
      ).pipe(
        map(response => {
          const severities = calculateAccumulativeMonitorings(payload.allActions, response);
          return LoadAnalyticResultsHistogramSuccess({
            severities,
            allActions: payload.allActions,
            allIds: payload.ids,
            clearCache: payload.clearCache
          });
        }),
        catchError((error: string) => of(LoadAnalyticResultsHistogramFailure(error)))
      );
    })
  );

export const handleCreateStationSuccess = (action$: ActionsObservable<ReturnType<typeof CreateStationSuccess>>) =>
  action$.pipe(
    ofType(StationsActionsTypes.CREATE_STATION_SUCCESS),
    map(action => action.payload),
    concatMap(payload => {
      const callback = payload?.callback;
      const station = payload?.station;

      const successArgs = callback?.notification?.success;
      if (successArgs) {
        notification.success(successArgs);
      }

      callback?.tracking?.installedTrap();
      callback?.clearDrawerAndSelectStation(station);

      return EMPTY;
    })
  );

export const handleDeleteStation = (action$: ActionsObservable<Action<StationPayloadType>>, state$: StateObservable<AppState>) =>
  action$.pipe(
    ofType(StationsActionsTypes.DELETE_STATION),
    map(action => action.payload!),
    withLatestFrom(
      state$.pipe(map((state: AppState) => state.uiState.auth.user!)),
      state$.pipe(map((state: AppState) => selectSelectedCompany(state.uiState.global)!)),
      state$.pipe(map((state: AppState) => selectSelectedProperty(state.uiState.global)!))
    ),
    concatMap(([payload, user, company, property]) => {
      const { stationType, stationId, integrationSource, callback } = payload;
      if (stationType === StaticPointType.TRAP) {
        return updateStation(
          StationActionType.DELETE,
          {
            id: user.id,
            name: user.name
          },
          stationId,
          company,
          property
        ).pipe(
          map(() => {
            return DeleteStationSuccess(stationType, stationId, integrationSource, callback);
          }),
          catchError(() => {
            notification.error(callback.notification.error);
            return EMPTY;
          })
        );
      }
      return EMPTY;
    })
  );

export const handleToggleStation = (action$: ActionsObservable<Action<StationPayloadType>>, state$: StateObservable<AppState>) =>
  action$.pipe(
    ofType(StationsActionsTypes.TOGGLE_STATION),
    mergeMap(action =>
      of(action.payload).pipe(
        withLatestFrom(
          state$.pipe(map((state: AppState) => state.uiState.auth.user)),
          state$.pipe(map((state: AppState) => selectSelectedCompany(state.uiState.global))),
          state$.pipe(map((state: AppState) => selectSelectedProperty(state.uiState.global))),
          state$.pipe(map((state: AppState) => selectStation(action?.payload?.stationId)(state)))
        ),
        mergeMap(([payload, user, company, property, station]) => {
          if (user && company && property && payload?.stationType === StaticPointType.TRAP) {
            return concat(
              of(
                ToggleStationLoading({
                  isLoading: true,
                  stationId: payload.stationId
                })
              ),
              updateStation(
                payload.actionType,
                { id: user.id, name: user.name },
                payload.stationId,
                company,
                property,
                payload.autoCreateTask
              ).pipe(
                map(() => {
                  const isUninstallActionType = payload.actionType === StationActionType.UNINSTALL;
                  if (isUninstallActionType) payload.callback.tracking.disabledTrap();

                  const updateEventDate = new Date().toISOString();

                  const stationPayload = {
                    ...station,
                    last_event_date: updateEventDate,
                    last_event_type: payload.actionType,
                    uninstallation_date: payload.actionType === StationActionType.UNINSTALL ? updateEventDate : null,
                    expiration_date: payload.actionType === StationActionType.REINSTALL ? null : station?.expiration_date
                  };

                  notification.success(payload.callback.notification.success);
                  return ToggleStationSuccess(stationPayload as unknown as Station);
                }),
                catchError(error => {
                  notification.error(payload.callback.notification.error);
                  sentryService.captureException(error, {
                    fileName: 'stations.epics.ts',
                    method: 'handleToggleStation',
                    description: 'Error when trying to change the state of a trap'
                  });
                  return EMPTY;
                })
              ),
              of(
                ToggleStationLoading({
                  isLoading: false,
                  stationId: payload.stationId
                })
              )
            );
          }
          return EMPTY;
        })
      )
    )
  );

export const handleEditStation = (action$: ActionsObservable<ReturnType<EditStationType>>, state$: StateObservable<AppState>) =>
  action$.pipe(
    ofType(StationsActionsTypes.EDIT_STATION),
    map(action => action.payload),
    withLatestFrom(state$.pipe(map((state: AppState) => state.uiState.auth.user))),
    concatMap(([payload, user]) => {
      if (!user) return EMPTY;

      if (payload?.stationType === StaticPointType.TRAP) {
        return getStaticPoint(payload.station.id, payload.station.company_id).pipe(
          map(staticPointTemplate => mapStaticPointToStaticPointTemplate(payload.station, staticPointTemplate)),
          concatMap(staticPointTemplateHandled =>
            editStation({ id: user.id, name: user.name }, staticPointTemplateHandled).pipe(
              map(() => {
                const station = mapStaticPointTemplateToStation(staticPointTemplateHandled, payload.station);
                return EditStationSuccess(station, payload.callback);
              }),
              catchError(() => {
                notification.error(payload.callback.notification.error);
                return of(EditStationFailed());
              })
            )
          )
        );
      }

      return EMPTY;
    })
  );

export const handleEditStationSuccess = (action$: ActionsObservable<ReturnType<typeof EditStationSuccess>>) =>
  action$.pipe(
    ofType(StationsActionsTypes.EDIT_STATION_SUCCESS),
    map(action => action.payload),
    concatMap(payload => {
      const callback = payload?.callback;
      const station = payload?.station;

      if (callback?.notification?.success) {
        notification.success(callback.notification.success);
      }

      callback?.tracking?.editedTrap();
      callback?.clearDrawerAndSelectStation(station);
      return EMPTY;
    })
  );

export const handleDeleteStationSuccess = (action$: ActionsObservable<ReturnType<DeleteStationType>>, state$: StateObservable<AppState>) =>
  action$.pipe(
    ofType(StationsActionsTypes.DELETE_STATION_SUCCESS),
    map(action => action.payload),
    withLatestFrom(
      state$.pipe(map((state: AppState) => selectSelectedCompany(state.uiState.global))),
      state$.pipe(map((state: AppState) => selectSelectedProperty(state.uiState.global)))
    ),
    concatMap(([payload, company, property]) => {
      if (!company || !property) return EMPTY;

      if (payload?.integrationSource === ExternalIntegrationSource.TRAPVIEW) {
        return deleteTrapViewDevice({
          protector_trap_id: payload.stationId,
          protector_organization_id: company,
          protector_property_id: property
        }).pipe(
          map(() => {
            notification.success(payload.callback.notification.success);
            payload?.callback?.tracking?.deletedTrap();
            return DeleteTrapViewDevice();
          }),
          catchError(() => {
            notification.error(payload.callback.notification.error);
            return EMPTY;
          })
        );
      }

      if (payload?.callback?.notification?.success) {
        notification.success(payload.callback.notification.success);
      }

      payload?.callback.tracking.deletedTrap();

      return EMPTY;
    })
  );
