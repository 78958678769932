import * as Sentry from '@sentry/react';
import { useDidMount } from 'beautiful-react-hooks';
import Modals from 'components/modals/modals';
import { browserHistory } from 'config/historyNavigate';
import { queryClient } from 'config/react-query';
import { QueryClientProvider, ReactQueryDevtools } from 'core/utils/data-store/tools';
import { handleResetSegment } from 'core/utils/segment/useSegmentTracking';
import Infra from 'infra/infra.container';
import type React from 'react';
import { unstable_HistoryRouter as HistoryRouter } from 'react-router-dom';

const toggleButtonRenderProps: React.ButtonHTMLAttributes<HTMLButtonElement> = {
  style: {
    marginBottom: '70px'
  }
};

const App: React.FC = () => {
  useDidMount(() => {
    handleResetSegment();
  });

  return (
    <QueryClientProvider client={queryClient}>
      <ReactQueryDevtools initialIsOpen={false} toggleButtonProps={toggleButtonRenderProps} />
      <HistoryRouter history={browserHistory}>
        <Infra />
        <Modals />
      </HistoryRouter>
    </QueryClientProvider>
  );
};

export default Sentry.withProfiler(App);
