import { Avatar } from 'antd';
import classNames from 'classnames';
import { memo } from 'react';
import STTypo, { STTypoModels } from '../typo';
import type { STAvatarProps } from './avatar.models';
import './avatar.styles.less';

const STAvatar = ({
  chevron = false,
  small = false,
  typo = STTypoModels.TypeOptions.C1,
  label,
  children,
  clicked = false,
  onClick,
  disabled = false,
  ...props
}: STAvatarProps) => {
  const hasLabel = !!label;
  const avatarClasses = !disabled
    ? classNames({
        'st-avatar': true,
        'st-avatar--with-label': !!label,
        'st-avatar--with-chevron': chevron,
        'st-avatar--clicked': clicked
      })
    : classNames({
        'st-avatar-2': true,
        'st-avatar-2--with-label': !!label,
        'st-avatar-2--with-chevron': chevron
      });
  return (
    <div data-testid='avatar-container' className={avatarClasses} onClick={() => onClick && onClick()}>
      <Avatar {...props} size={small ? 24 : 40}>
        <STTypo type={small ? STTypoModels.TypeOptions.C2 : STTypoModels.TypeOptions.P1}>{children}</STTypo>
      </Avatar>
      {hasLabel && (
        <div className='st-avatar-label'>
          <STTypo data-testid='avatar-label' type={typo} fontWeight={!small ? 500 : undefined}>
            {label}
          </STTypo>
        </div>
      )}
      {chevron && (
        <div className='st-avatar-chevron'>
          <i className='sf sf-chevron-right' />
        </div>
      )}
    </div>
  );
};

const STAvatarMemoized = memo(STAvatar);
export default STAvatarMemoized;
