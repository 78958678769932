import * as Sentry from '@sentry/react';
import { selectCurrentUser } from 'core/services/auth/auth.reducer';
import { getCurrentLanguage } from 'core/utils/functions';
import { getSelectedCompany } from 'entities/company/company.reducer';
import { getSelectedProperty } from 'entities/property/property.reducer';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import sentryService from 'sentry/service';

const useTags = () => {
  const company = useSelector(getSelectedCompany);
  const property = useSelector(getSelectedProperty);
  const currentUser = useSelector(selectCurrentUser);

  const { pathname } = useLocation();

  const screen = pathname.split('/').pop();

  useEffect(() => {
    const information = { company, property, screen };
    Sentry.addEventProcessor(event => sentryService.globalEventProcessor(event, information));
    Sentry.setTags({
      language: getCurrentLanguage(),
      screen,
      companyId: company?.id ?? '',
      companyName: company?.name ?? '',
      propertyId: property?.id ?? '',
      propertyName: property?.name ?? '',
      userId: currentUser?.id ?? '',
      userEmail: currentUser?.email ?? ''
    });
  }, [company, property, screen, currentUser]);
};

export default useTags;
