import { Spin } from 'antdV5';
import { useGetRecommendationNotes } from 'querys/disease-risk/disease-risk.recommendation-notes.query';
import type Quill from 'quill';
import type { MutableRefObject } from 'react';
import { useEffect, useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { StyledRichText } from './report-disease-risk-notes-tabs.style';
import { updateUniqueNotes } from './report-disease-risk-notes.hook';
import type { RecommendationNote } from './report-disease-risk-notes.type';

interface ReportDiseaseRiskNotesTabsRichTextProps {
  seasonId: string;
  onChange: (seasonId: string, ref: Quill | null) => void;
  notesRef: MutableRefObject<RecommendationNote[]>;
}

export const ReportDiseaseRiskNotesTabsRichText = ({ seasonId, onChange, notesRef }: ReportDiseaseRiskNotesTabsRichTextProps) => {
  const [t] = useTranslation();
  const ref = useRef<Quill | null>(null);
  const { propertyId } = useParams();
  const { data: notes, isLoading } = useGetRecommendationNotes(propertyId ?? '', seasonId);

  const currentNote = useMemo(() => notes?.find(note => note.resource_id === seasonId), [notes, seasonId]);

  useEffect(() => {
    if (currentNote) {
      notesRef.current = updateUniqueNotes(notesRef.current, seasonId, currentNote.content.value);
    }
  }, [currentNote, notesRef, seasonId]);

  useEffect(() => {
    ref.current?.clipboard.dangerouslyPasteHTML(notesRef.current.find(note => note.season_id === seasonId)?.notes ?? '');
  });

  return (
    <Spin spinning={isLoading}>
      <StyledRichText
        ref={ref}
        onChange={() => onChange(seasonId, ref.current)}
        debounceTime={500}
        placeholder={t('report.disease_risk.options.report_notes_creation.rich_text_placeholder')}
      />
    </Spin>
  );
};
