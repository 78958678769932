import * as Sentry from '@sentry/react';
import axios from 'axios';
import { NEMADIGITAL_URL } from 'config/constants';
import type { RequestCreateJob } from 'syngenta-digital-react-cropwise-nemadigital/dist/module/nema-config/types/nema-config.types';
import type { AvailableFields, FieldCropInfoQuery, NemaDigitalJob, NemaDigitalJobSuccess } from './nemadigital.model';

const nemaUrl = NEMADIGITAL_URL;
export const ERROR_404 = 'status code 404';

export const getNemaDigitalFieldCropInfo = async (fieldId: string, start_date: string, end_date: string) => {
  const url = `${nemaUrl}/field/${fieldId}/crop_prediction?start_date=${start_date}&end_date=${end_date}`;

  try {
    const response = await axios.get<FieldCropInfoQuery[]>(url);
    return response.data;
  } catch (error) {
    Sentry.captureException(error);
    return [];
  }
};

export const getNemaDigitalAvailableFields = async (propertyId: string, workspaceId: string): Promise<AvailableFields[]> => {
  const url = `${nemaUrl}/properties/${propertyId}/available-fields?workspace_id=${workspaceId}`;

  try {
    const response = await axios.get<AvailableFields[]>(url);
    return response.data;
  } catch (error) {
    return [];
  }
};

export const getNemaDigitalJobs = async (propertyId: string): Promise<NemaDigitalJob[]> => {
  const url = `${nemaUrl}/properties/${propertyId}/jobs`;

  try {
    const response = await axios.get<NemaDigitalJob[]>(url);
    return response.data;
  } catch (error) {
    if (JSON.stringify(error).includes(ERROR_404)) {
      throw new Error(ERROR_404);
    }
    return [];
  }
};

export const createNemaDigitalJob = async (requestCreateJob: RequestCreateJob): Promise<NemaDigitalJobSuccess | undefined> => {
  const url = `${nemaUrl}/reports/job/create`;
  try {
    const response = await axios.post<NemaDigitalJobSuccess>(url, {
      ...requestCreateJob,
      aois: requestCreateJob.aois.map(aoi => ({
        ...aoi,
        crops: aoi.crops.map(crop => ({ ...crop, year: undefined }))
      }))
    });
    return response.data;
  } catch (error) {
    return undefined;
  }
};

export const editNemaDigitalJob = async (requestCreateJob: NemaDigitalJob): Promise<NemaDigitalJobSuccess | undefined> => {
  const url = `${nemaUrl}/reports/job`;
  try {
    const response = await axios.put<NemaDigitalJobSuccess>(url, {
      ...requestCreateJob,
      job_params: requestCreateJob.job_params.map(aoi => ({
        ...aoi,
        crops: aoi.crops.map(crop => ({ ...crop, year: undefined }))
      }))
    });
    return response.data;
  } catch (error) {
    return undefined;
  }
};

export const anticipateNemaDigitalJob = async (jobId: number): Promise<NemaDigitalJobSuccess | undefined> => {
  const url = `${nemaUrl}/reports/${jobId}/anticipate`;

  const response = await axios.get<NemaDigitalJobSuccess>(url);
  return response.data;
};
