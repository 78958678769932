export enum Entitlements {
  ACCOUNTS_MANAGEMENT = 'accounts.management',
  ACCOUNTS_INVITATION = 'accounts.invitation',
  ORGANIZATION_CRUD = 'organization.crud',
  SEASON_CREATION = 'season.creation',
  SEASON_UPDATE = 'season.update',
  PROPERTY_CREATION = 'property.creation',
  PROPERTY_UPDATE = 'property.update',
  AREA_CREATION = 'area.creation',
  AREA_UPDATE = 'area.update',
  MAPS_IMAGERY = 'maps.imagery',
  MAPS_IMAGERY_REMOTE_SENSING = 'remote-sensing.autoingestion.enable',
  MAPS_IMAGERY_REMOTE_SENSING_CWO_DATASET = 'remote-sensing.autoingestion.use-cwo-dataset',
  RADAR = 'radar',
  WEATHER = 'weather',
  ALERTS = 'alerts',
  ALERTS_CONFIG = 'alerts.config',
  MAPS_XRAY = 'maps.xray',
  MAPS = 'maps',
  RESOURCE_CENTER = 'resource_center',
  DASHBOARD = 'dashboard',
  TASKS_MONITORING = 'tasks.monitoring',
  TASKS_PRESCRIPTION = 'tasks.prescription',
  MAPS_AGRONOMIC = 'maps.agronomic',
  FINANCIAL = 'financial',
  INVENTORY = 'inventory',
  FARMSTORIES = 'farmstories',
  MAPS_FIELDREPORT = 'maps.fieldreport',
  SCOUTING_SAMPLEPROBLEM = 'scouting.sampleproblem',
  SCOUTING_STANDCOUNT = 'scouting.standcount',
  SCOUTING_GROWTHSTAGE = 'scouting.growthstage',
  SCOUTING_FIXEDPOINTS = 'scouting.fixedpoints',
  SCOUTING_ANNOTATION = 'scouting.annotation',
  TASKS_SPRAY_REGISTRATION = 'tasks.sprayregistration',
  SIMPLIFIED_SPRAY_REGISTRATION = 'simplified.sprayregistration',
  TASKS_SAMPLING = 'tasks.sampling',
  TASKS_MANAGEMENT = 'tasks.management',
  TASKS_NOTIFICATION_EMAIL = 'tasks.notification.email',
  OFFLINE_RESUME = 'offline.resume',
  SCOUTING_DAYSLIST = 'scouting.dayslist',
  MAPS_USERFILE = 'maps.userfile',
  SCOUTING_REPRESENTATIVINESS = 'scouting.representativiness',
  OFFLINE_AVAILABILITY = 'offline.availability',
  DISEASE_RISK = 'diseaserisk',
  DISEASE_RISK_ADVANCED_CONFIGURATION = 'diseaserisk.advanced_configuration',
  DISEASE_RISK_BARLEY = 'diseaserisk.barley',
  DISEASE_RISK_WHEAT = 'diseaserisk.wheat',
  DISEASE_RISK_GRAPES = 'diseaserisk.grapes',
  DISEASE_RISK_REPORT = 'report.diseaserisk',
  DISEASE_RISK_WEATHER_STATION = 'diseaserisk.weather_station',
  DISEASE_RISK_WEATHER_STATION_INTEGRATED = 'diseaserisk.weather_station_integrated',
  DISEASE_RISK_WEATHER_STATION_MANUAL = 'diseaserisk.weather_station_manual',
  DISEASE_RISK_LANDING_PAGE = 'diseaserisk.web_landing_page',
  DISEASE_RISK_CROP_CALENDAR = 'diseaserisk.crop_calendar',
  DISEASE_RISK_POTATO = 'diseaserisk.potato',
  DISEASE_RISK_PRE_SEASON = 'diseaserisk.pre_season',
  GROWTH_STAGES_EDIT = 'growth.stages.edit.web',
  ZENDESK = 'zendesk',
  ZENDESK_LATAM = 'zendesk.latam',
  BORER_RISK = 'has_sugarcane_pest_mngt',
  WARNING_EXPIRATION_PLAN = 'warning.expirationplan',
  REGIONAL_RISK = 'regionaloverview.diseaserisk',
  MAPS_SEVERITY_TYPE_PHENOMENON = 'maps.severityphenomenon',
  CUSTOMIZED_REPORTS = 'customized.reports',
  HIDE_PROPERTY_HALL = 'hide_property_hall',
  SIMPLE_CEREALS = 'simple.cereals',
  HIDE_TASKS = 'hide_tasks',
  HIDE_FIXED_POINTS = 'hide_menu.fixed_points',
  HIDE_MENU_INVENTORY = 'hide_menu.inventory',
  HIDE_SAMPLING_REPORT = 'hide_sampling_report',
  HIDE_ALERTS_ON_PANEL = 'hide_alerts_on_panel',
  HIDE_METHODOLOGY_CONFIGURATION = 'hide_methodology_configuration',
  HIDE_CROP_DEVELOPMENT_SPAIN = 'hide_crop_dev_stage_cp',
  REGIONAL_RISK_MYCOTOXIN = 'regionalrisk.mycotoxin',
  MAPS_SEEDS = 'maps.seeds',
  MAP_PHENOLOGICAL_STAGE = 'maps.phenological_stage',
  MAP_DAE = 'maps.dae',
  VISIT_REPORT = 'report.visit',
  FOX_INTEGRATION_READ_ONLY = 'diseaserisk.fox_read_only',
  FIELD_BOOK_SPAIN = 'field.book.spain',
  FIELD_BOOK_HUNGARY = 'field.book.hungary',
  NOTES_PAGE = 'notes.page',
  SMART_PLAN = 'smart_plan.app',
  SPRAY_PRODUCT_INFO_SPAIN = 'spray.product_info_detail',
  SCOUTING_SCORE = 'scouting.score',
  REPORT_DETAILED_CONSOLIDATED = 'report.detailed_consolidated',
  POST_HARVEST = 'post.harvest',
  INTEGRATION_SIAGRE = 'integration.siagri',
  INTEGRATION_SAA = 'integration.saa',
  INTEGRATION_NECTAR = 'integration.nectar',
  INTEGRATION_REA = 'integration.rea',
  CREATE_ORG_AND_PROPERTY = 'create.org.and.property.protector_web',
  OPPORTUNITY_MACHINE = 'opportunity_machine',
  TASK_HARVEST = 'task.harvest',
  TASK_IRRIGATION = 'task.irrigation',
  TASK_FERTIRRIGATION = 'task.fertirrigation',
  TASK_FERTILIZATION = 'task.fertilisation',
  TASK_MAINTENANCE = 'task.maintenance',
  TASK_PLANTING = 'task.planting',
  TASK_SEED_TREATMENT = 'task.seed_treatment',
  TASK_BUILDING_TREATMENT = 'task.building_treatment',
  TASK_POST_HARVEST_TREATMENT = 'task.post_harvest_treatment',
  CARBON_MAPS = 'carbon.maps',
  HIDE_ALERTS_MONITORING = 'hide_alerts.monitoring',
  HIDE_ALERTS_RISK_CHANGE = 'hide_alerts.risk_change',
  HIDE_ALERTS_PRESCRIPTION = 'hide_alerts.prescription',
  HIDE_ALERTS_WORSENED_STATUS = 'hide_alerts.worsened_status',
  HIDE_ALERTS_WORSENED_STATUS_BY_PROBLEM = 'hide_alerts.worsened_status_by_problem',
  HIDE_REPORT_APLICATION_STATISTICS = 'hide_report.aplication_statistics',
  HIDE_REPORT_FARM = 'hide_report.property',
  HIDE_REPORT_INVENTORY_WAREHOUSE = 'hide_report.inventory_warehouse',
  HIDE_REPORT_MONITORING_STATISTICS = 'hide_report.monitoring_statistics',
  HIDE_REPORT_RAINFALL = 'hide_report.rainfall',
  HIDE_REPORT_SAMPLE_POINT_AND_AGRCULTURAL_RESOURCES = 'hide_report.sample_point_and_agrcultural_resources',
  HIDE_REPORT_TRACKING = 'hide_report.tracking',
  HIDE_REPORT_TRAPS = 'hide_report.traps',
  HIDE_TIMELINE_DAYS_WITHOUT_MONITORING = 'hide_timeline.days_without_monitoring',
  HIDE_TIMELINE_DAYS_WITHOUT_SPRAY = 'hide_timeline.days_without_spray',
  HIDE_REPORT_FARM_SCOUTING = 'hide_report.farm_scouting',
  DISEASE_RISK_MOCK = 'mock.disease_risk',
  REGIONAL_RISK_MOCK = 'regionalrisk.mock',
  HIDE_TASKS_PRESCRIPTION = 'hide_tasks.prescription',
  EDIT_MONITORING = 'edit.monitoring',
  MANAGEMENT_REPORT = 'management.reports',
  TASK_SCOUNTING_YIELD_ESTIMATION = 'task.scouting_yield_estimation',
  PRODUCT_WARNINGS = 'product.warnings',
  TIMELINE_SHOW_INDICATOR_CHART = 'timeline.show_indicator_chart',
  SHOW_FARMS_WITHOUT_SEASONS = 'show_farms_without_season',
  HIDE_MENU_OPTION_WITHOUT_SEASON = 'hide_menu_option_without_season',
  TIMELINE_EVOLUTION_GRAPH_DEFAULT_VIEW = 'timeline.evolution_graph_default_view',
  TIMELINE_SHOWCHART_HIDEINDICATORRESULT = 'timeline.show_chart_hide_indicator_result',
  SEASON_REPORT_ORG = 'season_report.org',
  SEASON_REPORT_WORKSPACE = 'season_report.workspace',
  REPORT_DISEASE_RISK_RECOMMENDATIONS = 'report.diseaserisk.recommendations',
  NEMA_CROP_IDENTIFICATION_ENABLED = 'crop.identification.enabled',
  REGIONAL_RISK_GRID = 'regionaloverview.diseaserisk.grid'
}
