import { datadogRum } from '@datadog/browser-rum';
import * as Sentry from '@sentry/react';
import type React from 'react';
import { useErrorBoundaryStore } from './error-boundary.store';
import { SomethingWentWrong } from './something-went-wrong.container';

interface ErrorBoundaryProps {
  children: React.ReactNode;
}

export const APIErrorBoundary = ({ children }: ErrorBoundaryProps) => {
  const { APIError } = useErrorBoundaryStore();
  if (APIError) {
    throw APIError;
  }
  return <>{children}</>;
};

const ErrorBoundary = ({ children }: ErrorBoundaryProps) => (
  <Sentry.ErrorBoundary
    fallback={SomethingWentWrong}
    beforeCapture={scope => {
      scope.setTag('crashScreen', true);
      datadogRum.setGlobalContextProperty('crashScreen', true);
    }}>
    <APIErrorBoundary>{children}</APIErrorBoundary>
  </Sentry.ErrorBoundary>
);

export default ErrorBoundary;
