import { notification } from 'antd';
import type { RangePickerValue } from 'antd/lib/date-picker/interface';
import type { TreeNode } from 'antd/lib/tree-select';
import type { Company } from 'entities/company/company.models.ts';
import moment from 'moment';
import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import type { Dictionary } from '../../../../../config/types.ts';
import { getAllCompanies, getSelectedCompany } from '../../../../../entities/company/company.reducer.ts';
import type { Property } from '../../../../../entities/property/property.models.ts';
import type { AppState } from '../../../../../redux/app-state.ts';
import type { Moment, UUID } from '../../../../utils/basic.models.ts';
import { useMutation } from '../../../../utils/data-store/tools.ts';
import { getPropertyByOrg } from './season-report.service.ts';
import type { TreeNodeExtra } from './useSeasonReport.models.ts';

export const MAX_SELECTED_ORGANIZATIONS = 50;

export const useListPropertiesByOrgs = (SeasonReportWorkspaceEntitlement: boolean) => {
  const [t] = useTranslation();

  const companies = useSelector(getAllCompanies);
  const currentCompany = useSelector(getSelectedCompany)!;
  const workspaceId = useSelector((state: AppState) => state.entities.company.licensingStatus)?.workspace?.id;

  const [propertiesByOrgList, setPropertiesByOrgList] = useState<Dictionary<Property[]>>({});

  const allCompanies = useMemo(() => {
    if (SeasonReportWorkspaceEntitlement) return filterPropertiesByWorkspace(companies, workspaceId);
    return [currentCompany];
  }, [SeasonReportWorkspaceEntitlement, currentCompany, companies, workspaceId]);

  const { mutate } = useMutation(
    ['getPropertiesInfo'],
    (companyId: string) => {
      return getPropertyByOrg(companyId);
    },
    {
      onSuccess: (data, value) => {
        setPropertiesByOrgList(prev => {
          return {
            ...prev,
            [value as unknown as string]: data
          };
        });
      },
      onError: () => {
        notification.error({ message: t('modals.season_report.alert.select_orgs') });
      }
    }
  );

  const orgTree = useMemo(() => {
    return buildOrgTree(allCompanies, propertiesByOrgList);
  }, [allCompanies, propertiesByOrgList]);

  return { orgTree, mutate };
};

export const buildOrgTree = (allCompanies: Company[], propertiesByOrg: Dictionary<Property[]>) => {
  const orgTree: TreeNode[] = [];

  allCompanies?.forEach(company => {
    orgTree.push({
      title: company.name,
      value: company.id,
      key: company.id,
      children: propertiesByOrg[company.id]?.map(property => {
        return {
          title: property.name,
          value: property.id,
          key: property.id,
          isLeaf: true,
          orgId: company.id
        };
      })
    });
  });
  return orgTree;
};

export const useSeasonDate = () => {
  const initialTimeRangeTo = moment().set({ hour: 0, minute: 0, second: 0, millisecond: 0 });
  const initialTimeRangeFrom = moment().subtract(7, 'd').set({ hour: 0, minute: 0, second: 0, millisecond: 0 });

  const [timeRangeFrom, setTimeRangeFrom] = useState(initialTimeRangeFrom);
  const [timeRangeTo, setTimeRangeTo] = useState<Moment | null>(initialTimeRangeTo);

  const disabledDate = useCallback((current: Moment | null) => {
    if (!current) return false;

    const today = moment().endOf('day');
    const treeYearAgo = moment().subtract(3, 'y').startOf('day');

    if (current.isAfter(today) || current.isBefore(treeYearAgo)) {
      return true;
    }

    return false;
  }, []);

  const handleTimeRange = useCallback((dates: RangePickerValue) => {
    if (dates?.[0] && dates[1]) {
      const isDiffGreaterThanOneYear = dates[1].diff(dates[0], 'months') > 12;
      setTimeRangeFrom(dates[0].set({ hour: 0, minute: 0, second: 0, millisecond: 0 }));

      if (isDiffGreaterThanOneYear) {
        setTimeRangeTo(null);
        return;
      }
      setTimeRangeTo(dates[1].set({ hour: 0, minute: 0, second: 0, millisecond: 0 }));
    }
  }, []);

  return { timeRangeFrom, timeRangeTo, disabledDate, handleTimeRange };
};

export const useSetSelectedProperties = () => {
  const [t] = useTranslation();

  const [selectedProperty, setSelectedProperty] = useState<UUID[]>([]);
  const [selectedPropertiesByOrg, setSelectedPropertiesByOrg] = useState<Record<string, string[]>>({});

  const handleSelectAllOrganizations = (orgsIds: UUID[]) => {
    const maxOrganizationsIds = orgsIds.slice(0, MAX_SELECTED_ORGANIZATIONS);
    setSelectedProperty(maxOrganizationsIds);
    const newHierarchy = { ...selectedPropertiesByOrg };
    maxOrganizationsIds.forEach(orgId => {
      newHierarchy[orgId] = [];
    });
    setSelectedPropertiesByOrg(newHierarchy);
  };

  const handleClearAllOrganizations = () => {
    setSelectedProperty([]);
    setSelectedPropertiesByOrg({});
  };

  const handleSetOrg = (orgId: string, value: string[]) => {
    const newHierarchy = { ...selectedPropertiesByOrg };

    if (newHierarchy[orgId]) {
      delete newHierarchy[orgId];
    } else {
      if (Object.keys(newHierarchy).length >= MAX_SELECTED_ORGANIZATIONS) {
        notification.error({ message: t('modals.season_report.max_selected_organizations_error') });
        return;
      }
      newHierarchy[orgId] = [];
    }

    setSelectedPropertiesByOrg(newHierarchy);
    setSelectedProperty(value);
  };

  const handleSetProperty = (orgId: string, newProperties: string[], value: string[]) => {
    const newHierarchy = { ...selectedPropertiesByOrg };

    newHierarchy[orgId] = newProperties;

    if (Object.keys(newHierarchy).length > MAX_SELECTED_ORGANIZATIONS) {
      notification.error({ message: t('modals.season_report.max_selected_organizations_error') });
      return;
    }

    setSelectedPropertiesByOrg(newHierarchy);
    setSelectedProperty(value);
  };

  const onChange = (value: UUID[], _label: string, extra: TreeNodeExtra) => {
    const isClearAllOrganizations = !value.length;
    if (isClearAllOrganizations) {
      handleClearAllOrganizations();
      return;
    }

    const isSetOrg = !extra.triggerNode?.props?.isLeaf;

    if (isSetOrg) {
      const orgId = extra.triggerNode.props.value;
      handleSetOrg(orgId, value);
      return;
    }

    const parentOrg = extra.triggerNode.props?.orgId ?? '';
    const newProperties =
      extra?.allCheckedNodes?.filter(node => node.node.props?.orgId === parentOrg)?.map(node => node.node.props.value) ?? [];

    handleSetProperty(parentOrg, newProperties, value);
  };

  return { selectedProperty, onChange, selectedPropertiesByOrg, handleSelectAllOrganizations };
};

export const filterPropertiesByWorkspace = (companies: Company[], workspaceId?: string) => {
  if (!workspaceId) return [];
  return companies.filter(company => {
    return company?.crn?.includes(workspaceId);
  });
};

export const filterTreeNode = (inputValue: string, treeNode: { props: { title: string } }) => {
  return treeNode?.props?.title?.toLowerCase().indexOf(inputValue.toLowerCase()) >= 0;
};
