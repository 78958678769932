import { Tabs } from 'antdV5';
import { RichText } from 'components/rich-text';
import { COLOR_OPTIONS, FONT_FAMILY } from 'config/style';
import styled from 'styled-components';

export const StyledTabs = styled(Tabs)`
  .ant-tabs-tab {
    margin-right: 0;

    &:hover {
      color: ${COLOR_OPTIONS.BLUE_60};
    }
  }
`;

export const StyledRichText = styled(RichText)`
  .ql-editor {
    min-height: 150px;
    font-family: ${FONT_FAMILY};
  }

  .ql-tooltip.ql-editing {
    left: 50% !important;
    transform: translateX(-50%) !important;
  }

  b,
  strong,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-weight: 600;
  }
`;
