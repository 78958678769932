import type React from 'react';

export const OpenInNewIcon: React.FC = () => {
  return (
    <svg width='18' height='19' viewBox='0 0 18 19' style={{ fill: 'currentcolor', display: 'block' }} xmlns='http://www.w3.org/2000/svg'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M3.75 4.25V14.75H14.25V9.5H15.75V14.75C15.75 15.575 15.075 16.25 14.25 16.25H3.75C2.9175 16.25 2.25 15.575 2.25 14.75V4.25C2.25 3.425 2.9175 2.75 3.75 2.75H9V4.25H3.75ZM10.5 4.25V2.75H15.75V8H14.25V5.3075L6.8775 12.68L5.82 11.6225L13.1925 4.25H10.5Z'
        fill='white'
      />
    </svg>
  );
};
