import axios from 'axios';
import { CORE_SERVICES_API_URL } from 'config/constants';
import type { LicensingStatusResponse } from 'entities/company/company.models';

const coreServicesApiUrl = CORE_SERVICES_API_URL || 'http://localhost:8080';
const licensingStatusUrl = `${coreServicesApiUrl}/v2/licensing/status`;

export const getLicensingStatusByWorkspace = async (workspaceId: string) => {
  const response = await axios.get<LicensingStatusResponse>(`${licensingStatusUrl}?workspace=${workspaceId}`);
  return response.data;
};
