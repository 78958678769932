import { SetCurrentCompany } from 'core/core.actions';
import { getSelectedCompany } from 'core/core.selectors';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useMatch } from 'react-router-dom';

export const useCompanyOrOrganizationMatch = () => {
  const companyMatch = useCompanyMatch();
  const organizationMatch = useOrganizationMatch();

  return companyMatch ?? organizationMatch;
};

export const useCompanyMatch = () => {
  return useMatch({
    path: '/company/:companyId',
    end: false
  });
};

export const useOrganizationMatch = () => {
  const organizationMatch = useMatch({
    path: '/hall-of-companies/workspace/:workspaceId/organization/:organizationId',
    end: false
  });

  const organizationMatchRetro = organizationMatch && {
    ...organizationMatch,
    params: {
      ...organizationMatch.params,
      companyId: organizationMatch.params.organizationId
    }
  };
  return organizationMatchRetro;
};

export const useWorkspaceMatch = () => {
  const workspaceMatch = useMatch({
    path: '/hall-of-companies/workspace/:workspaceId',
    end: false
  });

  const workspaceMatchRetro = workspaceMatch && {
    ...workspaceMatch,
    params: {
      ...workspaceMatch.params
    }
  };
  return workspaceMatchRetro;
};

export const useCurrentCompanyIdFromPath = () => {
  const isHallOfEntitiesMFE = useOrganizationMatch();

  const companyId = useSelector(getSelectedCompany);
  const dispatch = useDispatch();
  const companyMatch = useCompanyOrOrganizationMatch();

  useEffect(() => {
    if (!isHallOfEntitiesMFE) return;
    if (companyMatch?.params?.companyId && companyMatch.params.companyId !== companyId) {
      dispatch(SetCurrentCompany(companyMatch.params.companyId));
    }
  }, [companyId, companyMatch?.params.companyId, dispatch, isHallOfEntitiesMFE]);
};

export const useHallOfWorkspaceMatch = (end = true) => {
  return useMatch({
    path: '/hall-of-companies',
    end
  });
};
