import { isDateBetween } from 'core/utils/date';
import type { Season } from 'entities/season/season.models';
import type { TFunction } from 'i18next';
import { JobStatusEnum } from 'syngenta-digital-react-cropwise-nemadigital';
import type {
  NemaConfigTranslations,
  RequestCreateJob,
  RequestCreateJobAois
} from 'syngenta-digital-react-cropwise-nemadigital/dist/module/nema-config/types/nema-config.types';
import type { AreaSimpleNema, AvailableFields, NemaDigitalJob } from './nemadigital.model';

export const convertFieldToAreaSimple = (availableFields: AvailableFields[]): AreaSimpleNema[] => {
  return availableFields.map((field: AvailableFields) => ({
    id: field.id,
    name: field.name,
    size: field.declared_area
  }));
};

export const selectedSoybeanSeasons = (allSeasons: Season[], selectedSeasonsId: string[]): Season | undefined => {
  const selectedSeasons = allSeasons.filter(
    season => selectedSeasonsId.includes(season.id) && season.crop?.wk_slug?.toUpperCase()?.includes('SOYBEAN')
  );

  if (selectedSeasons.length === 1) {
    return selectedSeasons[0];
  }
  if (selectedSeasons.length > 1) {
    selectedSeasons.sort((seasonA, seasonB) => {
      if (new Date(seasonA.end_date).getTime() > new Date(seasonB.end_date).getTime()) {
        return -1;
      }
      return 1;
    });

    return selectedSeasons[0];
  }

  return undefined;
};

export const convertNemaDigitalJobToRequestCreateJob = (workspaceId: string, job?: NemaDigitalJob): RequestCreateJob | undefined => {
  if (!job) {
    return undefined;
  }
  return {
    contract_id: job.contract_id,
    crop_cycle_end: job.crop_cycle_end,
    crop_cycle_start: job.crop_cycle_start,
    workspace_id: workspaceId,
    aois: job.job_params
  };
};

export const convertRequestCreateJobToNemaDigitalJob = (
  requestCreateJob: RequestCreateJob,
  beforeNemaDigitalJob: NemaDigitalJob
): NemaDigitalJob => ({
  property_id: beforeNemaDigitalJob.property_id,
  status: beforeNemaDigitalJob.status,
  updated_at: beforeNemaDigitalJob.updated_at,
  workspace_id: beforeNemaDigitalJob.workspace_id,
  parent_job_id: beforeNemaDigitalJob.parent_job_id,
  job_id: beforeNemaDigitalJob.job_id,
  created_at: beforeNemaDigitalJob.created_at,
  contract_id: requestCreateJob.contract_id,
  crop_cycle_end: requestCreateJob.crop_cycle_end,
  crop_cycle_start: requestCreateJob.crop_cycle_start,
  job_params: requestCreateJob.aois,
  pre_season_execution: beforeNemaDigitalJob.pre_season_execution,
  post_season_execution: beforeNemaDigitalJob.post_season_execution
});

const removeJobEditedAndArchived = (jobs?: NemaDigitalJob[]): NemaDigitalJob[] => {
  if (!jobs) {
    return [];
  }
  return jobs.filter(job => job.status !== JobStatusEnum.EDITED && job.status !== JobStatusEnum.ARCHIVED);
};

const findJobEndDate = (job: NemaDigitalJob): string => {
  return 'season_end_date' in job && !!job?.season_end_date ? job.season_end_date : job.crop_cycle_end;
};

export const findJobInList = (jobs?: NemaDigitalJob[], jobId?: number, currentSoySeason?: Season): NemaDigitalJob | undefined => {
  const jobsFiltered = removeJobEditedAndArchived(jobs);
  if (!jobId) {
    if (currentSoySeason) {
      return jobsFiltered.find(job => isDateBetween(findJobEndDate(job), currentSoySeason.start_date, currentSoySeason.end_date));
    }
    return undefined;
  }
  return jobsFiltered.find(job => job.job_id === jobId);
};

export const removeCropsWithoutCropType = (data: RequestCreateJob): RequestCreateJob => {
  try {
    const newData = JSON.parse(JSON.stringify(data)) as RequestCreateJob;

    const deleteCropsWithoutCropType = (aoi: RequestCreateJobAois) => {
      if (aoi.crops && Array.isArray(aoi.crops)) {
        aoi.crops = aoi.crops.filter(crop => crop.crop_type && crop.end_date?.length && crop.start_date?.length);
      }
    };

    return {
      ...newData,
      aois: newData.aois.map(aoi => {
        deleteCropsWithoutCropType(aoi);
        return {
          ...aoi,
          hasError: undefined,
          isLoading: undefined
        };
      })
    };
  } catch (error) {
    return data;
  }
};

const insertMinSizeSeasonInTranslate = (t: TFunction<'translation', undefined>, translate: string, minSizeSeason: number) => {
  return t(translate).replace('{amountSeason}', `${minSizeSeason}`);
};

export const generateTranslation = (
  t: TFunction<'translation', undefined>,
  minSizeSeasonSoy: number,
  minSizeSeasonOthers: number
): NemaConfigTranslations => ({
  descriptionTitleFieldStep: t('pages.nemadigital.nema_config.description_title_field_step'),
  descriptionTitleSoyStep: insertMinSizeSeasonInTranslate(t, 'pages.nemadigital.nema_config.description_title_soy_step', minSizeSeasonSoy),
  descriptionTitleOthersStep: insertMinSizeSeasonInTranslate(
    t,
    'pages.nemadigital.nema_config.description_title_others_step',
    minSizeSeasonOthers
  ),
  findField: t('pages.nemadigital.nema_config.find_field'),
  nameFieldStep: t('pages.nemadigital.nema_config.name_field_step'),
  nameOthersStep: t('pages.nemadigital.nema_config.name_others_step'),
  textTooltipWarning: t('pages.nemadigital.nema_config.text_tooltip_warning'),
  titleFieldStep: t('pages.nemadigital.nema_config.title_field_step'),
  titleOthersStep: t('pages.nemadigital.nema_config.title_others_step'),
  titleSoyStep: t('pages.nemadigital.nema_config.title_soy_step'),
  translationOr: t('pages.nemadigital.nema_config.translation_or'),
  nameSoyStep: t('pages.nemadigital.nema_config.name_soy_step'),
  setupForm: {
    header: {
      headerTitlePage: t('pages.nemadigital.nema_config.setup_form.header.header_title_page')
    },
    step: {
      stepNextButton: t('pages.nemadigital.nema_config.setup_form.step.step_next_button'),
      stepPreviousButton: t('pages.nemadigital.nema_config.setup_form.step.step_previous_button'),
      stepExitButton: t('pages.nemadigital.nema_config.setup_form.step.step_exit_button'),
      stepConclusionButton: t('pages.nemadigital.nema_config.setup_form.step.step_conclusion_button')
    },
    backPopConfirm: {
      backPopConfirmTitle: t('pages.nemadigital.nema_config.setup_form.back_pop_confirm.back_pop_confirm_title'),
      backPopConfirmCTAText: t('pages.nemadigital.nema_config.setup_form.back_pop_confirm.back_pop_confirm_cta_text'),
      backPopConfirmCancelButton: t('pages.nemadigital.nema_config.setup_form.back_pop_confirm.back_pop_confirm_cancel_button'),
      backPopConfirmConfirmButton: t('pages.nemadigital.nema_config.setup_form.back_pop_confirm.back_pop_confirm_confirm_button')
    },
    editFieldCrop: {
      cancelAndBack: t('pages.nemadigital.nema_config.setup_form.edit_field_crop.cancel_and_back'),
      saveConfiguration: t('pages.nemadigital.nema_config.setup_form.edit_field_crop.save_configuration'),
      messageExitConfirm: t('pages.nemadigital.nema_config.setup_form.edit_field_crop.message_exit_confirm'),
      selectedFields: t('pages.nemadigital.nema_config.setup_form.edit_field_crop.selected_fields'),
      offSeason: t('pages.nemadigital.nema_config.setup_form.edit_field_crop.off_season'),
      tooltipError: t('pages.nemadigital.nema_config.setup_form.edit_field_crop.tooltip_error'),
      soybeans: t('pages.nemadigital.nema_config.setup_form.edit_field_crop.soybean'),
      others: t('pages.nemadigital.nema_config.setup_form.edit_field_crop.others'),
      currentSeason: t('pages.nemadigital.nema_config.setup_form.edit_field_crop.current_season'),
      pastSeason: t('pages.nemadigital.nema_config.setup_form.edit_field_crop.past_season'),
      searchFieldPlaceholder: t('pages.nemadigital.nema_config.setup_form.edit_field_crop.field_search'),
      corn: t('pages.nemadigital.nema_config.setup_form.edit_field_crop.corn'),
      cotton: t('pages.nemadigital.nema_config.setup_form.edit_field_crop.cotton'),
      wheat: t('pages.nemadigital.nema_config.setup_form.edit_field_crop.wheat'),
      sorghum: t('pages.nemadigital.nema_config.setup_form.edit_field_crop.sorghum'),
      winterWheat: t('pages.nemadigital.nema_config.setup_form.edit_field_crop.winter_wheat')
    },
    dateEditModal: {
      title: t('pages.nemadigital.nema_config.setup_form.date_edit_modal.title'),
      startCalendarTitle: t('pages.nemadigital.nema_config.setup_form.date_edit_modal.end_calendar_title'),
      endCalendarTitle: t('pages.nemadigital.nema_config.setup_form.date_edit_modal.start_calendar_title'),
      cancel: t('pages.nemadigital.nema_config.setup_form.date_edit_modal.cancel'),
      save: t('pages.nemadigital.nema_config.setup_form.date_edit_modal.save'),
      errorMessageDate: t('pages.nemadigital.nema_config.setup_form.date_edit_modal.error_message_date')
    },
    endSeasonButton: {
      endSeasonButton: t('pages.nemadigital.nema_config.setup_form.end_season_button.end_season_button'),
      endSeasonPopconfirmTitle: t('pages.nemadigital.nema_config.setup_form.end_season_button.end_season_popconfirm_title'),
      endSeasonPopConfirmCTAText: t('pages.nemadigital.nema_config.setup_form.end_season_button.end_season_popconfirm_cta_text'),
      endSeasonPopconfirmCancelText: t('pages.nemadigital.nema_config.setup_form.end_season_button.end_season_popconfirm_cancel_text'),
      endSeasonPopconfirmOkText: t('pages.nemadigital.nema_config.setup_form.end_season_button.end_season_popconfirm_confirm_text')
    }
  },
  buttonGoToEditSeason: t('pages.nemadigital.nema_config.button_go_to_edit_season'),
  nameSummaryScreen: t('pages.nemadigital.nema_config.name_summary_screen')
});
