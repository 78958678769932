import type { Dictionary } from 'config/types';
import type { Nullable } from 'core/core.models';
import type { UUID } from 'core/utils/basic.models';
import type { CreateStaticPointCallback } from 'pages/static-points/static-points-drawer/redux/static-points-drawer.models';
import type { EntityState } from 'redux-ngrx-entity';

export interface IntegrationsState extends EntityState<unknown> {
  isLoading: boolean;
  error: string | null;
  content: unknown;
  jdoc: JDOCState;
  perfectFlight: PerfectFlightState;
  trapView: TrapViewState;
}

export interface PerfectFlightProperty {
  id: string;
  name: string;
  area: number;
  plots_count: number;
}

export interface IntegratePerfectFlightPayload {
  property_id: UUID;
  key: string;
  secret: string;
}

export interface PerfectFlightState {
  isLoading?: boolean;
  authenticated: boolean;
  error: string | null;
  propertyList: PerfectFlightProperty[];
  areasDiff: AreasDiffResponse[];
  areasLoaded: boolean;
  applications: PerfectFlightApplication[];
  applicationsLoaded: boolean;
}

export interface JDOCState {
  checkingAuth: boolean;
  gettingUrl: boolean;
  authentication: JDOCAuthentication | null;
  error: string | null;
  urlAuth: string | null;
  loadPermissions: boolean;
  permissions: JDOCPermissions | null;
  postingPermissions: boolean;
  revokingAccess: boolean;
  loadingOrganizations: boolean | null;
  organizations: JDOCOrganizationsResponse[];
  loadingFarms: boolean;
  farms: JDOCFarmsResponse[];
  loadingAreasDiff: boolean;
  areasDiff: AreasDiffResponse[];
  postingAreasDiff: boolean;
  updateDiff: JDOCPayloadDiffResponse | null;
  gettingUpdateDiff: boolean;
  loadedOrganizations: boolean;
}

export interface JDOCOrganizationsResponse {
  id: string;
  name: string;
  quantity_farms: number;
  has_access: boolean;
}

export interface JDOCFarmsResponse {
  ha: number;
  id: UUID;
  name: string;
  quantity_areas: number;
}

export interface AreasDiffResponse {
  atlas_area: JDOCAreasDiffArea | null;
  intersection_percentage: number;
  integration_area: JDOCAreasDiffArea | null;
}

export interface JDOCAreasDiffResponse {
  atlas_area: JDOCAreasDiffArea | null;
  intersection_percentage: number;
  john_deere_area: JDOCAreasDiffArea | null;
}

export interface PerfectFlightAreaDiff {
  atlas_area: JDOCAreasDiffArea | null;
  perfect_flight_area: JDOCAreasDiffArea | null;
  intersection_percentage: number;
}

interface JDOCAreasDiffArea {
  geojson: string;
  id: UUID;
  name: string;
}

export interface JDOCAuthentication {
  property_id: string;
  authenticated: boolean;
  user: {
    account_name: string;
    family_name: string;
    given_name: string;
    user_type: string;
  };
}

export interface JDOCPermissions {
  permissions: string[];
  property_id: UUID;
}

export interface JDOCPaylodFarmsOrganization {
  organizationId: string;
  propertyId: UUID;
}

export interface JDOCPaylodAreasDiff {
  organizationId: string;
  farmId: UUID;
  propertyId: UUID;
}

export interface PerfectFlightPayloadAreasDiff {
  pfFarmId: string;
  propertyId: UUID;
}

export interface JDOCPaylodPostAreasDiff {
  organizationId: string;
  farmId: UUID;
  propertyId: UUID;
  data: JDOCAreasDiffResponse[];
}

interface JDOCPaylodDiffAreasValue {
  id: UUID;
  geometry: string;
}

export interface JDOCPaylodDiffAreas {
  old_values: JDOCPaylodDiffAreasValue[];
  new_values: JDOCPaylodDiffAreasValue[];
}

interface JDOCPayloadDiffAreaDataResponse {
  id: UUID;
  property_id: string;
  parent_region_id: string;
  name: string;
  version: string;
  created_at: string;
  last_modified: string;
  deleted_at: string;
  child_ids: string;
  account: string;
  geometry: string;
  polygon_check_sum: string;
  calculated_area: number;
  declared_area: string;
  named_values: string;
  is_area: true;
}

interface JDOCPayloadDiffAreaResponse {
  base: JDOCPayloadDiffAreaDataResponse;
  overlap: JDOCPayloadDiffAreaDataResponse;
  intersection_percentage: number;
}

export interface JDOCPayloadDiffResponse {
  intersections?: Dictionary<JDOCPayloadDiffAreaResponse[]>;
}

export interface PerfectFlightPayloadAplications {
  propertyId: UUID;
  startDate: string;
  endDate: string;
}

interface PerfectFlightApplicationPlotsData {
  active: boolean;
  area_id: UUID;
  id: string;
  name: string;
}

export interface PerfectFlightApplication {
  active: boolean;
  aircraft_id: number;
  area_ids: UUID[];
  date: string;
  id: string;
  pilot_id: number;
  plots_data: PerfectFlightApplicationPlotsData[];
  plots_ids: string[];
  report_hash: string;
  start_date: string;
  time_begin: string;
}

interface PerfectFlightDetailsPilot {
  id: string;
  active: boolean;
  name: string;
  breve: string;
  company_id: string;
}

interface PerfectFlightDetailsAirCraft {
  id: string;
  active: boolean;
  manufacturer: string;
  model: string;
  prefixo: string;
  company_id: string;
}

export interface PerfectFlightDetailsResponse {
  id: string;
  active: boolean;
  report_hash: string;
  start_date: string;
  end_date: string;
  average_application_speed: number;
  swath_width: number;
  order_service: string | null;
  index_success: number;
  index_waste: number;
  index_fail: number;
  index_uniformity: number;
  total_farming_area_ha: number;
  total_application_area_ha: number;
  total_application_success_area_ha: number;
  total_non_farming_area_ha: number;
  external_area_ha: number;
  overlap_area_ha: number;
  internal_overlap_area_ha: number;
  external_overlap_area_ha: number;
  dose_error_area_ha: number;
  total_external_application_area_ha: number;
  flight_distance: number;
  pilot: PerfectFlightDetailsPilot;
  aircraft: PerfectFlightDetailsAirCraft;
  plots_data: string | null;
}

interface TrapViewState {
  isLoading: boolean;
  validated: boolean;
  authenticated: boolean;
  username: string | null;
  token: string | null;
  error: string | null;
  devices: TrapviewDevice[];
  binding: string[];
  account_id: string | null;
}

export interface TrapViewAccessProperty {
  account_id?: string;
}

export interface TrapViewProperty {
  protector_property_id: string;
  protector_organization_id: string;
  trapview_username: string;
  trapview_subject_id: string;
  trapview_token: string;
}

export interface TrapViewIntegrateExternal {
  protector_property_id: string;
  protector_organization_id: string;
  trapview_token: string;
}

export interface TrapViewIntegratePayload {
  protector_property_id: string;
  protector_organization_id: string;
  trapview_username: string;
}

export interface TrapviewInstallDevice {
  id: number;
  pest_code: string;
  protector_trap_id: string;
  latitude: number;
  longitude: number;
  protector_trap_name: string;
  language: string;
  area_ids?: string[];
  account_id: UUID;
  property_id: UUID;
  organization_id: UUID;
}

export interface TrapviewInstallPayload {
  trapviewDevice: TrapviewInstallDevice;
  callback: CreateStaticPointCallback;
}

export interface TrapviewDeletePayload {
  protector_trap_id: string;
  protector_property_id: number | string;
  protector_organization_id: number | string;
}

export interface TrapviewDevice {
  id: number;
  part_no: string;
  name: string;
  latitude: number;
  longitude: number;
  integrated: boolean;
  pest_code?: string;
  pest_eppo?: string;
  pest_latin_name?: string;
  pest_name?: string;
  protector_trap_id: Nullable<UUID>;
}

export enum TrapViewIntegrationError {
  USER_NOT_FOUND = 'USER_NOT_FOUND',
  PROPERTY_ALREADY_INTEGRATED = 'PROPERTY_ALREADY_INTEGRATED',
  GENERIC_ERROR = 'GENERIC_ERROR'
}

export enum TrapviewInstallationError {
  NOT_FOUND_PEST_CODE = 'Could not found template'
}
