import { Skeleton } from 'antd';
import { images } from 'assets';
import LicenceExpiration from 'components/licence-expiration/licence-expiration.component';
import { VITE_ENV } from 'config/constants';
import STAlert from 'core/components/header/components/alert/alert.component';
import STBreadcrumb from 'core/components/header/components/breadcrumb/breadcrumb.component';
import { selectSystemFlags } from 'core/core.reducer';
import { getLicensingStatus } from 'core/core.selectors';
import { Entitlements } from 'core/shared/enums/entitlements.enum';
import { isUK, validatePlansAndEntitlements, verifyMycotoxinRiskEntitlement } from 'core/utils/functions';
import { useCompanyOrOrganizationMatch, useCurrentCompanyIdFromPath, useOrganizationMatch } from 'core/utils/hooks/usePathMatches';
import useSegmentTracking from 'core/utils/segment/useSegmentTracking';
import { getSelectedCompany } from 'entities/company/company.reducer';
import { getContract } from 'entities/company/company.selector';
import { getSelectedProperty } from 'entities/property/property.reducer';
import { useFieldClusterConfigurationStore } from 'pages/field-clustering/configuration/field-clustering-configuration.zustand';
import useFieldClusteringConfigPermission from 'pages/field-clustering/configuration/hooks/useFieldClusteringConfigPermission';
import { RoutersTypes } from 'pages/routes-configuration/post-harvest/post-harvest.models';
import { useGetStatesRoutesConfiguration } from 'pages/routes-configuration/queries/queries';
import { useGetLicensingStatusByWorkspace } from 'querys/licensing/licensing.query';
import { useGetRBACAuthorityUser } from 'querys/rbac/rbac.query';
import { RBACActions, RBACLevels, RBACPermissionTypesEnum } from 'querys/rbac/rbac.query.model';
import type React from 'react';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useMatch, useNavigate } from 'react-router-dom';
import type { AppState } from 'redux/app-state';
import SVGFieldClusterGlyph from 'st-protector-icons/Glyph/FieldClusterGlyph';
import SVGMethodologyGlyph from 'st-protector-icons/Glyph/MethodologyGlyph';
import SVGScoutingScoreGlyph from 'st-protector-icons/Glyph/ScoutingScoreGlyph';
import DeckOfAppsComponent from './components/deck-of-apps/deck-of-apps.component';
import FieldClusterModal from './components/field-cluster-modal/field-cluster-modal';
import ProfileDropdownComponent from './components/profile-dropdown/profile-dropdown.component';
import STReport from './components/report/report.component';
import SDResourceCenter from './components/resource-center/resource-center.component';
import FarmSettingsComponent from './components/settings/farm-settings.component';
import type { STOrgSettingsCardProps } from './components/settings/org-settings-card.component';
import './deck.less';
import './header.styles.less';
import { handleLogout } from './header.utils';

export interface ISTHeader {
  isEmbedded?: boolean;
}

let accountsUrl: string;

if (VITE_ENV === 'staging') {
  accountsUrl = isUK() ? 'https://beta.accounts.syngentadigital.co.uk' : 'https://beta.accounts.cropwise.com';
} else {
  accountsUrl = isUK() ? 'https://accounts.syngentadigital.co.uk' : 'https://accounts.cropwise.com';
}

const getLogoImage = () => {
  return isUK() ? images.logo_syngenta_digital_collpsed_menu_black : images.logo_protector_collpsed_menu_black;
};

const STHeader: React.FC<ISTHeader> = ({ isEmbedded = false }) => {
  const navigate = useNavigate();
  const [t] = useTranslation();
  const dispatch = useDispatch();
  const segmentTrack = useSegmentTracking();

  const [isValidToken, setIsValidToken] = useState<boolean>(false);
  const selectedProperty = useSelector((state: AppState) => getSelectedProperty(state));
  const defaultWorkspaceId = useSelector(getLicensingStatus);
  const selectedCompany = useSelector((state: AppState) => getSelectedCompany(state));
  const systemFlags = useSelector((state: AppState) => selectSystemFlags(state));
  const showScoutingScore = useSelector((state: AppState) => state.uiState.auth.permissionFlags?.showScoutingScore?.value);
  const contract = useSelector((state: AppState) => getContract(state));
  const licensingStatus = useSelector((state: AppState) => state.entities.company.licensingStatus);
  const hasMycotoxinRiskEntitlement = verifyMycotoxinRiskEntitlement(licensingStatus);

  const showFieldClusterConfig = useFieldClusteringConfigPermission();
  const [openFieldClusterModal, setOpenFieldClusterModal] = useState(false);
  const { resetState } = useFieldClusterConfigurationStore();
  const [, , clearCache] = useGetStatesRoutesConfiguration(false, RoutersTypes.FIELD_CLUSTER);

  useGetLicensingStatusByWorkspace();
  useCurrentCompanyIdFromPath();

  const hideAlerts = validatePlansAndEntitlements(licensingStatus, null, [Entitlements.HIDE_ALERTS_ON_PANEL]);
  const showResourceCenter = validatePlansAndEntitlements(licensingStatus, null, [Entitlements.RESOURCE_CENTER]);

  const methodologyPermissions = useGetRBACAuthorityUser({
    rbacActions: RBACActions.PANEL_METHODOLOGY,
    rbacLevels: RBACLevels.ORG,
    rbacPermission: [RBACPermissionTypesEnum.READ, RBACPermissionTypesEnum.WRITE]
  });
  const showOrgMethodologyConfig = useMemo(() => {
    const companyAndProperty = !!selectedCompany && !!selectedProperty;
    const isMethodologyVisible = !!methodologyPermissions?.length;
    const hideByEntitlement = !!validatePlansAndEntitlements(licensingStatus, null, [Entitlements.HIDE_METHODOLOGY_CONFIGURATION]);

    return !hideByEntitlement && companyAndProperty && isMethodologyVisible;
  }, [licensingStatus, selectedCompany, selectedProperty, methodologyPermissions]);

  const showScoutingScoreSetup =
    !!systemFlags?.P40_24324_scouting_score_hall && (!!systemFlags?.P40_21430_scoutingScoreXray || showScoutingScore);

  const isHallOfEntitiesMFE = useOrganizationMatch();
  const hallOfPropertiesMatch = useCompanyOrOrganizationMatch();

  const matchFarmSettingsInputsMgt = useMatch({
    path: '/farm-settings/inputs-mgt/*',
    end: false
  });

  const orgSettingsMatch = useMatch({
    path: '/company/:companyId/edit-methodology',
    end: false
  });

  const propertyMatch = useMatch({
    path: '/company/:companyId/property/:propertyId',
    end: false
  });

  const scoutingScoreMatch = useMatch({
    path: '/company/:companyId/property/:propertyId/scouting-score',
    end: true
  });

  const notesMatch = !!useMatch({
    path: '/company/:companyId/property/:propertyId/notes',
    end: false
  });

  const segmentTracking = useSegmentTracking();

  const onHandleRedirectTo = (event: string, pathRedirect: string) => () => {
    segmentTracking.track(event);
    navigate(pathRedirect);
  };

  const handleFieldClusterModal = () => {
    if (systemFlags?.P40_41276_latest_field_clusters_view) {
      navigate(`/company/${selectedCompany?.id}/property/${selectedProperty?.id}/field-clustering`);
    } else {
      setOpenFieldClusterModal(state => !state);
    }
  };

  const handleCreateFieldCluster = () => {
    const fieldClusterPath =
      selectedCompany && selectedProperty
        ? `/company/${selectedCompany.id}/property/${selectedProperty.id}/field-clustering/configuration?plan_type=field_cluster`
        : '/';

    resetState();
    clearCache();
    handleFieldClusterModal();
    onHandleRedirectTo('Settings - Tapped to create  new Field Cluster', fieldClusterPath)();
  };

  const getOrgSettingContentOptions = (): STOrgSettingsCardProps[] => {
    const editMethodologyPath = selectedCompany ? `/company/${selectedCompany.id}/edit-methodology` : '/';

    return [
      {
        isAvailable: showOrgMethodologyConfig,
        svgIcon: <SVGMethodologyGlyph />,
        title: t('modals.organization_settings.methodology_card.title'),
        description: t('modals.organization_settings.methodology_card.description'),
        onClick: onHandleRedirectTo('Settings - Tapped to access Methodology', editMethodologyPath)
      },
      {
        isAvailable: showFieldClusterConfig,
        svgIcon: <SVGFieldClusterGlyph />,
        title: t('modals.organization_settings.field_cluster_card.title'),
        description: t('modals.organization_settings.field_cluster_card.description'),
        onClick: handleFieldClusterModal
      }
    ];
  };

  const getPropertySettingContentOptions = (): STOrgSettingsCardProps[] => {
    const scoutingScorePath = `/company/${selectedCompany?.id}/property/${selectedProperty?.id}/scouting-score`;
    return [
      {
        isAvailable: showScoutingScoreSetup,
        svgIcon: <SVGScoutingScoreGlyph className='settings-menu-icon' />,
        title: t('modals.organization_settings.scouting_score_card.title'),
        description: t('modals.organization_settings.scouting_score_card.description'),
        onClick: onHandleRedirectTo('Settings - Tapped to access Scouting Score', scoutingScorePath)
      }
    ];
  };

  const orgSettingsContentOptions = getOrgSettingContentOptions().filter(option => option?.isAvailable);
  const propertySettingsContentOptions = getPropertySettingContentOptions().filter(option => option?.isAvailable);

  const propertySettingsAvailable = !!propertySettingsContentOptions.length && !!propertyMatch && !scoutingScoreMatch;

  const orgSettingsButtonAvailable =
    !!hallOfPropertiesMatch && !orgSettingsMatch && !!orgSettingsContentOptions.length && !propertySettingsAvailable;

  const token = localStorage.getItem('access_token');

  useEffect(() => {
    if (token?.includes('.')) {
      const parsedToken = JSON.parse(window.atob(token.split('.')[1]) || 'null') as Partial<Record<'exp', number>> | null;
      if (parsedToken?.exp) setIsValidToken(parsedToken.exp * 1000 > Date.now());
    }
  }, [token]);

  const handleResourceCenter = useCallback(() => {
    segmentTrack.track('Tap into the resource center');
  }, [segmentTrack]);

  const showReports = useMemo(() => {
    return !!propertyMatch || (!!systemFlags?.P40_33267_report_button_in_hall_of_properties && !!hallOfPropertiesMatch);
  }, [hallOfPropertiesMatch, propertyMatch, systemFlags]);

  if (isEmbedded) {
    return (
      <div className='sd-header' data-testid='st-header'>
        <div className='sd-header__logo'>
          <img src={getLogoImage()} alt='Cropwise Protector' />
        </div>
        <div className='sd-header__breadcromb'>
          {!!hallOfPropertiesMatch && !isHallOfEntitiesMFE && <STBreadcrumb isEmbedded hideSeasons={notesMatch} />}
        </div>
      </div>
    );
  }
  return (
    <div className='sd-header' data-testid='st-header'>
      {isValidToken ? (
        <DeckOfAppsComponent
          handleLogout={() => handleLogout({ segmentTracking, systemFlags, dispatch })}
          selectedPropertyId={selectedProperty?.id}
          selectedCompanyId={selectedCompany?.id}
          workspaceId={defaultWorkspaceId?.account.id ?? ''}
        />
      ) : (
        <Skeleton active title={false} paragraph={{ rows: 1, width: '30px' }} className='skeleton-deck-off-apps' />
      )}
      <div className='sd-header__logo'>
        <img src={getLogoImage()} alt='Cropwise Protector' />
      </div>
      <div className='sd-header__breadcromb'>
        {(hallOfPropertiesMatch ?? matchFarmSettingsInputsMgt) && !isHallOfEntitiesMFE && <STBreadcrumb hideSeasons={notesMatch} />}
      </div>
      <div className='sd-header__buttons' data-testid='sd-header-buttons'>
        {contract && <LicenceExpiration contract={contract} baseUrl={`${accountsUrl}/app/workspaces/${selectedCompany?.id}/products`} />}
        {showResourceCenter && <SDResourceCenter onClick={handleResourceCenter} />}
        {showReports && <STReport />}
        {!!hallOfPropertiesMatch && !hideAlerts && <STAlert />}

        {!hasMycotoxinRiskEntitlement && (
          <FarmSettingsComponent
            orgId={selectedCompany?.id}
            propertyId={selectedProperty?.id}
            workspaceId={defaultWorkspaceId?.account?.id ?? ''}
            orgSettingsContentOptions={orgSettingsContentOptions}
            propertySettingsAvailable={propertySettingsAvailable}
            orgSettingsButtonAvailable={orgSettingsButtonAvailable}
            propertySettingsContentOptions={propertySettingsContentOptions}
          />
        )}

        <div className='sd-header__buttons__separator' />

        <ProfileDropdownComponent
          handleLogout={() => handleLogout({ segmentTracking, systemFlags, dispatch })}
          selectedCompanyName={selectedCompany?.name}
          selectedCompanyId={selectedCompany?.id}
          selectedPropertyId={selectedProperty?.id}
          selectedPropertyName={selectedProperty?.name}
        />

        {openFieldClusterModal && (
          <FieldClusterModal visible onClickClose={handleFieldClusterModal} onClickCreate={handleCreateFieldCluster} />
        )}
      </div>
    </div>
  );
};

export default STHeader;
