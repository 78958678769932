import { getDifferenceInDays, yearOfDate } from 'core/utils/date';
import type { SegmentTrackingHookReturn } from 'core/utils/segment/useSegmentTracking';
import type { AnalyticsInfo } from 'core/utils/segment/user-events.types';
import type { TFunction } from 'i18next';
import type { NemadigitalMapTypes } from 'syngenta-digital-react-cropwise-nemadigital/dist/module/map-overlay/map-overlay.types';
import type { NemadigitalExecution, NemadigitalPropertyReports } from './nemadigital.model';

const generateBeforeSeasonName = (reportType: string, t: TFunction<'translation', undefined>) => {
  switch (reportType) {
    case 'BEFORE_SEASON':
      return t('general.map.multilayer.nemadigital.start');
    case 'AFTER_SEASON':
    default:
      return t('general.map.multilayer.nemadigital.end');
  }
};

const generateDateSeason = (yearStart: string, yearEnd: string): string => {
  return `${yearOfDate(yearStart, 2)}/${yearOfDate(yearEnd, 2)}`;
};

export const sortNemadigitalExecution = (propertyExecution?: NemadigitalExecution): NemadigitalExecution | undefined => {
  if (!propertyExecution) {
    return propertyExecution;
  }
  propertyExecution.reports.sort((reportA, reportB) => {
    if (getDifferenceInDays(new Date(reportA.crop_cycle_year_end), new Date(reportB.crop_cycle_year_end)) > 0) {
      return -1;
    }
    if (getDifferenceInDays(new Date(reportA.crop_cycle_year_end), new Date(reportB.crop_cycle_year_end)) < 0) {
      return 1;
    }
    if (reportA.report_type === 'AFTER_SEASON' && reportB.report_type === 'BEFORE_SEASON') {
      return 1;
    }
    if (reportA.report_type === 'BEFORE_SEASON' && reportB.report_type === 'AFTER_SEASON') {
      return -1;
    }
    return 0;
  });
  return propertyExecution;
};

export const mapNemadigitalExecutionsByCropCycle = (
  propertyExecution: NemadigitalExecution | undefined,
  t: TFunction<'translation', undefined>
): NemadigitalPropertyReports => {
  if (!propertyExecution) {
    return {
      cropCycles: [],
      reportsPerCropCycles: {}
    };
  }
  const cropCycles: string[] = [];
  const reportsPerCropCycles = propertyExecution.reports.reduce((executionPerCropCycle, report) => {
    const cropCycleName = `${generateBeforeSeasonName(report.report_type, t)} ${t(
      'general.map.multilayer.nemadigital.season'
    )} ${generateDateSeason(report.crop_cycle_year_start, report.crop_cycle_year_end)}`;

    cropCycles.push(cropCycleName);
    const sourceUrlPerField = {};
    const damagePerField = {};
    const confidenceLevelPerField = {};
    report.fields.forEach(field => {
      damagePerField[field.field_id] = field.damage;
      sourceUrlPerField[field.field_id] = field.link_nematode_geojson;
      confidenceLevelPerField[field.field_id] = field.confidence_index;
    });
    return {
      ...executionPerCropCycle,
      [cropCycleName]: {
        damagePerField,
        sourceUrlPerField,
        confidenceLevelPerField,
        jobId: report.job_id,
        executionId: report.execution_id,
        status: report.status
      }
    };
  }, {});

  return {
    cropCycles,
    reportsPerCropCycles
  };
};

export const onSelectNemadigitalMapCallback = (
  map: NemadigitalMapTypes,
  companyId: string | undefined,
  companyName: string | undefined,
  propertyId: string | undefined,
  propertyName: string | undefined,
  segmentTracking: SegmentTrackingHookReturn
): void => {
  const payload: AnalyticsInfo = {
    companyId,
    companyName,
    propertyId,
    propertyName,
    mapType: map
  };

  segmentTracking.track(`Timeline - select nemadigital ${map}`, payload);
};
