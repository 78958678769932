import { getSelectedPropertyId, hasRTRPlanEntitlement } from 'core/core.selectors';
import { getPropertyEntities } from 'entities/property/property.selectors';
import { isEmpty } from 'lodash';
import { useNewHallOfCompanies } from 'pages/hall-of-entities/hall-of-entities-mfe.hooks';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';

const usePropertyHasNoSeason = () => {
  const allProperties = useSelector(getPropertyEntities);
  const propertySelected = useSelector(getSelectedPropertyId);
  const isUsingNewHallOfCompanies = useNewHallOfCompanies();
  const showPropertiesWithoutSeasons = useSelector(hasRTRPlanEntitlement);

  const propertyHasNoSeason = useMemo(() => {
    if (isEmpty(allProperties) || !isUsingNewHallOfCompanies || !propertySelected || showPropertiesWithoutSeasons) return false;
    const proprieties = Object.values(allProperties);
    const property = proprieties.find(property => property.id === propertySelected);

    return !property?.seasons.length;
  }, [allProperties, isUsingNewHallOfCompanies, propertySelected, showPropertiesWithoutSeasons]);

  return propertyHasNoSeason;
};

export { usePropertyHasNoSeason };
