import { notification } from 'antd';
import type { ISDPopupAnnotation } from 'components/map/popup/sd-popup-annotation.component';
import SDPopupAnnotation from 'components/map/popup/sd-popup-annotation.component';
import SDPopupCluster from 'components/map/popup/sd-popup-cluster.component';
import type { ISDPopupMonitoring } from 'components/map/popup/sd-popup-monitoring.component';
import SDPopupMonitoring from 'components/map/popup/sd-popup-monitoring.component';
import type { ISDPopupPhenology } from 'components/map/popup/sd-popup-phenology.component';
import SDPopupPhenology from 'components/map/popup/sd-popup-phenology.component';
import i18n from 'config/i18n';
import type { Dictionary } from 'config/types';
import { emptyThreshold, Threshold } from 'core/core.models';
import { DEFAULT_UUID } from 'core/shared/map/map.functions';
import type { ISTMarkerProps, TMarkerPinStatus } from 'core/shared/map/map.models';
import type { IndexedName, Page, UUID } from 'core/utils/basic.models';
import { LissThresholds } from 'core/utils/basic.models';
import { sortArrayByDateAscending } from 'core/utils/functions';
import { getColorGeneralWithMaxValue } from 'core/utils/map/choropleth';
import type { ChoroplethLimits } from 'core/utils/map/choropleth.models';
import {
  MAP_DAE_COLORS_5,
  MAP_SEVERITY_COLORS,
  MAP_SEVERITY_COLORS_5,
  MAP_SPRAY_COLORS_5,
  SEVERITY_COLORS_10
} from 'core/utils/map/choropleth.models';
import { validateNumberOrZero } from 'core/utils/numbers';
import type { SegmentTrackingHookReturn } from 'core/utils/segment/useSegmentTracking';
import { getStringOrDefault } from 'core/utils/strings';
import type { Monitoring as AreaDayMonitoring, MonitoringExtraDimension } from 'entities/monitoring/monitoring.models';
import type { DiagnosticBreakdown, Property, Severity } from 'entities/property/property.models';
import { BreakdownType } from 'entities/property/property.models';
import type { CurrentInfo, Region } from 'entities/region/region.models';
import _, { isEmpty } from 'lodash';
import type { Moment } from 'moment';
import moment from 'moment';
import timezoneMoment from 'moment-timezone';
import type {
  Annotation,
  ArrayOfPages,
  GetNotesMarkerIconProps,
  IExtendAttrSpain,
  Monitoring,
  MonitoringPoint,
  Phenology,
  TimelineWindow,
  TimelineWindowEvents
} from 'pages/timeline/timeline.models';
import { EventKeys, EventType, FILE_TYPE } from 'pages/timeline/timeline.models';
import { createRoot } from 'react-dom/client';
import { map } from 'rxjs/operators';
import sentryService from 'sentry/service';
import type { IEditAreaRequestParams } from './area-day-info/components/area-day-info.resume.models';
import type { MonitoringDetailThreshold } from './monitoring.models';
import { LastEventType } from './region-select/timeline.region-select.models';
import { saveEditAreaInfo } from './timeline.service';

export const formatPages = (events: TimelineWindow[], pageSize: number): ArrayOfPages<TimelineWindow> => {
  const pages: Page<TimelineWindow>[] = [];
  let elementsLeft = events.length;
  let controlArray: TimelineWindow[] = events;
  for (let i = 0; i < Math.ceil(events.length / pageSize); i++) {
    pages[i] = {
      number: i,
      size: elementsLeft - pageSize >= 0 ? pageSize : elementsLeft,
      mutableContent:
        controlArray.length - pageSize >= 0
          ? sortArrayByDateAscending(controlArray.slice(0, pageSize), 'start_date')
          : sortArrayByDateAscending(controlArray.slice(0, elementsLeft), 'start_date'),
      pages: Math.ceil(events.length / pageSize),
      total: events.length
    };
    if (controlArray.length - pageSize >= 0) {
      controlArray = controlArray.slice(pageSize, controlArray.length);
    }
    elementsLeft -= pageSize;
  }
  return pages;
};

export const getLastAreaEvent = (currentInfo?: CurrentInfo): LastEventType | null => {
  let lastEvent: LastEventType | null = null;
  if (currentInfo) {
    if (currentInfo.last_monitoring) {
      if (
        !currentInfo.last_spray ||
        (currentInfo.last_spray && moment(currentInfo.last_monitoring.date).diff(moment(currentInfo.last_spray.end_date)) > 0)
      ) {
        lastEvent = LastEventType.MONITORING;
      } else {
        lastEvent = LastEventType.SPRAY;
      }
    } else if (currentInfo.last_spray) {
      lastEvent = LastEventType.SPRAY;
    }
  }
  return lastEvent;
};

export const getThresholdColor = (area: Region) => {
  const result = { backgroundColor: '#707374' };

  if (!area.current_info) {
    return result;
  }

  if (area.current_info.crop_ended) {
    result.backgroundColor = '#C1C5C8';

    return result;
  }

  const lastEvent = getLastAreaEvent(area.current_info);

  if (!lastEvent) {
    return result;
  }

  if (lastEvent === LastEventType.MONITORING && validateNumberOrZero(area.current_info.severity_level)) {
    if (area.current_info.severity_level! < Number(LissThresholds.MIN_CONTROL)) {
      result.backgroundColor = '#2FB966';
    } else if (area.current_info.severity_level! < Number(LissThresholds.MIN_DAMAGE)) {
      result.backgroundColor = '#FFCA28';
    } else {
      result.backgroundColor = '#F85555';
    }
  }

  if (lastEvent === LastEventType.SPRAY) {
    result.backgroundColor = '#0568FA';
  }

  return result;
};

export const getChoroplethSeverityColor = (area: Region, crop_ended: boolean): string => {
  let backgroundColor = 'transparent';
  const colors = MAP_SEVERITY_COLORS;
  if (!area.current_info) {
    return backgroundColor;
  }
  if (area.current_info && crop_ended) {
    backgroundColor = '#C1C5C8';
  } else if (area.current_info.last_monitoring && validateNumberOrZero(area.current_info.severity_level)) {
    if (area.current_info.severity_level! < (LissThresholds.MIN_CONTROL as number)) {
      backgroundColor = colors[0];
    } else if (area.current_info.severity_level! < (LissThresholds.MIN_DAMAGE as number)) {
      backgroundColor = colors[1];
    } else {
      backgroundColor = colors[2];
    }
  }
  return backgroundColor;
};

export const getSeverityColorFromSeverity = (severity: Severity, useNewColors = false): string => {
  const colors = useNewColors ? MAP_SEVERITY_COLORS : SEVERITY_COLORS_10;
  switch (severity.label) {
    case 'ACCEPTANCE':
      return colors[0];
    case 'CONTROL':
      return colors[1];
    case 'DAMAGE':
      return colors[2];
    default:
      return 'transparent';
  }
};

export const getDaysWithoutSprayColor = (
  area: Region,
  choroplethLimits: Dictionary<ChoroplethLimits>,
  crop_ended: boolean,
  overMaxDaysColor: string | null | undefined = undefined
): string => {
  let backgroundColor = 'transparent';
  const lastSpray = area.current_info?.last_spray;
  if (area.current_info && crop_ended) {
    backgroundColor = '#C1C5C8';
  } else if (area.current_info && lastSpray) {
    const lastSprayDate = lastSpray.start_date || lastSpray.end_date;
    const maxValueSpray = choroplethLimits.days_without_spray ? choroplethLimits.days_without_spray.max : 100;

    const colors = [...MAP_SPRAY_COLORS_5, getStringOrDefault(overMaxDaysColor, '#000')];
    backgroundColor = getColorGeneralWithMaxValue(lastSprayDate, colors, maxValueSpray);
  }

  return backgroundColor;
};

export const getDaysWithoutMonitoringColor = (
  area: Region,
  choroplethLimits: Dictionary<ChoroplethLimits>,
  crop_ended: boolean,
  overMaxDaysColor: string | null | undefined = undefined
): string => {
  let backgroundColor = 'transparent';
  const lastMonitoring = area.current_info?.last_monitoring;
  if (area.current_info && crop_ended) {
    backgroundColor = '#C1C5C8';
  } else if (area.current_info && lastMonitoring) {
    const maxValueSpray = choroplethLimits.days_without_monitoring ? choroplethLimits.days_without_monitoring.max : 100;

    const colors = [...MAP_SEVERITY_COLORS_5, getStringOrDefault(overMaxDaysColor, '#000')];
    backgroundColor = getColorGeneralWithMaxValue(lastMonitoring.date, colors, maxValueSpray);
  }

  return backgroundColor;
};

export const getDaysAfterEmergenceColor = (
  area: Region,
  choroplethLimits: Dictionary<ChoroplethLimits>,
  crop_ended: boolean,
  overMaxDaysColor: string | null | undefined = undefined,
  belowMinDaysColor: string | null | undefined = undefined,
  belowMinDays: number | undefined = 0
): string => {
  let backgroundColor = 'transparent';
  if (area.current_info && crop_ended) {
    backgroundColor = '#C1C5C8';
  } else if (area.current_info?.emergence_day) {
    const maxValueDae = choroplethLimits.days_after_emergence ? choroplethLimits.days_after_emergence.max : 100;

    const colors = [...MAP_DAE_COLORS_5, getStringOrDefault(overMaxDaysColor, '#000')];
    backgroundColor = getColorGeneralWithMaxValue(area.current_info?.emergence_day, colors, maxValueDae, belowMinDays, belowMinDaysColor);
  }

  return backgroundColor;
};

export const parseTooltipSeeds = (seeds: string[]) => {
  return seeds.reduce((total, current, idx, array) => (idx > 1 ? total + current + (idx < array.length - 1 ? ', ' : '') : ''), '');
};

export const getAreaThreshold = (area: Region): Threshold => {
  let threshold = Threshold.NONE;
  if (!area.current_info) {
    return threshold;
  }
  const lastEvent = getLastAreaEvent(area.current_info);
  if (area.current_info.crop_ended) {
    threshold = Threshold.ENDED;
  } else if (validateNumberOrZero(area.current_info.severity_level) && lastEvent === LastEventType.MONITORING) {
    if (area.current_info.severity_level! < (LissThresholds.MIN_CONTROL as number)) {
      threshold = Threshold.ACCEPTANCE;
    } else if (area.current_info.severity_level! < (LissThresholds.MIN_DAMAGE as number)) {
      threshold = Threshold.CONTROL;
    } else {
      threshold = Threshold.DAMAGE;
    }
  } else if (area.current_info && lastEvent === LastEventType.SPRAY) {
    threshold = Threshold.SPRAY;
  }
  return threshold;
};

export const buildDiagnosticBreakdown = (regions: Region[]): DiagnosticBreakdown => {
  const initialDiagnostic: DiagnosticBreakdown = {
    acceptance: 0,
    control: 0,
    damage: 0,
    undetermined: 0,
    spray: 0,
    breakdown_type: BreakdownType.AREA,
    valid_on: moment().toISOString()
  };
  const diagnosticBreakdown: DiagnosticBreakdown = regions?.reduce<DiagnosticBreakdown>((diagnostic, region) => {
    if (!region.current_info) {
      return { ...diagnostic, undetermined: diagnostic.undetermined + 1 };
    }
    const lastEvent = getLastAreaEvent(region.current_info);
    if (lastEvent === LastEventType.MONITORING) {
      if (validateNumberOrZero(region.current_info?.severity_level)) {
        if (region.current_info.severity_level! < (LissThresholds.MIN_CONTROL as number)) {
          return {
            ...diagnostic,
            acceptance: diagnostic.acceptance + 1
          };
        }
        if (region.current_info.severity_level! < (LissThresholds.MIN_DAMAGE as number)) {
          return {
            ...diagnostic,
            control: diagnostic.control + 1
          };
        }
        return {
          ...diagnostic,
          damage: diagnostic.damage + 1
        };
      }
    } else if (lastEvent === LastEventType.SPRAY) {
      return { ...diagnostic, spray: diagnostic.spray + 1 };
    }
    return { ...diagnostic, undetermined: diagnostic.undetermined + 1 };
  }, initialDiagnostic);

  return diagnosticBreakdown;
};

export const getWindowEventTypes = (window: Record<string, TimelineWindowEvents>): string[] => {
  const eventKeys: Record<string, string> = EventKeys;
  return Object.keys(window).reduce<string[]>((acc, key) => {
    const correspondingValue = Object.keys(EventKeys).find(value => value === key);
    if (correspondingValue && window[key]) {
      acc.push(eventKeys[key]);
    }
    return acc;
  }, []);
};

export const serializeWindows = (windows: TimelineWindow[]) => {
  return windows.map(window => {
    return {
      ...window,
      startDate: moment(window.start_date),
      endDate: moment(window.end_date)
    };
  });
};

export const extractExtraDimensions = (extraDimensions: MonitoringExtraDimension[]) => {
  return extraDimensions.reduce((resources, extraDimension) => {
    return { ...resources, ...extraDimension.extra_dimensions };
  }, {});
};
const extractMarkerProps = (
  point: MonitoringPoint,
  type: EventType,
  propertyId: UUID,
  propertyTimezone: string,
  reverse = false,
  monitoringById: Dictionary<AreaDayMonitoring> = {}
) => {
  const mutableStatus: TMarkerPinStatus[] = [];

  if (point.forced_point) mutableStatus.push('simulated');

  if (point.image || point?.medias?.length) mutableStatus.push('have-photo');

  let position;

  if (reverse) {
    position = point.forced_point
      ? [point.forced_point.coordinates[1], point.forced_point.coordinates[0]]
      : [point.point.coordinates[1], point.point.coordinates[0]];
  } else {
    position = point.forced_point ? point.forced_point.coordinates : point.point.coordinates;
  }
  const monitoring = monitoringById[point.id];
  const resources = monitoring ? extractExtraDimensions(monitoring.inspections_extra_dimensions) : undefined;
  return {
    id: point.id,
    markerProps: {
      type,
      status: mutableStatus,
      position
    },
    popupProps: {
      ...point,
      popup: { ...point, image: point.image || point?.medias?.[0]?.url, propertyTimezone },
      propertyId,
      resources
    }
  };
};

export const getMonitoringMarkers = (
  monitoring: Monitoring,
  propertyId: UUID,
  monitoringById: Dictionary<AreaDayMonitoring>,
  propertyTimezone: string,
  reverse = false
): ISTMarkerProps[] => {
  return monitoring.points.map(point =>
    extractMarkerProps(point, EventType.MONITORING, propertyId, propertyTimezone, reverse, monitoringById)
  ) as unknown as ISTMarkerProps[];
};

export const getPhenologyMarkers = (
  phenology: Phenology,
  propertyId: UUID,
  propertyTimezone: string,
  reverse = false
): ISTMarkerProps[] => {
  return phenology.samples.map(point =>
    extractMarkerProps(point as unknown as MonitoringPoint, EventType.PHENOLOGY, propertyId, propertyTimezone, reverse)
  ) as unknown as ISTMarkerProps[];
};

export const getAnnotationMarkers = (
  annotation: Annotation,
  propertyId: UUID,
  propertyTimezone: string,
  reverse = false
): ISTMarkerProps[] => {
  return annotation.annotations.map(point =>
    extractMarkerProps(point as unknown as MonitoringPoint, EventType.ANNOTATION, propertyId, propertyTimezone, reverse)
  ) as unknown as ISTMarkerProps[];
};

interface ILoadPopup {
  popup: mapboxgl.Popup;
  marker: ISTMarkerProps;
  enableMultiplePhotos?: boolean;
  isGalleryV2?: boolean | string;
  productsAvailable?: boolean | string;
  segmentTracking: SegmentTrackingHookReturn;
  fieldName?: string;
}

interface ILoadClusterPopup extends ILoadPopup {
  data: ISTMarkerProps[];
}

export const getLoadPopupElement = ({
  popup,
  marker,
  enableMultiplePhotos,
  isGalleryV2,
  productsAvailable,
  segmentTracking,
  fieldName
}: ILoadPopup) => {
  let popupElement;
  if (!marker.popupProps) return;
  if (marker.markerProps.type === EventType.MONITORING) {
    popupElement = <SDPopupMonitoring {...(marker.popupProps as unknown as ISDPopupMonitoring)} mapboxPopup={popup} />;
  } else if (marker.markerProps.type === EventType.PHENOLOGY) {
    popupElement = <SDPopupPhenology {...(marker.popupProps as unknown as ISDPopupPhenology)} mapboxPopup={popup} />;
  } else if (marker.markerProps.type === EventType.ANNOTATION) {
    popupElement = (
      <SDPopupAnnotation
        {...(marker.popupProps as unknown as ISDPopupAnnotation)}
        enableMultiplePhotos={enableMultiplePhotos}
        productsAvailable={productsAvailable}
        segmentTracking={segmentTracking}
        isGalleryV2={isGalleryV2 as boolean}
        fieldName={fieldName}
        mapboxPopup={popup}
      />
    );
  }
  return popupElement;
};

export function loadPopup({ popup, marker, enableMultiplePhotos, isGalleryV2, productsAvailable, segmentTracking, fieldName }: ILoadPopup) {
  const popupElement = getLoadPopupElement({
    popup,
    marker,
    enableMultiplePhotos,
    isGalleryV2,
    productsAvailable,
    segmentTracking,
    fieldName
  });

  const root = createRoot(popup._content as unknown as HTMLElement);
  root.render(popupElement);
}

export const loadClusterPopup = ({
  data,
  popup,
  enableMultiplePhotos,
  isGalleryV2,
  productsAvailable,
  segmentTracking,
  fieldName
}: ILoadClusterPopup) => {
  const root = createRoot(popup._content as unknown as HTMLElement);
  root.render(
    <SDPopupCluster
      enableMultiplePhotos={enableMultiplePhotos}
      productsAvailable={productsAvailable}
      segmentTracking={segmentTracking}
      isGalleryV2={isGalleryV2}
      fieldName={fieldName}
      mapboxPopup={popup}
      data={data}
    />
  );
};

export function getMarkersFromWindowEvents(
  windowEvents: TimelineWindowEvents,
  propertyId: string,
  propertyTimezone: string,
  selectedEvent = EventType.NONE,
  areaDayMonitorings: AreaDayMonitoring[] = [],
  reverse = false
) {
  let markers: ISTMarkerProps[] = [];

  switch (selectedEvent) {
    case EventType.NONE:
      if (windowEvents.monitoring_window) {
        const monitoringById = _.keyBy(areaDayMonitorings, 'id');
        markers = [
          ...markers,
          ...getMonitoringMarkers(windowEvents.monitoring_window, propertyId, monitoringById, propertyTimezone, reverse)
        ];
      }
      if (windowEvents.phenology_window) {
        markers = [...markers, ...getPhenologyMarkers(windowEvents.phenology_window, propertyId, propertyTimezone, reverse)];
      }
      if (windowEvents.annotation_window) {
        markers = [...markers, ...getAnnotationMarkers(windowEvents.annotation_window, propertyId, propertyTimezone, reverse)];
      }
      break;

    case EventType.MONITORING:
      if (windowEvents.monitoring_window) {
        const monitoringById = _.keyBy(areaDayMonitorings, 'id');
        markers = [
          ...markers,
          ...getMonitoringMarkers(windowEvents.monitoring_window, propertyId, monitoringById, propertyTimezone, reverse)
        ];
      }
      break;

    case EventType.PHENOLOGY:
      if (windowEvents.phenology_window) {
        markers = [...markers, ...getPhenologyMarkers(windowEvents.phenology_window, propertyId, propertyTimezone, reverse)];
      }
      break;

    case EventType.ANNOTATION:
      if (windowEvents.annotation_window) {
        markers = [...markers, ...getAnnotationMarkers(windowEvents.annotation_window, propertyId, propertyTimezone, reverse)];
      }
      break;
  }

  return markers;
}

export function getAreasFromWindowEvents(
  windowEvents: TimelineWindowEvents,
  propertyId: string | undefined,
  selectedEvent = EventType.NONE
) {
  if (!propertyId) return [];

  let areaIDs: string[] = [];

  if (selectedEvent === EventType.NONE || selectedEvent === EventType.APPLICATION) {
    if (windowEvents.spray_window) {
      areaIDs = windowEvents.spray_window.sprays.flatMap(spray => spray.areas).map(area => area.area_id);
    }
  }

  return areaIDs;
}

const getRightNameTresholdIcon = (id: string, severityPoints?: Dictionary<Threshold>) => {
  if (severityPoints?.[id]) {
    if (severityPoints[id] === Threshold.ACCEPTANCE) return 'T1';
    if (severityPoints[id] === Threshold.CONTROL) return 'T2';
    if (severityPoints[id] === Threshold.DAMAGE) return 'T3';
  }
  return 'No';
};

const getMonitoringMarkerIcon = (
  marker: ISTMarkerProps,
  hasPhoto: boolean,
  activePinColor: boolean,
  severityPoints?: Dictionary<Threshold>,
  hasMultiplePhotos?: boolean
): string => {
  const isSampling =
    marker.popupProps?.sampling_id && marker.popupProps.sampling_id !== DEFAULT_UUID && marker.popupProps.sampling_id.length > 0;
  const shouldGetThresholdColor = activePinColor && !isSampling;
  const thresholdColor = shouldGetThresholdColor ? getRightNameTresholdIcon(marker.id, severityPoints) : '';

  if (hasMultiplePhotos) {
    return `monitoringImage${thresholdColor}${marker.markerProps.status.includes('simulated') ? 'Simulated' : ''}MultiplePhoto${
      isSampling ? 'Sampling' : ''
    }`;
  }

  return `monitoringImage${thresholdColor}${marker.markerProps.status.includes('simulated') ? 'Simulated' : ''}${hasPhoto ? 'Photo' : ''}${
    isSampling ? 'Sampling' : ''
  }`;
};

export const getNotesMarkerIcon = ({
  isSingleOrMultiplePhotos,
  enableMultiplePhotos,
  hasPhoto,
  hasAudio,
  marker
}: GetNotesMarkerIconProps): string => {
  const isSimulated = marker.markerProps.status.includes('simulated');

  if (enableMultiplePhotos) {
    let noteIcon = 'note';

    if (isSimulated) noteIcon += 'Simulated';
    if (isSingleOrMultiplePhotos) noteIcon += 'Photo';
    if (hasAudio) noteIcon += 'Audio';

    return noteIcon;
  }

  if (hasPhoto) {
    return isSimulated ? 'annotationImageSimulatedPhoto' : 'annotationImagePhoto';
  }

  return isSimulated ? 'annotationImageSimulated' : 'annotationImage';
};

interface HasMedia {
  hasAudio: boolean;
  hasImage: boolean;
}

const getMarkerWithoutAndWithImages = (
  marker: ISTMarkerProps,
  popupHasNotImages: boolean,
  activePinColor: boolean,
  severityPoints: Dictionary<Threshold>,
  hasMultiplePhotos: boolean,
  enableMultiplePhotos: boolean,
  hasMedia: HasMedia
) => {
  const { hasAudio, hasImage } = hasMedia;
  if (marker.markerProps.type === EventType.MONITORING) {
    const parameterHasMultiple = !popupHasNotImages ? hasMultiplePhotos : undefined;
    return getMonitoringMarkerIcon(marker, !popupHasNotImages, activePinColor, severityPoints, parameterHasMultiple);
  } else if (marker.markerProps.type === EventType.PHENOLOGY) {
    if (!popupHasNotImages) {
      return marker.markerProps.status.includes('simulated') ? 'phenologicalStateImageSimulatedPhoto' : 'phenologicalStateImagePhoto';
    }
    return marker.markerProps.status.includes('simulated') ? 'phenologicalStateImageSimulated' : 'phenologicalStateImage';
  } else if (marker.markerProps.type === EventType.ANNOTATION) {
    return getNotesMarkerIcon({
      isSingleOrMultiplePhotos: hasMultiplePhotos || hasImage,
      enableMultiplePhotos,
      hasPhoto: !popupHasNotImages,
      hasAudio,
      marker
    });
  }
};

export const getRightMarkerIcon = (
  marker: ISTMarkerProps,
  severityPoints: Dictionary<Threshold>,
  activePinColor = false,
  enableMultiplePhotos = false
) => {
  if (!marker.popupProps && !marker) return undefined;

  const popupMediasLength = marker.popupProps?.medias?.length ?? 0;

  const ifAnnotationHasMedias = marker.markerProps.type !== EventType.ANNOTATION && popupMediasLength > 1;

  const ifHasPictures = marker.popupProps?.medias && marker.popupProps.medias.filter(media => media.type === FILE_TYPE.PICTURES).length > 1;

  const hasMultiplePhotos = ifAnnotationHasMedias || ifHasPictures;

  const hasAudio = marker.popupProps?.medias?.length && marker.popupProps.medias.some(media => media.type === FILE_TYPE.AUDIO);
  const hasImage = marker.popupProps?.image && !isEmpty(marker.popupProps.image);

  const popupHasNotImages =
    marker?.popupProps && ((marker.popupProps.medias && marker.popupProps.medias.length === 0) || isEmpty(marker.popupProps.image));

  return getMarkerWithoutAndWithImages(
    marker,
    !!popupHasNotImages,
    activePinColor,
    severityPoints,
    !!hasMultiplePhotos,
    enableMultiplePhotos,
    { hasAudio: !!hasAudio, hasImage: !!hasImage }
  );
};

interface SaveAreaInfoParams {
  area: Region;
  UpdateRegion$: (item: Region[]) => void;
  setIsSavingEditInfo: (item: boolean) => void;
  handleCloseEdit: () => void;
  currentAreaName: string;
  currentPlantingDay: Moment | null;
  currentEmergenceDay: Moment | null;
  currentHarvestDay: Moment | null;
  propertyTimeZone?: string;
  selectedVarieties: IndexedName[];
  selectedSeasonFieldId: string;
  updateCurrentAreaInfo?: (area: Region) => void;
}

export const getLocaleFormattedDate = (date: Moment | null, timeZone: string): string => {
  if (!date?.isValid()) return '';
  const localeDate = timeZone ? timezoneMoment.tz(date.format('YYYY-MM-DD'), timeZone) : date;
  return localeDate.format('YYYY-MM-DDTHH:mm:ssZZ');
};

export const saveAreaInfo = ({
  propertyTimeZone = '',
  selectedSeasonFieldId,
  updateCurrentAreaInfo,
  setIsSavingEditInfo,
  currentEmergenceDay,
  currentPlantingDay,
  currentHarvestDay,
  selectedVarieties,
  currentAreaName,
  handleCloseEdit,
  UpdateRegion$,
  area
}: SaveAreaInfoParams): void => {
  const plantingDate = getLocaleFormattedDate(currentPlantingDay, propertyTimeZone);
  const emergencyDate = getLocaleFormattedDate(currentEmergenceDay, propertyTimeZone);
  const harvestingDate = getLocaleFormattedDate(currentHarvestDay, propertyTimeZone);

  const requestParams: IEditAreaRequestParams = {
    areaName: currentAreaName,
    plantingDate,
    emergencyDate,
    harvestingDate,
    varieties: selectedVarieties.map(v => v.id),
    id: selectedSeasonFieldId
  };

  saveEditAreaInfo(requestParams)
    .pipe(map(response => response.data))
    .subscribe(
      () => {
        setIsSavingEditInfo(false);
        handleCloseEdit();
        const updatedRegion: Region = {
          ...area,
          name: currentAreaName,
          current_info: {
            ...area.current_info,
            area_in_hectares: area.current_info!.area_in_hectares,
            severity_level: area.current_info!.severity_level,
            seeds: selectedVarieties.map(v => v.name),
            planting_day: plantingDate || area.current_info!.planting_day,
            emergence_day: emergencyDate || area.current_info!.emergence_day
          }
        };
        UpdateRegion$([updatedRegion]);
        if (updateCurrentAreaInfo) {
          updateCurrentAreaInfo(area);
        }
      },
      error => {
        notification.error({ message: i18n.t('alert.messages.error') });

        sentryService.captureException(error, {
          fileName: 'timeline.functions',
          method: 'saveAreaInfo'
        });
      }
    );
};

export const onHandleCreateRegionObject = (
  area: Region,
  currentAreaName: string,
  selectedVarieties: IndexedName[],
  plantingDate: string | null,
  emergencyDate: string | null,
  harvestingDate: string
): Region | null => {
  if (area.current_info) {
    const updatedRegion: Region = {
      ...area,
      name: currentAreaName,
      current_info: {
        ...area.current_info,
        area_in_hectares: area.current_info.area_in_hectares,
        severity_level: area.current_info.severity_level,
        seeds: selectedVarieties.map(v => v.name),
        planting_day: plantingDate ?? undefined,
        emergence_day: emergencyDate ?? undefined,
        harvest_day: harvestingDate
      }
    };

    return updatedRegion;
  }
  return null;
};

export interface IOnHandleUpdateAreaInfoParams {
  area: Region;
  currentAreaName: string;
  currentPlantingDay: string | null;
  currentEmergenceDay: string | null;
  currentHarvestDay: string;
  selectedVarieties: IndexedName[];
  selectedSeasonAreaId: UUID;
}

export const onHandleUpdateAreaInfo = ({
  area,
  currentAreaName,
  currentPlantingDay,
  currentEmergenceDay,
  currentHarvestDay,
  selectedVarieties,
  selectedSeasonAreaId
}: IOnHandleUpdateAreaInfoParams): Promise<Region | null | Error> => {
  const requestParams: IEditAreaRequestParams = {
    areaName: currentAreaName,
    plantingDate: currentPlantingDay,
    emergencyDate: currentEmergenceDay,
    harvestingDate: currentHarvestDay,
    varieties: selectedVarieties.map(v => v.id),
    id: selectedSeasonAreaId
  };

  return saveEditAreaInfo(requestParams)
    .toPromise()
    .then(() => {
      return onHandleCreateRegionObject(
        area,
        currentAreaName,
        selectedVarieties,
        currentPlantingDay,
        currentEmergenceDay,
        currentHarvestDay
      );
    })
    .catch((e: Error) => {
      sentryService.captureException(e, {
        fileName: 'timeline.functions',
        method: 'saveEditAreaInfo'
      });
      return e;
    });
};

const checkValueIsOnThreshold = (value: number, threshold: MonitoringDetailThreshold, isPositiveIndicator = false) => {
  const interval = [threshold.left, threshold.right].sort((a, b) => a - b);
  return !isPositiveIndicator ? value >= interval[0] : value <= interval[1];
};

export const getIndicatorThreshold = (value: number, thresholds?: MonitoringDetailThreshold[]): MonitoringDetailThreshold => {
  if (!thresholds?.length) return emptyThreshold;
  const isPositive = thresholds[0].right > thresholds[1].right;
  const sortedThresholdsDesc = [...thresholds].sort((thrA, thrB) => (isPositive ? thrA.left - thrB.left : thrB.left - thrA.left));
  return (
    sortedThresholdsDesc.find(thr => checkValueIsOnThreshold(value, thr, isPositive)) ??
    sortedThresholdsDesc[sortedThresholdsDesc.length - 1]
  );
};

export const createBreadcrumbRegion = (
  regions: Dictionary<Region>,
  selectedProperty: Property | undefined,
  selectedRegionOrAreaId: string | undefined | null
): string | undefined => {
  const rootRegionId = selectedProperty?.root_region_id;

  if (!rootRegionId || !selectedRegionOrAreaId) return undefined;

  const regionsNames: string[] = [];
  let currentRegionId = selectedRegionOrAreaId;

  while (currentRegionId) {
    const region = regions[currentRegionId];
    if (!region) break;
    regionsNames.push(region.name);
    currentRegionId = region.parent_id!;
  }

  regionsNames.reverse();
  const breadcrumb = regionsNames.slice(1).join(' > ');
  return breadcrumb;
};

export const setCorrectPixelRatioToIconImages = (image: { id: string; url: string }): number => {
  const includesMonitoringOrNote = image.id.includes('monitoring') || image.id.includes('note');
  return includesMonitoringOrNote ? 2 : 1;
};

export const getStartDateFromMutableContentOfPage = (content: TimelineWindow[], isFirst: boolean): Moment | undefined => {
  return content[isFirst ? 0 : content.length - 1]?.start_date;
};

export const getSigpac = (area?: Region | null) => {
  const extended_attributes = area?.json_extended_attributes as unknown as IExtendAttrSpain;
  const sigpac: string = extended_attributes?.sigpacCode ?? null;

  if (!sigpac) return '';

  const dgcCode: string = extended_attributes?.dgcCode ?? null;
  return dgcCode ? `${sigpac}.${dgcCode}` : `${sigpac}`;
};
