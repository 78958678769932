import type { RangePickerValue } from 'antd/lib/date-picker/interface';
import type { TreeNode } from 'antd/lib/tree-select';
import type { Region } from 'entities/region/region.models';
import isEmpty from 'lodash/isEmpty';
import type { Moment } from 'moment';
import moment from 'moment';
import type { Note } from 'syngenta-digital-react-cropwise-notes/dist/src/utils/models';
import { QuickFilterValues, RegionType } from './notes.model';

export const getLast30DaysRange = (): RangePickerValue => {
  const todayUsingMoment = moment();
  const startOfLast30Days = moment().subtract(QuickFilterValues.LAST_30_DAYS, 'days');
  return [startOfLast30Days, todayUsingMoment];
};

export const formatRegionsToTreeNode = (rootRegion: Region, regionsDictionary: Record<string, Region>): TreeNode[] => {
  const groupedByParent = Object.values(regionsDictionary).reduce((acc: Record<string, Region[]>, region) => {
    const parentId = region.parent_id ?? '';
    acc[parentId] = acc[parentId] || [];
    acc[parentId].push(region);
    return acc;
  }, {});

  const recursiveFormat = (regions: Region[]) => {
    return regions.map(region => {
      const formattedChild: TreeNode = {
        key: region.id,
        value: region.id,
        title: region.name,
        isLeaf: region.type === (RegionType.AREA as unknown as RegionType)
      };

      if (!region.children || region.children.length === 0) {
        return formattedChild;
      }

      formattedChild.children = recursiveFormat(groupedByParent[region.id] || []);
      return formattedChild;
    });
  };

  return recursiveFormat(groupedByParent[rootRegion.id] || []);
};

export const getInputtedDate = (value: RangePickerValue): string | null => {
  const to = moment(value[0]);
  const from = moment(value[1]);

  let quickSelectedDays: string | null = null;

  if (from.format('YYYY-MM-DD') === moment().format('YYYY-MM-DD')) {
    const diff = from.diff(to, 'days');

    switch (diff) {
      case QuickFilterValues.TODAY:
        quickSelectedDays = 'TODAY';
        break;
      case QuickFilterValues.LAST_3_DAYS:
        quickSelectedDays = 'LAST_3_DAYS';
        break;
      case QuickFilterValues.LAST_7_DAYS:
        quickSelectedDays = 'LAST_7_DAYS';
        break;
      case QuickFilterValues.LAST_14_DAYS:
        quickSelectedDays = 'LAST_14_DAYS';
        break;
      case QuickFilterValues.LAST_30_DAYS:
        quickSelectedDays = 'LAST_30_DAYS';
        break;
    }
  }

  return quickSelectedDays;
};

export const parsedContextPropFromInsideNotes = (context: Note['context']): Record<string, string> | undefined => {
  if (!context || isEmpty(context)) return undefined;

  return context.split('/').reduce((acc, cur) => {
    const [key, value] = cur.split(':');

    if (key && value) acc[key] = value;

    return acc;
  }, {} as Record<string, string>);
};

export const parseTagsToURLParams = (tags: string[] | undefined): string | undefined => {
  return tags?.reduce((acc, tag) => `${acc}&tag=${tag}`, '');
};

export const parseDateToUTC0 = ({ type, date }: { type: 'startOf' | 'endOf'; date?: Moment | null }): string => {
  const parseDate = date?.[type]('day');
  return moment.utc(parseDate).format('YYYY-MM-DDTHH:mm:ss.SSS[Z]');
};

export const fixLanguageCode = (languageCode: string): string => {
  const [language, region] = languageCode.split('-');
  if (language && region && region.length === 2) {
    const regionUpperCase = region.toUpperCase();

    return [language, regionUpperCase].join('-');
  }
  return languageCode;
};
