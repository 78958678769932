import i18n from 'config/i18n';
import SvgDiseaseRisk from 'st-protector-icons/Minor/DiseaseRisk';
import type { GetHomePageProps, PropertyData } from './dashboard-property-card.models';
import { homePageMapping } from './dashboard-property-card.models';

export const getDiseaseRiskItem = (
  showItem: boolean,
  navigateTo: (destiny: string, segmentEvent: string, from: string) => void,
  property: PropertyData
) => {
  return [
    {
      show: showItem,
      title: i18n.t('pages.disease_risk.configuration.name'),
      name: 'disease-risk',
      onClick: () => navigateTo(`property/${property.id}/disease-risk`, 'Open Disease Risk', 'Hall of Properties - Disease Risk button'),
      icon: <SvgDiseaseRisk data-testid='disease-risk-icon-property-card' fill='#C1C5C8' />
    }
  ];
};

export const getHomePage = ({ hasDiseaseRiskLandingPage, showOnlyNotesFeature }: GetHomePageProps) => {
  if (showOnlyNotesFeature) {
    return homePageMapping.showOnlyNotesFeature;
  }

  if (hasDiseaseRiskLandingPage) {
    return homePageMapping.hasDiseaseRiskLandingPage;
  }

  return homePageMapping.default;
};
