import type React from 'react';
import type { SVGProps } from 'react';

const SvgOpportunityMachineIcon: React.VFC<SVGProps<SVGSVGElement>> = props => (
  <svg width={21} height={20} viewBox='0 0 21 20' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M13.3639 19.9978L7.63663 19.9978L7.63663 15.4064C5.92799 14.4137 4.77299 12.5714 4.77299 10.4523C4.77299 7.2928 7.34072 4.72507 10.5003 4.72507C13.6598 4.72508 16.2275 7.2928 16.2275 10.4523C16.2275 12.5714 15.0725 14.4137 13.3639 15.4064L13.3639 19.9978ZM9.54545 18.0887L9.54545 14.3087L8.59091 13.7551C7.40727 13.0678 6.68182 11.8078 6.68182 10.4523C6.68182 8.3428 8.39046 6.63417 10.5 6.63417C12.6095 6.63417 14.3182 8.3428 14.3182 10.4523C14.3182 11.8078 13.5832 13.0678 12.4091 13.7551L11.4545 14.3087L11.4545 18.0887L9.54545 18.0887ZM18.1364 11.4547L21 11.4547L21 9.54558L18.1364 9.54558L18.1364 11.4547ZM18.5664 3.73397L17.2205 2.38806L15.5119 4.10624L16.8578 5.45215L18.5664 3.73397ZM11.4541 2.86377L9.54501 2.86377L9.54501 0.000132513L11.4541 0.00013268L11.4541 2.86377ZM2.86328 11.4546L-0.00035402 11.4546L-0.000353853 9.5455L2.86328 9.5455L2.86328 11.4546ZM3.78932 2.37525L5.49796 4.09343L4.1616 5.4298L2.44341 3.72116L3.78932 2.37525Z'
      fill={props.fill ?? 'white'}
    />
  </svg>
);

export default SvgOpportunityMachineIcon;
