import { VITE_ENV } from 'config/constants';
import { getCurrentUnitSystem, getLicensingStatus } from 'core/core.selectors';
import { getSelectedCompany } from 'entities/company/company.reducer';
import { getSelectedProperty } from 'entities/property/property.reducer';
import { getSelectedSeasonsIds } from 'entities/season/season.reducer';
import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';

const loadSmartPlan = () => import('smart-plan');

interface SmartPlanProps {
  config: {
    unitSystem: string;
    org?: string;
    cropCycle: string[];
    token: string | null;
    environment?: string;
    baseRoute?: string;
    locale: string | null;
    appId?: string;
    workspaceId?: string;
  };
}

const SmartPlan = React.lazy(() =>
  loadSmartPlan().then((module: { SmartPlan: React.FC<SmartPlanProps> }) => ({ default: module.SmartPlan }))
);

const LocalSmartPlan = ({ routeLevel = 'organization' }: { routeLevel?: 'organization' | 'property' }) => {
  const company = useSelector(getSelectedCompany);
  const property = useSelector(getSelectedProperty);
  const selectedSeasonsIds = useSelector(getSelectedSeasonsIds);
  const currentUnitSystem = useSelector(getCurrentUnitSystem);
  const licenseStatus = useSelector(getLicensingStatus);

  const config: SmartPlanProps['config'] = useMemo(
    () => ({
      unitSystem: currentUnitSystem,
      org: company?.id,
      token: localStorage.getItem('access_token'),
      baseRoute:
        routeLevel === 'organization'
          ? `/company/${company?.id}/smart-plan`
          : `/company/${company?.id}/property/${property?.id}/smart-plan`,
      environment: VITE_ENV,
      cropCycle: selectedSeasonsIds,
      locale: localStorage.getItem('i18nextLng'),
      appId: licenseStatus?.apps[0].id,
      workspaceId: licenseStatus?.workspace.id
    }),
    [company, currentUnitSystem, licenseStatus, property, routeLevel, selectedSeasonsIds]
  );

  return <SmartPlan key={property?.id} config={config} />;
};

export default LocalSmartPlan;
