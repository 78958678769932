const SvgStaticPointMenu = ({ ...props }) => (
  <svg width='1em' height='1em' viewBox='0 0 24 24' fontSize='24px' {...props}>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M12 1L20 9.68421L12 23L4 9.68421L12 1ZM12 3.88027L6.42182 9.93553L12 19.2203L17.5782 9.93553L12 3.88027Z'
    />
  </svg>
);

export default SvgStaticPointMenu;
