import type { Selector } from 'core/core.selectors';
import { createSelector } from 'reselect';
import type { Product, ProductState } from './product.models';
import { selectAllProducts } from './product.reducer';

export const getProductEntitiesInEntities: Selector<ProductState['entities']> = state => state.entities.product.entities;

export const getAllProducts: Selector<Product[]> = state => selectAllProducts(state.entities.product);

export const getProductLoaded: Selector<ProductState['productLoaded']> = state => state.entities.product.productLoaded;

export const getDeletedProductsFromProductEntities = createSelector(getProductEntitiesInEntities, products => {
  const deleted: Product[] = [];
  for (const product in products) {
    if (Object.prototype.hasOwnProperty.call(products, product)) {
      const safeProduct = products[product];
      if (safeProduct.deleted_at) {
        deleted.push(safeProduct);
      }
    }
  }
  return deleted;
});

export const getProductsError: Selector<ProductState['error']> = state => state.entities.product.error;
