import axios from 'axios-observable';
import { PROTECTOR_API_URL } from 'config/constants';
import { queryClient } from 'config/react-query';
import type { UUID } from 'core/utils/basic.models';
import _ from 'lodash';
import { SeasonQueryType } from 'querys/season/season.query.models';
import type { Observable } from 'rxjs';
import { forkJoin, of } from 'rxjs';
import { fromPromise } from 'rxjs/internal-compatibility';
import { catchError, concatMap, exhaustMap, map } from 'rxjs/operators';
import type { Nullable } from '../../core/core.models';
import { FIELDS_STORE_NAME, IndexedDBPromiseAPI, PROPERTY_ID_INDEX } from '../../core/indexeddb.utils';
import { getObservables } from '../../core/rxjs-utils';
import type { FieldIdsByEndDateInSeasonField } from '../field/field.service';
import { getAllFieldsByIds } from '../field/field.service';
import type { CurrentSeasonArea, FieldSeason } from '../property/property.models';
import { getRegionsByProperty, getSeasonAreasBySeasons } from '../property/property.service';
import type { Season } from '../season/season.models';
import type { AreaVariable, CurrentInfo, Field, IPayloadRegionData, IRegionDataResponse, Region } from './region.models';
import { buildDeepRegion, getFieldIdsByEndDateInSeasonField } from './region.utils';

const protectorApiUrl = PROTECTOR_API_URL;
const archeTimelineUrl = `${protectorApiUrl}/v1/timeline`;
const regionUrl = `${protectorApiUrl}/v1/panel/property/`;
const areaVariableUrl = `${protectorApiUrl}/v1/area-variables`;
const CHUNK_CURRENT_INFO_FIELD_IDS = 300;
const regionDataURL = (id: string) => `${regionUrl}${id}/regions/last-event`;

export interface FieldCurrentInfoResource {
  field_id: UUID;
  current_info: CurrentInfo;
}

interface UpdateSeasonAreasMassivelyPayloadModel {
  plantingDate?: string;
  emergencyDate?: string;
  harvestingDate?: string;
  varieties?: UUID[];
}
export interface UpdateSeasonAreasMassivelyPayload {
  model: UpdateSeasonAreasMassivelyPayloadModel;
  seasonIds: UUID[];
  fieldToUpdate: string;
}

/**
 * Get Region tree. The region tree is composed by its children and its information like name, id, seasons, crops - for each subregion too.
 * @param propertyId {UUID} Property Id.
 * @param seasons {UUID[]} Related seasons for load only fields from seasons.
 * @param geometryDate Used for field versioning
 * @param seasonAreasFromActiveSeasons Current season areas for dont need to load season fields again
 * @param useSeasonAreasFromActiveSeasons If is for use season areas for load regions
 * @param useGetRegionsFlat If is for just load fields, without varieties, crops, load season fields, etc
 */
export const getRegion = (
  propertyId: UUID,
  seasons: Season[],
  geometryDate: Nullable<string> = null,
  seasonAreasFromActiveSeasons: CurrentSeasonArea[] = [],
  useSeasonAreasFromActiveSeasons = false,
  getGeometriesBySeasonFieldEndDate = false
): Observable<Region> => {
  return useSeasonAreasFromActiveSeasons
    ? getRegionForFieldSeasons(propertyId, seasons, geometryDate, seasonAreasFromActiveSeasons, getGeometriesBySeasonFieldEndDate)
    : getRegionForSeason(propertyId, seasons, geometryDate, getGeometriesBySeasonFieldEndDate);
};

/**
 * Get Regions tree for some season. Just fields in the seasons will be returned here.
 * @param regionId {UUID} Root region id.
 * @param propertyId {UUID} Related property for get fields.
 * @param seasons {Season[]>} Related seasons for get fields.
 * @param geometryDate Used for field versioning
 */
const getRegionForSeason = (
  propertyId: UUID,
  seasons: Season[],
  geometryDate: Nullable<string> = null,
  getGeometriesBySeasonFieldEndDate = false
): Observable<Region> => {
  const seasonIds = seasons.map(s => s.id);
  return forkJoin([getSeasonAreasBySeasons(propertyId, seasonIds)]).pipe(
    exhaustMap(([seasonsFieldsFlat]) => {
      queryClient.setQueryData([SeasonQueryType.GET_SEASON_AREAS_FROM_SELECTED_SEASONS, seasonIds, propertyId], seasonsFieldsFlat);
      const seasonFields = seasonsFieldsFlat.flat(Infinity);
      return getRegionForFieldSeasons(propertyId, seasons, geometryDate, seasonFields, getGeometriesBySeasonFieldEndDate);
    })
  );
};

/**
 * Get Regions tree for current season areas. Just active areas will be returned here.
 * @param propertyId {UUID} Related property for get fields.
 * @param seasons {Season[]>} Related seasons for get fields.
 * @param geometryDate Used for field versioning
 * @param fieldSeasons Current season areas loaded
 */
const getRegionForFieldSeasons = (
  propertyId: UUID,
  seasons: Season[],
  geometryDate: Nullable<string> = null,
  fieldSeasons: FieldSeason[],
  getGeometriesBySeasonFieldEndDate = false
): Observable<Region> => {
  const fieldsIds: UUID[] = fieldSeasons.map(sf => sf.areaId);

  let fieldIdsByEndDateInSeasonField;
  if (getGeometriesBySeasonFieldEndDate) {
    fieldIdsByEndDateInSeasonField = getFieldIdsByEndDateInSeasonField(fieldSeasons);
  }

  return forkJoin([getRegionsByProperty(propertyId), getFields(propertyId, fieldsIds, geometryDate, fieldIdsByEndDateInSeasonField)]).pipe(
    exhaustMap(([abstractRegions, fields]) => {
      const formattedFields = fields.map(f => ({ ...f, geometry: f.geometry || { type: 'Polygon', coordinates: [] } }));

      const deepRegion: Region = buildDeepRegion(abstractRegions, fieldSeasons, formattedFields, seasons);
      return of(deepRegion);
    })
  );
};

const getFields = (
  propertyId: UUID,
  fieldIds: UUID[],
  geometryDate: Nullable<string> = null,
  fieldIdsByEndDateInSeasonField?: FieldIdsByEndDateInSeasonField
): Observable<Field[]> => {
  return forkJoin([
    getAllFieldsByIds(fieldIds, true, geometryDate, false, fieldIdsByEndDateInSeasonField),
    getGeometriesCache(propertyId)
  ]).pipe(
    exhaustMap(([fields, cachedFields]) => {
      const idsNotCached = getFieldsNotCached(fields.flat(), cachedFields);

      const groupCachedFields = _.keyBy(cachedFields, 'id');

      const validCachedFields = fieldIds.reduce((accumulator, fieldId) => {
        if (!groupCachedFields[fieldId] || idsNotCached.includes(fieldId)) {
          return accumulator;
        }

        return [...accumulator, groupCachedFields[fieldId]];
      }, [] as Field[]);

      let idsNotCachedByEndDateInSeasonField;
      if (fieldIdsByEndDateInSeasonField) {
        const endDates = Object.keys(fieldIdsByEndDateInSeasonField);
        idsNotCachedByEndDateInSeasonField = idsNotCached.reduce<Record<string, string[]>>((accumulator, fieldId) => {
          const mutableAccumulator = accumulator;

          endDates.forEach(endDate => {
            if (fieldIdsByEndDateInSeasonField[endDate].includes(fieldId)) {
              if (!mutableAccumulator[endDate]) {
                mutableAccumulator[endDate] = [];
              }

              mutableAccumulator[endDate].push(fieldId);
            }
          });

          return mutableAccumulator;
        }, {});
      }

      return forkJoin([of(validCachedFields), getGeometries(idsNotCached, true, geometryDate, idsNotCachedByEndDateInSeasonField)]);
    }),
    exhaustMap(([cachedFields, requestFields]) => {
      const fields = [...cachedFields, ...requestFields.flat()];
      insertGeometriesCache(fields).catch(e => {
        console.log('error in Geometries Cache =>', e);
      });
      return of(fields);
    })
  );
};

const getFieldsNotCached = (fields: Field[], cachedFields: Field[]) => {
  const cachedFieldsById = _.mapKeys(cachedFields || [], 'id');
  return fields.reduce((accumulator, field) => {
    if (cachedFieldsById[field.id]?.updated_at === field.updated_at) {
      return accumulator;
    }
    return [...accumulator, field.id];
  }, [] as string[]);
};

const getGeometries = (
  ids: string[],
  useAsync: boolean,
  geometryDate: Nullable<string>,
  fieldIdsByEndDateInSeasonField?: FieldIdsByEndDateInSeasonField
) => {
  return ids?.length ? getAllFieldsByIds(ids, useAsync, geometryDate, true, fieldIdsByEndDateInSeasonField) : of([]);
};

const insertGeometriesCache = (fields: Field[]) => {
  return IndexedDBPromiseAPI.insertValues(FIELDS_STORE_NAME, fields).then(
    () => console.info('Geometries updated on IDB!'),
    error => console.error('Error: ', error)
  );
};

const getGeometriesCache = (propertyId: string): Observable<Field[]> => {
  return fromPromise(IndexedDBPromiseAPI.queryIndex(FIELDS_STORE_NAME, PROPERTY_ID_INDEX, propertyId) as Promise<Field[]>).pipe(
    concatMap(result => of(result)),
    catchError(() => of([]))
  );
};

export const editAreaVariablesMassively = (areaVariables: AreaVariable[], propertyId: UUID) => {
  const url = `${protectorApiUrl}/v1/properties/${propertyId}/area-variables/update`;
  const requests = _.chunk(areaVariables, 500).map(chunk => {
    return axios.put(url, chunk);
  });
  return getObservables(requests, true).pipe(map(response => response.flat(1)));
};

export const createAreaVariablesMassively = (areaVariables: AreaVariable[], propertyId: UUID) => {
  const url = `${protectorApiUrl}/v1/properties/${propertyId}/area-variables/save`;
  const requests = _.chunk(areaVariables, 500).map(chunk => {
    return axios.post(url, chunk);
  });
  return getObservables(requests, true).pipe(map(response => response.flat(1)));
};

/**
 * @deprecated v1/season-areas will be deprecated in the future if possible use PUT /v2/season-fields/{uuid} */
export const updateSeasonAreasMassively = (payload: UpdateSeasonAreasMassivelyPayload) => {
  const url = `${protectorApiUrl}/v1/season-areas/update`;
  return axios.put(url, payload);
};

export const getPropertyCurrentInfo = (propertyId: UUID, seasonIds: UUID[]) => {
  return axios
    .post<Record<string, CurrentInfo>>(`${archeTimelineUrl}/${propertyId}/current-info`, {
      property_id: propertyId,
      season_ids: seasonIds
    })
    .pipe(map(response => response.data));
};

export const getCurrentInfosByFields = (propertyId: UUID, fieldIds: UUID[], seasonIds: UUID[]) => {
  return axios
    .post<FieldCurrentInfoResource[]>(`${regionUrl}${propertyId}/fields/current-info?allowPastSeasons=true`, {
      field_ids: fieldIds,
      season_ids: seasonIds
    })
    .pipe(map(response => response.data));
};

export const getCurrentInfosByFieldIdsPaginated = (propertyId: UUID, fieldIds: UUID[], seasonIds: UUID[]) => {
  const totalChunkedPages = Math.floor(fieldIds.length / CHUNK_CURRENT_INFO_FIELD_IDS + 1);
  const pages = [...Array(totalChunkedPages).keys()];
  const observables = pages.map(page => {
    const idsChunked = fieldIds.slice(page * CHUNK_CURRENT_INFO_FIELD_IDS, (page + 1) * CHUNK_CURRENT_INFO_FIELD_IDS);
    return getCurrentInfosByFields(propertyId, idsChunked, seasonIds);
  });
  return getObservables(observables, true);
};

export const getAreaVariable = (seasonId: UUID) => {
  return axios.get<AreaVariable[]>(`${areaVariableUrl}?season_id=${seasonId}&size=999`).pipe(map(response => response.data));
};

export const postAreaVariable = (propertyId: string | null, content: AreaVariable) => {
  return axios.post<unknown>(`${areaVariableUrl}/${propertyId}/save`, content).pipe(map(response => response.data));
};

export const updateAreaVariable = (propertyId: string | null, content: AreaVariable) => {
  return axios.put<unknown>(`${areaVariableUrl}/${propertyId}/update`, content).pipe(map(response => response.data));
};

export const deleteAreaVariable = (areaVariables: AreaVariable[], companyId?: string, propertyId?: string) => {
  return axios
    .delete<unknown>(`${areaVariableUrl}/delete`, {
      data: areaVariables.map(areaVariable => ({ ...areaVariable, company_id: companyId, property_id: propertyId }))
    })
    .pipe(map(response => response.data));
};

export const getRegionData = (payload: IPayloadRegionData) => {
  return axios
    .post<IRegionDataResponse[]>(`${regionDataURL(payload.propertyId)}`, { regionsIds: payload.regionsId })
    .pipe(map(response => response.data));
};
