import { selectCurrentUser } from 'core/services/auth/auth.reducer';
import type { UUID } from 'core/utils/basic.models';
import useSegmentTracking from 'core/utils/segment/useSegmentTracking';
import { getSelectedCompany } from 'entities/company/company.reducer';
import { getSelectedProperty } from 'entities/property/property.reducer';
import type { Region } from 'entities/region/region.models';
import { selectRootRegion } from 'entities/region/region.reducer';
import { useNemadigital } from 'pages/nemadigital/nemadigital.hooks';
import type { AvailableFields } from 'pages/nemadigital/nemadigital.model';
import { useGetPropertyLatestExecution } from 'querys/nemadigital/nemadigital.query';
import { useCallback, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import type { AppState } from 'redux/app-state';
import { JobStatusEnum } from 'syngenta-digital-react-cropwise-nemadigital';
import { generateNemaSamplingPointsReport } from './report-nema-sampling-points.functions';
import type { AcceptedFileFormat } from './report-nema-sampling-points.types';

interface UseNamaSamplingPointsProps {
  callbackDrawer: () => void;
}

function useNemaSamplingPoints({ callbackDrawer }: UseNamaSamplingPointsProps) {
  const [fileFormat, setFileFormat] = useState<AcceptedFileFormat>('KML');

  const property = useSelector(getSelectedProperty);
  const currentSeasons = useSelector((state: AppState) => state.uiState.global.selectedSeasons);
  const company = useSelector((state: AppState) => getSelectedCompany(state));
  const regions = useSelector((state: AppState) => state.entities.region.entities);
  const rootRegion = useSelector<AppState, Region | null>(selectRootRegion);
  const user = useSelector(selectCurrentUser);
  const { availableFields } = useNemadigital(false);

  const getRegionsWithParents = (allRegions: Record<UUID, Region>, fields: AvailableFields[]): Record<UUID, Region> => {
    return fields.reduce((result, field) => {
      let region = allRegions[field.id];
      while (region) {
        result[region.id] = region;
        const nextRegion = region.parent_id ? allRegions[region.parent_id] : undefined;
        if (!nextRegion) break;
        region = nextRegion;
      }
      return result;
    }, {} as Record<UUID, Region>);
  };

  const filteredRegionsWithAvailableFields = useMemo(() => {
    if (!availableFields) return {};
    return getRegionsWithParents(regions, availableFields);
  }, [availableFields, regions]);

  const [selectedAreas, setSelectedAreas] = useState<UUID[]>(() => availableFields?.map(field => field.id) ?? []);

  const { lastCropCyclesFinished, reportsPerCropCycles } = useGetPropertyLatestExecution(true);

  const dispatch = useDispatch();
  const segmentTracking = useSegmentTracking();

  const executionId = useMemo(() => {
    if (reportsPerCropCycles[lastCropCyclesFinished] && 'executionId' in reportsPerCropCycles[lastCropCyclesFinished]) {
      return String(reportsPerCropCycles[lastCropCyclesFinished].executionId);
    }
    return null;
  }, [reportsPerCropCycles, lastCropCyclesFinished]);

  const reportStatus = useMemo(() => {
    return {
      isAvailable: reportsPerCropCycles[lastCropCyclesFinished]?.status === JobStatusEnum.FINISHED,
      status: reportsPerCropCycles[lastCropCyclesFinished]?.status
    };
  }, [reportsPerCropCycles, lastCropCyclesFinished]);

  const handleSelectAreas = (value: string[]) => {
    setSelectedAreas(value);
  };

  const closeDrawer = useCallback(() => {
    callbackDrawer();
    setSelectedAreas([]);
  }, [callbackDrawer]);

  const handleGenerateReport = useCallback(() => {
    if (!user || !executionId || !reportStatus.isAvailable) return;
    generateNemaSamplingPointsReport({
      property,
      company,
      selectedAreas,
      closeDrawer,
      dispatch,
      regions,
      segmentTracking,
      executionId,
      exportType: fileFormat
    });
  }, [
    closeDrawer,
    company,
    dispatch,
    property,
    segmentTracking,
    selectedAreas,
    user,
    regions,
    fileFormat,
    executionId,
    reportStatus.isAvailable
  ]);

  const hasSelectedAreas = !!selectedAreas.length;

  return {
    closeDrawer,
    currentSeasons,
    fileFormat,
    handleGenerateReport,
    handleSelectAreas,
    hasSelectedAreas,
    property,
    regions: filteredRegionsWithAvailableFields,
    rootRegion,
    selectedAreas,
    setFileFormat,
    user,
    lastCropCyclesFinished,
    report: {
      isAvailable: reportStatus.isAvailable,
      status: reportStatus.status,
      name: lastCropCyclesFinished
    }
  };
}

export default useNemaSamplingPoints;
