import type { RumInitConfiguration } from '@datadog/browser-rum';
import { datadogRum } from '@datadog/browser-rum';
import { getSystemFlags } from 'core/core.selectors';
import { selectCurrentUser } from 'core/services/auth/auth.reducer';
import useVersion from 'core/shared/version/useVersion.hook';
import { JSONsafeParse } from 'core/utils/json/json.utils';
import { isTrustedDomain } from 'core/utils/requests';
import { getSelectedCompany } from 'entities/company/company.reducer';
import { getSelectedProperty } from 'entities/property/property.reducer';
import { isEmpty } from 'lodash';
import { useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';

interface DatadogConfig {
  sessionSampleRate: number;
  sessionReplaySampleRate: number;
  telemetrySampleRate: number;
  traceSampleRate: number;
  trackResources: boolean;
  trackLongTasks: boolean;
  trackUserInteractions: boolean;
  enablePrivacyForActionName: boolean;
  compressIntakeRequests: boolean;
}

export const useDatadogRum = () => {
  const user = useSelector(selectCurrentUser);
  const selectedOrg = useSelector(getSelectedCompany);
  const propertyInfo = useSelector(getSelectedProperty);

  const systemFlags = useSelector(getSystemFlags);
  const tracingEnabled = useMemo(() => systemFlags?.P40_44838_DATADOG_RUM, [systemFlags?.P40_44838_DATADOG_RUM]);

  const traceConfig = useMemo(() => {
    const defaultConfig: DatadogConfig = {
      sessionReplaySampleRate: 100,
      sessionSampleRate: 100,
      telemetrySampleRate: 100,
      traceSampleRate: 100,
      trackResources: true,
      trackLongTasks: true,
      trackUserInteractions: true,
      enablePrivacyForActionName: false,
      compressIntakeRequests: true
    };

    if (isEmpty(systemFlags?.P40_44838_DATADOG_CONFIG)) {
      return defaultConfig;
    } else {
      return {
        ...defaultConfig,
        ...JSONsafeParse(systemFlags?.P40_44838_DATADOG_CONFIG as string)
      } as DatadogConfig;
    }
  }, [systemFlags?.P40_44838_DATADOG_CONFIG]);

  const version = useVersion();

  useEffect(() => {
    if (user) {
      datadogRum.setUser({
        id: user.id,
        name: user.name,
        email: user.email,
        baseRole: user.role,
        protectorRole: user.job_title
      });
    }
  }, [user]);

  useEffect(() => {
    if (selectedOrg?.id) {
      datadogRum.setGlobalContextProperty('organizationInfo', {
        id: selectedOrg?.id,
        name: selectedOrg?.name
      });
    }
  }, [selectedOrg?.id, selectedOrg?.name]);

  useEffect(() => {
    if (propertyInfo?.id) {
      datadogRum.setGlobalContextProperty('propertyInfo', {
        id: propertyInfo?.id,
        name: propertyInfo?.name
      });
    }
  }, [propertyInfo?.id, propertyInfo?.name]);

  useEffect(() => {
    if (tracingEnabled && version) {
      const initConfiguration: RumInitConfiguration = {
        applicationId: '74c15d9c-1280-45ab-af92-b011d9e94a1e',
        clientToken: 'pub9de9f41aa1baae5fcdebe8e657d17812',
        site: 'datadoghq.eu',
        version: version,
        service: 'web-react-protector-user-client',
        env: process.env.VITE_ENV,
        defaultPrivacyLevel: 'mask-user-input',
        silentMultipleInit: true,
        beforeSend: event => {
          if (event.type === 'resource' && !isTrustedDomain(event.resource.url)) {
            return false;
          }

          return true;
        },
        ...traceConfig
      };
      datadogRum.init(initConfiguration);
    }
  }, [tracingEnabled, traceConfig, version]);
};
