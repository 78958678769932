import { LoadingOutlined } from '@ant-design/icons';
import { Button, DatePicker, Drawer, Popconfirm, Radio, Spin, TreeSelect } from 'antd';
import { COLOR_OPTIONS } from 'config/style';
import STTypo from 'core/shared/typo';
import { TypeOptions } from 'core/shared/typo/typo.models.ts';
import type React from 'react';
import type { ReactNode } from 'react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import SvgAlertGlyph from 'st-protector-icons/Glyph/AlertGlyph.tsx';
import STTextArea from '../../../../../components/textArea/textArea.component.tsx';
import { RequestReport } from '../../../../../entities/reports/report.actions.ts';
import type { RequestReportBody } from '../../../../../entities/reports/report.models.ts';
import type { AppState } from '../../../../../redux/app-state.ts';
import { getPropertyEntitiesInEntities, getSelectedPropertyId } from '../../../../core.selectors.ts';
import { Entitlements } from '../../../../shared/enums/entitlements.enum.ts';
import { generateUUID, getCurrentLanguage, validatePlansAndEntitlements } from '../../../../utils/functions.ts';
import useSegmentTracking from '../../../../utils/segment/useSegmentTracking.ts';
import { ExplicativeText } from '../report/drawers/explicative-text.component.tsx';
import HeaderTitle from '../report/drawers/header-title.component.tsx';
import '../report/report.styles.less';
import { ReportsTypes } from '../report/reports-types.enum.ts';
import {
  SeasonReportStyledCalendar,
  SeasonReportStyledComments,
  SeasonReportStyledContainer,
  SeasonReportStyledSelectAll,
  SeasonReportStyledSelectAllButton,
  SeasonReportStyledSelectAllCounter,
  SeasonReportStyledSelector
} from './season-report.styles.ts';
import { SeasonReportOptions } from './useSeasonReport.models.ts';
import {
  filterTreeNode,
  MAX_SELECTED_ORGANIZATIONS,
  useListPropertiesByOrgs,
  useSeasonDate,
  useSetSelectedProperties
} from './useSeasonReport.ts';

interface SeasonReportInterface {
  reportType: ReportsTypes;
  callbackDrawer: () => void;
  icon?: ReactNode;
  visibleDrawer: boolean;
}

const { RangePicker } = DatePicker;

export const SeasonReport: React.FC<SeasonReportInterface> = ({ reportType, callbackDrawer, icon, visibleDrawer }) => {
  const [t] = useTranslation();
  const dispatch = useDispatch();
  const segmentTracking = useSegmentTracking();
  const selectedPropertyId = useSelector(getSelectedPropertyId);

  const licensingStatus = useSelector((state: AppState) => state.entities.company.licensingStatus);

  const SeasonReportWorkspaceEntitlement = validatePlansAndEntitlements(licensingStatus, null, [Entitlements.SEASON_REPORT_WORKSPACE]);

  const { selectedProperty, onChange, selectedPropertiesByOrg, handleSelectAllOrganizations } = useSetSelectedProperties();

  const [comment, setComment] = useState('');
  const [seasonReportOption, setSeasonReportOption] = useState<SeasonReportOptions>(
    SeasonReportWorkspaceEntitlement ? SeasonReportOptions.BY_ORG : SeasonReportOptions.BY_PROPERTY
  );

  const { orgTree, mutate: getPropertiesInfo } = useListPropertiesByOrgs(SeasonReportWorkspaceEntitlement);
  const orgTreeIds = orgTree.map(org => org.value as string);
  const { timeRangeFrom, timeRangeTo, disabledDate, handleTimeRange } = useSeasonDate();

  const properties = useSelector(getPropertyEntitiesInEntities);

  const generateReport = () => {
    const id = generateUUID();
    const groupId = generateUUID();
    const propertyId = selectedPropertyId ?? Object.keys(properties)[0];

    const reportPayload: RequestReportBody = {
      type: ReportsTypes.SEASON_REPORT,
      id: id,
      group_id: groupId,
      property_id: propertyId,
      data_request: {
        comments: comment,
        language: getCurrentLanguage(),
        start_date: timeRangeFrom.format('YYYY-MM-DD'),
        end_date: timeRangeTo?.format('YYYY-MM-DD'),
        season_report_option: seasonReportOption
      }
    };

    dispatch(
      RequestReport({
        ...reportPayload,
        data_request: {
          ...reportPayload?.data_request,
          selected_properties_by_org: selectedPropertiesByOrg
        }
      })
    );

    const rangeOfDays = !timeRangeTo ? 0 : timeRangeTo.diff(timeRangeFrom, 'days') + 1;
    const organizationsList = Object.keys(selectedPropertiesByOrg);
    const propertiesList = Object.values(selectedPropertiesByOrg).flat();

    segmentTracking.track('Reports - Season report - Click on Generate Report', {
      ...reportPayload,
      rangeOfDays,
      organizationsList,
      propertiesList,
      numberOfProperties: propertiesList?.length,
      numberOfOrganizations: organizationsList?.length
    });

    callbackDrawer();
  };

  const onLoadData = async (treeNode: { props: { value: string } }): void => {
    const orgId = treeNode.props.value;
    await new Promise<void>((resolve, reject) => {
      getPropertiesInfo(orgId, {
        onSuccess: () => resolve(),
        onError: () => reject(new Error('Something went wrong'))
      });
    });
  };

  const isSubmitDisabled = !selectedProperty.length || !timeRangeFrom || !timeRangeTo;

  return (
    <Drawer
      className='st-report-drawer'
      placement='right'
      visible={visibleDrawer}
      width={400}
      title={<HeaderTitle icon={icon} type={reportType} />}
      onClose={callbackDrawer}
      maskClosable>
      <div className='st-report-drawer__content' data-testid='season-report-drawer'>
        <ExplicativeText type={reportType} />

        <div className='st-report-drawer__form' data-testid='season-report-form'>
          {!orgTree?.length && <Spin indicator={<LoadingOutlined style={{ fontSize: 16 }} spin />} />}
          <SeasonReportStyledSelectAll>
            <STTypo type={TypeOptions.P1} color={COLOR_OPTIONS.NEUTRAL_60}>
              {SeasonReportWorkspaceEntitlement ? t('modals.season_report.select_orgs') : t('modals.season_report.select_properties')}
            </STTypo>
            <SeasonReportStyledSelectAllButton onClick={() => handleSelectAllOrganizations(orgTreeIds)}>
              <STTypo type={TypeOptions.P1} color={COLOR_OPTIONS.GREEN_40}>
                {t('modals.season_report.select_all_organizations')}
              </STTypo>
            </SeasonReportStyledSelectAllButton>
          </SeasonReportStyledSelectAll>
          <TreeSelect
            showSearch
            style={{ width: '100%' }}
            dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
            allowClear
            suffixIcon
            treeCheckable
            multiple
            loadData={onLoadData}
            onChange={onChange}
            value={selectedProperty}
            treeData={orgTree}
            maxTagCount={2}
            filterTreeNode={filterTreeNode}
          />
          <SeasonReportStyledSelectAllCounter>
            <STTypo type={TypeOptions.P1} color={COLOR_OPTIONS.NEUTRAL_60}>
              ({`${Object.keys(selectedPropertiesByOrg).length} / ${MAX_SELECTED_ORGANIZATIONS}`})
            </STTypo>
          </SeasonReportStyledSelectAllCounter>
        </div>
        <SeasonReportStyledContainer>
          <STTypo type={TypeOptions.P1} color={COLOR_OPTIONS.NEUTRAL_60}>
            {t('modals.season_report.options')}
          </STTypo>
          <Radio.Group onChange={event => setSeasonReportOption(event.target.value as SeasonReportOptions)} value={seasonReportOption}>
            <SeasonReportStyledSelector>
              <Radio data-testid={SeasonReportOptions.BY_ORG} value={SeasonReportOptions.BY_ORG}>
                <STTypo type={TypeOptions.P1} color={COLOR_OPTIONS.NEUTRAL_100}>
                  {t('modals.season_report.options_by_org')}
                </STTypo>
              </Radio>
              <Radio data-testid={SeasonReportOptions.BY_PROPERTY} value={SeasonReportOptions.BY_PROPERTY}>
                <STTypo type={TypeOptions.P1} color={COLOR_OPTIONS.NEUTRAL_100}>
                  {t('modals.season_report.options_by_property')}
                </STTypo>
              </Radio>
            </SeasonReportStyledSelector>
          </Radio.Group>
          <STTypo type={TypeOptions.P1} color={COLOR_OPTIONS.NEUTRAL_60}>
            {t('modals.timeline_xray_report.fields.time_range')}
          </STTypo>
          <SeasonReportStyledCalendar>
            <RangePicker
              value={[timeRangeFrom, timeRangeTo]}
              style={{ width: '432px' }}
              format='L'
              size='large'
              separator='—'
              onChange={handleTimeRange}
              allowClear={false}
              disabledDate={disabledDate}
            />
          </SeasonReportStyledCalendar>
          <STTypo type={TypeOptions.P2} color={COLOR_OPTIONS.NEUTRAL_50} data-testid='season-report-time-range-warning'>
            {t('modals.season_report.time_range_warning')}
          </STTypo>
          <SeasonReportStyledComments>
            <STTypo type={TypeOptions.P1} color={COLOR_OPTIONS.NEUTRAL_60}>
              {t('modals.season_report.comments')}
            </STTypo>
            <STTextArea
              setValue={setComment}
              value={comment}
              testID='season-report-drawer-textarea'
              rows={4}
              maxLength={4400}
              alignCounter='right'
            />
          </SeasonReportStyledComments>
        </SeasonReportStyledContainer>
        <div className='st-report-drawer__actions'>
          <Popconfirm
            placement='bottomRight'
            icon={
              <div className='st-report-drawer__confirm-icon-container'>
                <SvgAlertGlyph fill='#F74141' />
              </div>
            }
            overlayClassName='st-report-drawer__confirm'
            title={
              <div className='st-report-drawer__confirm-delete'>
                <STTypo type={TypeOptions.P1}>{`${t('report.back')}`}</STTypo>
              </div>
            }
            onConfirm={callbackDrawer}
            okText={t('general.complete')}
            okButtonProps={{
              style: {
                backgroundColor: '#F74141',
                border: 'none',
                borderRadius: '4px',
                paddingTop: '2px'
              }
            }}
            cancelButtonProps={{
              style: {
                backgroundColor: '#F2F4F6',
                border: 'none',
                borderRadius: '4px',
                paddingTop: '2px',
                color: COLOR_OPTIONS.PRIMARY
              }
            }}
            cancelText={t('general.back')}>
            <Button
              type='ghost'
              size='default'
              className='st-report-drawer_actions-cancel'
              data-testid='report-detailed-consolidated-cancel'>
              {t('general.cancel')}
            </Button>
          </Popconfirm>
          <Button
            type='primary'
            size='default'
            className='st-report-drawer_actions-submit'
            onClick={generateReport}
            disabled={isSubmitDisabled}
            data-testid='report-detailed-consolidated-submit'>
            {t('report.action')}
          </Button>
        </div>
      </div>
    </Drawer>
  );
};
