import type { UseQueryResult } from '@tanstack/react-query';
import type { JobStatusEnum } from 'syngenta-digital-react-cropwise-nemadigital';
import type { RequestCreateJobAois } from 'syngenta-digital-react-cropwise-nemadigital/dist/module/nema-config/types/nema-config.types';

export interface NemaDigitalJob {
  job_id: number;
  contract_id: string;
  created_at: string;
  crop_cycle_end: string;
  crop_cycle_start: string;
  job_params: RequestCreateJobAois[];
  parent_job_id?: string;
  property_id: string;
  status: JobStatusEnum;
  updated_at: string;
  workspace_id: string;
  season_start_date?: string;
  season_end_date?: string;
  pre_season_execution: SeasonExecution | null;
  post_season_execution: SeasonExecution | null;
}

interface SeasonExecution {
  created_at: string;
  ended_at: string | null;
  errors: string | null;
  execution_id: number;
  job_id: number;
  processed_records: number | null;
  report_type: string;
  started_at: string | null;
  started_records: number | null;
  status: JobStatusEnum;
  total_records: number | null;
}

export interface NemadigitalCropCycleInfo {
  crop_identified: string;
  start_date: string;
  end_date: string;
  confidence: number;
  source: string;
}

export interface FieldCropInfo {
  field_id: string;
  crop_cycles_identified: Record<string, NemadigitalCropCycleInfo[]>;
}

export interface FieldCropInfoQuery {
  fieldId?: string;
  query: UseQueryResult<FieldCropInfo>;
}

export interface NemaDigitalJobSuccess {
  job_id: string;
}

export interface AvailableFields {
  declared_area: number;
  event_date: string;
  id: string;
  name: string;
  parent_region_id: string;
  property_id: string;
}

export interface AreaSimpleNema {
  id: string;
  name: string;
  size: number;
}

export enum NemadigitalQueryType {
  GET_PROPERTY_NEMADIGITAL_JOBS = 'GET_PROPERTY_NEMADIGITAL_JOBS',
  GET_NEMADIGITAL_FIELD_CROP_INFO = 'GET_NEMADIGITAL_FIELD_CROP_INFO',
  GET_NEMADIGITAL_JOB_INFO = 'GET_NEMADIGITAL_JOB_INFO',
  GET_NEMADIGITAL_JOBS_BY_PROPERTY_ID = 'GET_NEMADIGITAL_JOBS_BY_PROPERTY_ID',
  GET_NEMADIGITAL_AVAILABLE_FIELDS = 'GET_NEMADIGITAL_AVAILABLE_FIELDS',
  CREATE_NEMADIGITAL_JOB = 'CREATE_NEMADIGITAL_JOB'
}

export enum NemadigitalDefaultThreshold {
  MEDIUM_DAMAGE_TRESHOLD = '6',
  HIGH_DAMAGE_TRESHOLD = '12',

  MEDIUM_QUALITY_TRESHOLD = '60',
  HIGH_QUALITY_TRESHOLD = '80'
}
