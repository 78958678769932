import { initialPage } from 'core/utils/basic.models';
import type { EntityAdapter } from 'redux-ngrx-entity';
import { createEntityAdapter } from 'redux-ngrx-entity';
import { createSelector } from 'reselect';
import type { AppState } from '../../redux/app-state';
import { CompanyActionsTypes } from './company.actions';
import type { Company, CompanyPageResponse, CompanyState, LicensingStatusResponse } from './company.models';

function selectCompanyUuid(company: Company): string {
  return company.id;
}

function sortByName(a: Company, b: Company): number {
  return a.name.localeCompare(b.name);
}

const entity: EntityAdapter<Company> = createEntityAdapter<Company>({
  selectId: selectCompanyUuid,
  sortComparer: sortByName
});

export const initialState = entity.getInitialState<CompanyState>({
  ids: [],
  entities: {},
  page: initialPage,
  isLoading: false,
  isLoaded: false,
  error: null,
  licensingStatus: null,
  isLoadingLicensingStatus: true,
  currentWorkspaceId: null,
  isLoadingCurrentWorkspaceId: false
});

interface IMutableContent {
  mutableContent: Company[];
}

interface IActionCompany {
  type: string;
  payload: string | CompanyPageResponse | LicensingStatusResponse | IMutableContent;
}

export default (state = initialState, action: IActionCompany) => {
  switch (action.type) {
    case CompanyActionsTypes.LOAD_COMPANIES:
      return {
        ...state,
        isLoading: true,
        isLoaded: false
      };
    case CompanyActionsTypes.LOAD_COMPANIES_SUCCESS:
      return entity.addAll((action.payload as IMutableContent).mutableContent, {
        ...state,
        page: {
          ...(action.payload as CompanyPageResponse)
        },
        isLoading: false,
        isLoaded: true,
        error: null
      });
    case CompanyActionsTypes.LOAD_COMPANIES_FAILURE:
      return {
        ...state,
        isLoading: false,
        isLoaded: false,
        error: action.payload
      };

    case CompanyActionsTypes.LOAD_CURRENT_WORKSPACE_ID:
      return {
        ...state,
        isLoadingCurrentWorkspaceId: true
      };

    case CompanyActionsTypes.LOAD_CURRENT_WORKSPACE_ID_SUCCESS:
      return {
        ...state,
        isLoadingCurrentWorkspaceId: false,
        currentWorkspaceId: action.payload as string
      };

    case CompanyActionsTypes.LOAD_CURRENT_WORKSPACE_ID_FAILURE:
      return {
        ...state,
        isLoadingCurrentWorkspaceId: false,
        error: action.payload as string
      };

    case CompanyActionsTypes.LOAD_LICENSING_STATUS:
    case CompanyActionsTypes.LOAD_LICENSING_STATUS_BY_WORKSPACE:
      return {
        ...state,
        isLoadingLicensingStatus: true
      };

    case CompanyActionsTypes.LOAD_LICENSING_STATUS_SUCCESS:
      return {
        ...state,
        licensingStatus: action.payload as LicensingStatusResponse,
        isLoadingLicensingStatus: false,
        error: null
      };
    case CompanyActionsTypes.CLEAN_LICENSING_STATUS:
      return {
        ...state,
        licensingStatus: null,
        isLoadingLicensingStatus: false,
        error: null
      };
    case CompanyActionsTypes.LOAD_LICENSING_STATUS_FAILURE:
      return {
        ...state,
        isLoadingLicensingStatus: false
      };
    default:
      return state;
  }
};

const { selectIds, selectEntities, selectAll, selectTotal } = entity.getSelectors();
export const selectCompanyIds = selectIds;
export const selectCompanyEntities = selectEntities;
export const selectAllCompanies = selectAll;
export const selectCompanyTotal = selectTotal;

export const getSelectedCompany = createSelector(
  (state: AppState) => selectAllCompanies(state.entities.company),
  (state: AppState) => state.uiState.global.selectedCompany,
  (companies, selectedCompany) => companies.find(company => company.id === selectedCompany)
);

export const getAllCompanies = createSelector(
  (state: AppState) => selectAllCompanies(state.entities.company),
  companies => companies
);
