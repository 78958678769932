import { SetCurrentProperty } from 'core/core.actions';
import { getLicensingStatus, getSystemFlags, hasRTRPlanEntitlement, selectHasDiseaseRiskLandingPage } from 'core/core.selectors';
import { Entitlements } from 'core/shared/enums/entitlements.enum';
import { validatePlansAndEntitlements, verifyUserAccessInRegionalOverview } from 'core/utils/functions';
import { useCompanyMatch } from 'core/utils/hooks/usePathMatches';
import type { AnalyticsInfo } from 'core/utils/segment/user-events.types';
import useSegmentTracking from 'core/utils/segment/useSegmentTracking';
import { CleanLicensingStatus, LoadCurrentWorkspaceIdSuccess, LoadWorkspaceLicensingStatus } from 'entities/company/company.actions';
import { getCurrentWorkspaceId } from 'entities/company/company.selector';
import { ClearReports } from 'entities/reports/report.actions';
import { getUserData } from 'entities/user/user.selectors';
import { getHomePage } from 'pages/dashboard/sidebar-property-card/dashboard-property-card.functions';
import { useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import type { SelectedEntitiesModel } from './hall-of-entities-mfe.models';

const useNewHallOfCompanies = () => {
  const systemFlags = useSelector(getSystemFlags);
  const user = useSelector(getUserData);
  const { user: userData } = user;

  const isUserInBlackList = systemFlags?.P40_45293_hall_of_companies_mfe_black_list_by_userid as unknown as string[];

  if (userData?.id && isUserInBlackList?.includes(userData?.id)) {
    return false;
  }

  const isValidByBooleanFlag = systemFlags?.P40_45293_use_new_hall_of_companies_mfe;
  if (isValidByBooleanFlag) {
    return true;
  }

  const isValidByCountryCode =
    !!userData?.country_code &&
    (systemFlags?.P40_45293_hall_of_companies_mfe_by_country as unknown as string[])?.includes(userData.country_code);
  if (isValidByCountryCode) {
    return true;
  }

  const isValidByJobTitle =
    !!userData?.job_title &&
    (systemFlags?.P40_45293_hall_of_companies_mfe_by_job_title as unknown as string[])?.includes(userData.job_title);
  if (isValidByJobTitle) {
    return true;
  }
  const isValidByWorkspaceId = userData?.licensing_accounts?.some(account =>
    (systemFlags?.P40_45293_hall_of_companies_mfe_by_workspace_id as unknown as string[])?.includes(account.workspace_id)
  );
  if (isValidByWorkspaceId) {
    return true;
  }

  const isValidByUserID =
    !!userData?.id && (systemFlags?.P40_45293_hall_of_companies_mfe_by_user as unknown as string[])?.includes(userData.id);
  if (isValidByUserID) {
    return true;
  }

  return false;
};

export const useNavigateTo = () => {
  const segmentTracking = useSegmentTracking();
  const navigate = useNavigate();

  const navigateTo = useCallback(
    (destiny: string, segmentEvent: string, from: string) => {
      const payload: AnalyticsInfo = {
        from
      };
      segmentTracking.track(segmentEvent, payload);
      navigate(destiny);
    },
    [navigate, segmentTracking]
  );

  return navigateTo;
};

const useRedirectToHallOfPropertiesMFE = (organizationId: string | undefined) => {
  const navigateTo = useNavigateTo();
  const flagNewHallOfCompanies = useNewHallOfCompanies();
  const workspaceId = useSelector(getCurrentWorkspaceId);
  const companyMatch = useCompanyMatch();

  useEffect(() => {
    if (workspaceId && flagNewHallOfCompanies && companyMatch) {
      navigateTo(
        `/hall-of-companies/workspace/${workspaceId}/organization/${organizationId}`,
        'Redirect to New Hall',
        'Old Hall of Properties'
      );
    }
  }, [flagNewHallOfCompanies, companyMatch, navigateTo, workspaceId, organizationId]);
};

const useDefineHomePageByEntitlement = () => {
  const hasDiseaseRiskLandingPage = useSelector(selectHasDiseaseRiskLandingPage);
  const navigateTo = useNavigateTo();
  const dispatch = useDispatch();
  const showOnlyNotesFeature = useSelector(hasRTRPlanEntitlement);
  const licensingStatus = useSelector(getLicensingStatus);

  const hasRegionalRisk = verifyUserAccessInRegionalOverview(licensingStatus);
  const hidePropertyHall = validatePlansAndEntitlements(licensingStatus, null, [Entitlements.HIDE_PROPERTY_HALL]);
  const hasRegionalRiskWithHideProperty = useMemo(() => hasRegionalRisk && hidePropertyHall, [hasRegionalRisk, hidePropertyHall]);

  const handleOpenHomePage = useCallback(
    ({ companyID, propertyID }: { companyID: string; propertyID?: string }) => {
      if (hasRegionalRiskWithHideProperty) {
        navigateTo(`/company/${companyID}/regional-overview`, 'Redirect to Regional Overview', 'Hall of Properties - Regional Overview');
        return;
      }

      if (!propertyID) return;
      const { eventName, page } = getHomePage({ hasDiseaseRiskLandingPage, showOnlyNotesFeature });
      dispatch(ClearReports());
      dispatch(SetCurrentProperty(propertyID));
      navigateTo(`/company/${companyID}/property/${propertyID}/${page}`, eventName, 'Hall of Properties - Property Card');
    },
    [hasRegionalRiskWithHideProperty, hasDiseaseRiskLandingPage, showOnlyNotesFeature, dispatch, navigateTo]
  );

  return handleOpenHomePage;
};

const useSetNewCurrentWorkSpace = () => {
  const dispatch = useDispatch();
  const currentWorkspaceId = useSelector(getCurrentWorkspaceId);

  const setNewCurrentWorkspaceId = useCallback(
    (entities: SelectedEntitiesModel) => {
      const workspace_id = entities.workspace?.id;
      if (workspace_id && workspace_id !== currentWorkspaceId) {
        dispatch(CleanLicensingStatus());
        dispatch(LoadWorkspaceLicensingStatus(workspace_id));
        dispatch(LoadCurrentWorkspaceIdSuccess(workspace_id));
      }
    },
    [currentWorkspaceId, dispatch]
  );

  return { setNewCurrentWorkspaceId };
};

export { useDefineHomePageByEntitlement, useNewHallOfCompanies, useRedirectToHallOfPropertiesMFE, useSetNewCurrentWorkSpace };
