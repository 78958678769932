import type { Dictionary } from 'config/types';
import type { Crop } from 'core/utils/basic.models';
import type { DiagnosticBreakdown, Property } from 'entities/property/property.models';
import type { Dispatch, SetStateAction } from 'react';

type OmittedProps = 'root_region_id' | 'last_visit' | 'total_area' | 'area_count';

export interface PropertyData extends Omit<Property, OmittedProps> {
  areaStatistics: {
    acceptanceAreas?: number;
    controlAreas?: number;
    damageAreas?: number;
  };
  diagnostic_breakdown?: DiagnosticBreakdown;
  totalAreas: number;
  totalAreaHa: number;
  lastVisit: number;
  name: string;
  crops: Crop[];
  mutableFavorite?: boolean;
}

export interface SeasonGroup {
  seasonActive: Dictionary<PropertyData>;
  seasonInactive: Dictionary<PropertyData>;
}

export interface PropertiesGroupBySeasonStatus {
  seasonActive: PropertyData[];
  seasonInactive: PropertyData[];
}

export interface CommonProps {
  setProperties: Dispatch<SetStateAction<PropertyData[]>>;
  seasons: string[] | number[];
  flag?: string | boolean;
  cropEnded?: boolean;
  companyId?: string;
  isSimple?: boolean;
}

enum HomePage {
  NOTES = 'notes',
  DISEASE_RISK = 'disease-risk',
  TIMELINE = 'timeline'
}

enum EventName {
  OPEN_NOTES = 'Open Notes',
  OPEN_DISEASE_RISK = 'Open Disease Risk',
  OPEN_TIMELINE = 'Open Timeline'
}

export const homePageMapping = {
  showOnlyNotesFeature: {
    eventName: EventName.OPEN_NOTES,
    page: HomePage.NOTES
  },
  hasDiseaseRiskLandingPage: {
    eventName: EventName.OPEN_DISEASE_RISK,
    page: HomePage.DISEASE_RISK
  },
  default: {
    eventName: EventName.OPEN_TIMELINE,
    page: HomePage.TIMELINE
  }
};

export interface GetHomePageProps {
  hasDiseaseRiskLandingPage: boolean;
  showOnlyNotesFeature: boolean;
}
