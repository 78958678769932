import { Modal } from 'antdV5';
import UnstyledButton from 'components/unstyled-button';
import { CROPWISE_URL } from 'config/constants';
import { FONT_FAMILY } from 'config/style';
import { SetCurrentProperty } from 'core/core.actions';
import { getSelectedPropertyId } from 'core/core.selectors';
import STTypo from 'core/shared/typo';
import { TypeOptions } from 'core/shared/typo/typo.models';
import { getCurrentWorkspaceId, getSelectedCompanyId } from 'entities/company/company.selector';
import { ClearReports } from 'entities/reports/report.actions';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import SvgEmptyStateSeasonGlyph from 'st-protector-icons/Glyph/EmptyStateSeasonGlyph';
import { usePropertyHasNoSeason } from '../modals.hook';
import './modal-property-no-season.styles.less';

const ModalPropertyNoSeason = () => {
  const [t] = useTranslation();
  const [visible, setVisible] = useState(false);
  const currentWorkspaceId = useSelector(getCurrentWorkspaceId);
  const companySelected = useSelector(getSelectedCompanyId);
  const propertySelected = useSelector(getSelectedPropertyId);
  const dispatch = useDispatch();

  const propertyHasNoSeason = usePropertyHasNoSeason();

  const navigate = useNavigate();

  const shouldShowModal = useMemo(() => propertyHasNoSeason, [propertyHasNoSeason]);

  useEffect(() => {
    setVisible(shouldShowModal);
  }, [shouldShowModal]);

  const cleanSomeStates = useCallback(() => {
    dispatch(ClearReports());
    dispatch(SetCurrentProperty(null));
    setVisible(false);
  }, [dispatch]);

  const handleClickOnListProperties = useCallback(() => {
    navigate(`/hall-of-companies/workspace/${currentWorkspaceId}/organization/${companySelected}`, {
      state: {
        stopRedirect: true
      }
    });
    cleanSomeStates();
  }, [cleanSomeStates, companySelected, currentWorkspaceId, navigate]);

  const handleClickOnCreateNewSeason = useCallback(() => {
    const url = `${CROPWISE_URL}/landing-organization/${companySelected}/property/${propertySelected}/table-view`;
    window.open(url, '_blank');
    navigate(`/hall-of-companies/workspace/${currentWorkspaceId}/organization/${companySelected}`, {
      state: {
        stopRedirect: true
      }
    });
    cleanSomeStates();
  }, [cleanSomeStates, companySelected, currentWorkspaceId, navigate, propertySelected]);

  if (!visible) {
    return null;
  }

  return (
    <Modal
      classNames={{ content: 'modal-property-no-season' }}
      data-testid='modal-property-no-season'
      open={visible}
      centered
      title={null}
      footer={null}
      closeIcon={null}
      height={450}
      destroyOnClose>
      <SvgEmptyStateSeasonGlyph data-testid='svg-empty-state-season' width={400} />

      <div className='modal-property-no-season__title'>
        <STTypo fontFamily={FONT_FAMILY} type={TypeOptions.H3} fontWeight={600} lineHeight='32px' color={'#14151C'}>
          {t('modals.property_no_season.no_season_created')}
        </STTypo>
      </div>

      <STTypo fontFamily={FONT_FAMILY} type={TypeOptions.H5} lineHeight='24px' color='#232630' align='center'>
        {t('modals.property_no_season.no_season_created_description')}
      </STTypo>

      <div className='modal-property-no-season__buttons-container'>
        <UnstyledButton data-testid='btn-go-to-farm-list' className='button button--secondary' onClick={handleClickOnListProperties}>
          <STTypo fontFamily={FONT_FAMILY} type={TypeOptions.P1}>
            {t('modals.property_no_season.go_back_farm_list')}
          </STTypo>
        </UnstyledButton>

        <UnstyledButton data-testid='btn-go-to-create-new-season' className='button button--primary' onClick={handleClickOnCreateNewSeason}>
          <STTypo fontFamily={FONT_FAMILY} type={TypeOptions.P1}>
            {t('modals.property_no_season.create_season')}
          </STTypo>
        </UnstyledButton>
      </div>
    </Modal>
  );
};

export default ModalPropertyNoSeason;
