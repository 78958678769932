import { CropIcon as CropIconCwElements } from '@cw-elements/crop-icons';
import { Tooltip } from 'antd';
import { getSystemFlags } from 'core/core.selectors.ts';
import type { Crop } from 'core/utils/basic.models';
import type React from 'react';
import { useSelector } from 'react-redux';
import CropIconUnit from './crop-icon-unit';
import { findCropLocalizedName, useGetCropInfo } from './crop-icon.queries.ts';
import CropIconContainer from './crop-icon.styles';

interface CropIconProps {
  crop?: Crop | Crop[];
  background?: boolean;
  cropEnded?: boolean;
  cropHarvested?: boolean;
  backgroundColor?: string;
  customIcon?: React.ReactElement;
  smallIcon?: boolean;
}

const CropIcon = ({ crop, background = true, cropEnded, cropHarvested, backgroundColor, customIcon, smallIcon }: CropIconProps) => {
  const systemFlags = useSelector(getSystemFlags);
  const cropInfo = Array.isArray(crop) ? crop[0] : crop;

  const cropList = useGetCropInfo();

  const localizedCrop = findCropLocalizedName(cropList, cropInfo);

  return (
    <Tooltip title={localizedCrop} data-testid='crop-icon-tooltip'>
      <CropIconContainer
        data-testid='crop-icon-container'
        cropEnded={cropEnded ?? cropHarvested}
        withBackground={background}
        backgroundColor={backgroundColor}
        smallIcon={smallIcon}>
        {systemFlags?.P40_46118_crop_icon ? (
          <CropIconCwElements iconName={cropInfo?.wk_slug} />
        ) : (
          <CropIconUnit cropInfo={cropInfo} customIcon={customIcon} background={background} />
        )}
      </CropIconContainer>
    </Tooltip>
  );
};

export default CropIcon;
