import type { ArgsProps } from 'antd/lib/notification';
import type { AxiosError } from 'axios';
import type { Action, DateISOString, Scouter, UUID } from 'core/utils/basic.models';
import type {
  ExternalIntegrationSource,
  SamplingBehaviour,
  StaticPointCreation,
  StaticPointEventType,
  StaticPointType
} from 'entities/static-points/static-points.models';
import type { Spray } from 'pages/timeline/timeline.models';
import type { EntityState } from 'redux-ngrx-entity';
import type { Nullable } from 'vitest';

export interface PayloadStationType {
  propertyId: UUID;
}

export interface LoadSeveritiesRequestType {
  startDate: Date;
  endDate: Date;
  templateId: UUID;
  ids: string[];
  allActions: boolean;
  clearCache?: boolean;
}

export interface LoadSeveritiesSuccessParamsType {
  severities: AnalyticResultsHistogramResponse[];
  allActions: boolean;
  allIds: string[];
  allMonitoring?: unknown[];
  clearCache?: boolean;
}

export interface UpsertStationSuccessCallbacks {
  clearDrawerAndSelectStation: (station?: Station) => void;
  notification: {
    success: ArgsProps;
  };
  tracking: {
    installedTrap: () => void;
    editedTrap: () => void;
  };
}

export interface StationPayloadType {
  stationType: StaticPointType;
  stationId: UUID;
  actionType: StationActionType;
  autoCreateTask?: boolean;
  callback: {
    notification: { error: ArgsProps; success: ArgsProps };
    tracking: {
      disabledTrap: () => void;
      deletedTrap: () => void;
    };
  };
  integrationSource: ExternalIntegrationSource;
}

export type DeleteStationType = (
  stationType: StaticPointType,
  stationId: UUID,
  integrationSource: ExternalIntegrationSource,
  callback: unknown
) => Action<StationPayloadType>;

export type EditStationType = (
  stationType: StaticPointType,
  station: StaticPointCreation,
  callback: unknown
) => Action<{
  stationType: StaticPointType;
  station: StaticPointCreation;
  callback: {
    notification: {
      success: ArgsProps;
      error: ArgsProps;
    };
    tracking: {
      editedTrap: () => void;
    };
    clearDrawerAndSelectStation: (station?: Station | undefined) => void;
  };
}>;

export interface Station {
  id: string;
  type: Nullable<StaticPointType> | null;
  template_id: string;
  name: string;
  property_id: string;
  areas_ids: UUID[];
  sampling_behaviour: SamplingBehaviour;
  lat: number;
  lng: number;
  description?: string | null;
  installation_date: DateISOString;
  uninstallation_date?: DateISOString | null;
  deleted_date?: DateISOString | null;
  last_event_type?: StaticPointEventType;
  last_event_date: DateISOString;
  last_renewal?: DateISOString | null;
  expiration_date: DateISOString;
  validity_in_days: number;
  view_id?: string | null;
  analytic_results_histogram?: AnalyticResultsHistogram;
  scouter?: Scouter;
  single_use?: boolean;
  integration_source?: ExternalIntegrationSource;
  partNo?: string;
  pestName?: string;
  installed_area?: UUID;
}

export interface AnalyticResultsHistogramResponse {
  trap_id: string;
  analytic_results_histogram: AnalyticResultsHistogram;
  scouter: Scouter;
}

interface AnalyticResultsHistogram {
  result_histogram: HistogramInstance[];
}

interface HistogramInstance {
  sprays: Spray[];
  indicators: HistogramIndicators[];
  phenological_stage: string;
  local_date: DateISOString;
  sampling_behaviour: SamplingBehaviour | null;
  reset: boolean;
}

export interface HistogramIndicators {
  id: UUID;
  liss_value: number;
  name: string;
  value: number;
}

export interface StationResponse {
  content: Station[];
  size: number;
  cursor: string;
  is_last: boolean;
}

export enum StationActionType {
  CREATE = 'CREATE',
  UNINSTALL = 'UNINSTALL',
  DELETE = 'DELETE',
  REINSTALL = 'REINSTALL',
  EDIT = 'EDIT'
}

export interface StationsState extends EntityState<Station> {
  isStationsLoading: boolean;
  isSeveritiesLoading: boolean;
  isSeveritiesWithAllActionsLoading: boolean;
  analyticResultsHistogramIds: string[];
  analyticResultsHistogramIdsWithAllActions: string[];
  error: AxiosError | null | string;
  isLoading: boolean;
  stationsLoading: Record<string, boolean> | null;
}
