import { Button, Checkbox, DatePicker, Drawer, Form, Icon, Popconfirm, Select, Spin, notification } from 'antd';
import type { CheckboxChangeEvent } from 'antd/lib/checkbox';
import type { RangePickerValue } from 'antd/lib/date-picker/interface';
import type { AxiosResponse } from 'axios';
import { COLOR_OPTIONS } from 'config/style';
import { selectSystemFlags } from 'core/core.reducer';
import { getPropertyEntitiesInEntities } from 'core/core.selectors';
import { selectCurrentUser } from 'core/services/auth/auth.reducer';
import STTypo from 'core/shared/typo';
import { TypeOptions } from 'core/shared/typo/typo.models';
import type { UUID } from 'core/utils/basic.models';
import { generateUUID, getCurrentLanguage, getNameByCurrentLanguage, textContains } from 'core/utils/functions';
import { useCompanyOrOrganizationMatch } from 'core/utils/hooks/usePathMatches';
import useSegmentTracking from 'core/utils/segment/useSegmentTracking';
import type { AnalyticsInfo } from 'core/utils/segment/user-events.types';
import { getSelectedCompany } from 'entities/company/company.reducer';
import type { PhenomenonSingle } from 'entities/methodology-deep/methodology-deep.models';
import type { MethodologiesForPropertyResponse } from 'entities/methodology/methodology.models';
import { getMethodologiesForProperty, getMethodologiesForPropertySeason } from 'entities/methodology/methodology.service';
import { getPhenomenaByMethodologyId } from 'entities/phenomenon/phenomenon.service';
import { getSelectedProperty } from 'entities/property/property.reducer';
import { selectsTheFirstPropertyIdOfTheEntities } from 'entities/property/property.selectors';
import { RequestReport } from 'entities/reports/report.actions';
import type { RequestReportBody } from 'entities/reports/report.models';
import type { Property } from 'entities/season/season-tree.models';
import _ from 'lodash';
import type { Moment } from 'moment';
import moment from 'moment';
import { normalizeString } from 'pages/timeline/table-mode/timeline-table.functions';
import type React from 'react';
import type { ReactNode } from 'react';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import type { AppState } from 'redux/app-state';
import sentryService from 'sentry/service';
import SvgAlertGlyph from 'st-protector-icons/Glyph/AlertGlyph';
import { useShowOnlyReportsForAllOrg } from '../report-drawer.component.hook';
import '../report.styles.less';
import type { ReportsTypes } from '../reports-types.enum';
import SelectSeason from './components/season-select.component';
import { ExplicativeText } from './explicative-text.component';
import HeaderTitle from './header-title.component';
import PhenomenaSingleSelect from './phenomena-single-select';

const { RangePicker } = DatePicker;

export interface ISTReportDetailedConsolidatedDrawer {
  reportType: ReportsTypes;
  callbackDrawer: () => void;
  icon?: ReactNode;
}

type AllMethodology = Omit<MethodologiesForPropertyResponse, 'seasonId'> & {
  seasonIds: MethodologiesForPropertyResponse['seasonId'][];
};

interface PhenomenonCategory {
  id: string;
  name: string;
}

const STReportDetailedConsolidatedDrawer = ({ reportType, callbackDrawer, icon }: ISTReportDetailedConsolidatedDrawer) => {
  const { propertyId } = useParams();
  const organizationMatch = useCompanyOrOrganizationMatch();
  const companyId = organizationMatch?.params?.companyId;

  const segmentTracking = useSegmentTracking();
  const [t] = useTranslation();
  const showOnlyReportsForAllOrg = useShowOnlyReportsForAllOrg();
  const initialTimeRangeTo = moment().set({ hour: 0, minute: 0, second: 0, millisecond: 0 });
  const initialTimeRangeFrom = moment().subtract(7, 'd').set({ hour: 0, minute: 0, second: 0, millisecond: 0 });

  const [timeRangeFrom, setTimeRangeFrom] = useState(initialTimeRangeFrom);
  const [selectedPhenomenonCategory, setSelectedPhenomenonCategory] = useState<UUID[] | undefined>();
  const [selectedPhenomenon, setSelectedPhenomenon] = useState<UUID[]>([]);
  const [timeRangeTo, setTimeRangeTo] = useState(initialTimeRangeTo);
  const [isLoading] = useState<boolean>(false);
  const [selectedSeason, setSelectedSeason] = useState<UUID>();
  const [finish, setFinish] = useState<boolean>(false);
  const [selectedProperty, setSelectedProperty] = useState<UUID[]>([]);
  const [isSelectedProperty, setIsSelectedProperty] = useState(false);
  const company = useSelector(getSelectedCompany);
  const propertyNew = useSelector(getSelectedProperty);
  const user = useSelector(selectCurrentUser);
  const isPropertiesLoaded = useSelector((state: AppState) => state.entities.property.isLoaded);
  const allProperties = useSelector(getPropertyEntitiesInEntities);
  const allSeason = useSelector((state: AppState) => state.entities.season.entities);
  const allSeasonIsLoaded = useSelector((state: AppState) => state.entities.season.isLoaded);
  const [allMethodology, setAllMethodology] = useState<AllMethodology[] | void>();

  const [allPhenomenon, setAllPhenomenon] = useState<PhenomenonSingle[]>([]);
  const [isLoadedAllMethodology, setIsLoadedAllMethodology] = useState(false);
  const [isLoadedPhenomenonCategory, setIsLoadedPhenomenonCategory] = useState<boolean>(true);
  const [phenomenonCategory, setPhenomenonCategory] = useState<PhenomenonCategory[]>([]);
  const [phenomenonCategoryFiltered, setPhenomenonCategoryFiltered] = useState<PhenomenonCategory[]>([]);
  const [propertiesFiltered, setPropertiesFiltered] = useState<Property[]>([]);
  const [propertiesBySeason, setPropertiesBySeason] = useState<Property[]>(Object.values(allProperties));
  const [phenomenon, setPhenomenon] = useState<PhenomenonSingle[]>([]);
  const [seasonLayout, setSeasonLayout] = useState(false);
  const [openProperty, setOpenProperty] = useState(false);
  const [openPhenomenonCategory, setOpenPhenomenonCategory] = useState(false);
  const systemFlags = useSelector((state: AppState) => selectSystemFlags(state));
  const firstPropertyId = useSelector(selectsTheFirstPropertyIdOfTheEntities);

  const isPhenomenonCategoryEnabled = useMemo(
    () => selectedSeason && selectedProperty.length && isLoadedAllMethodology,
    [selectedSeason, selectedProperty, isLoadedAllMethodology]
  );

  const isPhenomenonSelectEnabled = useMemo(
    () => selectedSeason && isLoadedAllMethodology && selectedPhenomenonCategory?.length,
    [selectedSeason, isLoadedAllMethodology, selectedPhenomenonCategory]
  );

  const handleExceptionWhenLoadingMethodologies = useCallback(
    (error: Error) => {
      notification.error({
        message: (
          <div>
            <STTypo>{t('report.error_message.load_methodologies_error')}.</STTypo>
          </div>
        )
      });
      sentryService.captureException(error, {
        fileName: 'report-detailed-consolidated.component',
        method: 'loadMethodologiesBySelectedPropertiesAndSeason',
        description: 'failed to load methodologies'
      });
    },
    [t]
  );

  const dispatch = useDispatch();

  const methodologyResponseSubscriber = useCallback(
    (respMethodology: MethodologiesForPropertyResponse[]) => {
      if (respMethodology) {
        const groupedMethodology = _.groupBy(respMethodology, 'methodologyId');
        const methodologies2 = _.map(groupedMethodology, group => {
          return {
            methodologyId: group[0].methodologyId,
            propertyId: group[0].propertyId,
            seasonIds: _.map(group, 'seasonId')
          };
        }).filter(methodology => selectedProperty.includes(methodology.propertyId));
        setAllMethodology(methodologies2);
      }
      setIsLoadedAllMethodology(true);
    },
    [selectedProperty]
  );

  const loadMethodologiesBySelectedPropertiesAndSeason = useCallback(() => {
    if (selectedSeason) {
      const promises = selectedProperty.map(selectedPropertyId => {
        return getMethodologiesForPropertySeason(selectedPropertyId, selectedSeason).toPromise();
      });
      Promise.all(promises)
        .then(methodologyResponse => {
          const methodologies = methodologyResponse.flatMap(methodology => methodology);
          methodologyResponseSubscriber(methodologies);
        })
        .catch((err: Error) => {
          handleExceptionWhenLoadingMethodologies(err);
          methodologyResponseSubscriber([]);
        });
    }
  }, [selectedSeason, selectedProperty, methodologyResponseSubscriber, handleExceptionWhenLoadingMethodologies]);

  const loadPropertyMethodologies = useCallback(() => {
    if (selectedSeason && selectedProperty.length) {
      setIsLoadedPhenomenonCategory(false);
      setIsLoadedAllMethodology(false);
      if (systemFlags?.P40_324292_new_property_methodology_endpoint) {
        loadMethodologiesBySelectedPropertiesAndSeason();
      } else {
        getMethodologiesForProperty(propertyId).subscribe(methodologyResponseSubscriber);
      }
    }
  }, [
    selectedSeason,
    selectedProperty.length,
    systemFlags?.P40_324292_new_property_methodology_endpoint,
    loadMethodologiesBySelectedPropertiesAndSeason,
    propertyId,
    methodologyResponseSubscriber
  ]);

  useEffect(() => {
    loadPropertyMethodologies();
  }, [propertyId, companyId, loadPropertyMethodologies]);

  useEffect(() => {
    if (allProperties && propertyId && !showOnlyReportsForAllOrg) {
      if (!isSelectedProperty) {
        setSelectedProperty([propertyId]);
      }
      setIsSelectedProperty(true);
    } else if (!showOnlyReportsForAllOrg) {
      setSelectedProperty([]);
    }
  }, [allProperties, propertyId, icon, showOnlyReportsForAllOrg, isSelectedProperty]);

  /**
   * @deprecated disabled because the feature flag
   */
  useEffect(() => {
    if (!systemFlags?.P40_324292_new_property_methodology_endpoint) {
      if (selectedSeason && isLoadedAllMethodology && allMethodology?.length) {
        setIsLoadedPhenomenonCategory(false);
        const allMethodologyFilter = allMethodology.flat().filter(x => x.seasonIds.includes(selectedSeason));
        setAllPhenomenon([]);

        if (!allMethodologyFilter.length) {
          setIsLoadedPhenomenonCategory(true);
        }

        allMethodologyFilter.forEach(methFilter => {
          getPhenomenaByMethodologyId(methFilter.methodologyId).subscribe(response => {
            if (response.data) {
              setAllPhenomenon(response.data);
            }
            setIsLoadedPhenomenonCategory(true);
          });
          return null;
        });
      }
    }
  }, [selectedSeason, isLoadedAllMethodology, allMethodology, systemFlags?.P40_324292_new_property_methodology_endpoint]);

  useEffect(() => {
    if (systemFlags?.P40_324292_new_property_methodology_endpoint) {
      if (isLoadedAllMethodology) {
        setIsLoadedPhenomenonCategory(false);
        setAllPhenomenon([]);
        if (allMethodology?.length) {
          const phenomenaPromises: Promise<AxiosResponse<PhenomenonSingle[]>>[] = allMethodology.map(methodology => {
            return getPhenomenaByMethodologyId(methodology.methodologyId).toPromise();
          });
          void Promise.all(phenomenaPromises).then((phenomenaResponse: AxiosResponse<PhenomenonSingle[]>[]) => {
            setAllPhenomenon(phenomenaResponse.flatMap(response => response.data));
          });
        }
        setIsLoadedPhenomenonCategory(true);
      }
    }
  }, [isLoadedAllMethodology, allMethodology, systemFlags?.P40_324292_new_property_methodology_endpoint]);

  const filterPhenomenonCategory = useMemo(() => {
    const uniqueIds: string[] = [];
    return allPhenomenon
      .map(x => x.category)
      .filter(element => {
        if (element.id) {
          const isDuplicate = uniqueIds.includes(element.id);

          if (!isDuplicate) {
            uniqueIds.push(element.id);

            return true;
          }
        }
        return false;
      });
  }, [allPhenomenon]);

  const filterPhenomenon = useMemo(() => {
    if (selectedPhenomenonCategory) {
      return allPhenomenon
        .map(x => x)
        .filter(element => {
          if (element.category.id) {
            if (selectedPhenomenonCategory.indexOf(element.category.id) !== -1) {
              return true;
            }
            return false;
          }
          return false;
        });
    }
    return [];
  }, [allPhenomenon, selectedPhenomenonCategory]);

  useEffect(() => {
    if (allPhenomenon && selectedSeason) {
      const translatePhenomenonCategory = filterPhenomenonCategory.map(item => {
        return { id: item.id, name: getNameByCurrentLanguage(item.name ? item.name : undefined) };
      });
      setPhenomenonCategory(
        translatePhenomenonCategory.sort((a, b) => {
          if (normalizeString(a.name.toLowerCase()) > normalizeString(b.name.toLowerCase())) return 1;
          if (normalizeString(b.name.toLowerCase()) > normalizeString(a.name.toLowerCase())) return -1;

          return 0;
        })
      );
    }
  }, [allPhenomenon, filterPhenomenonCategory, selectedSeason]);

  useEffect(() => {
    setPhenomenonCategoryFiltered(phenomenonCategory);
  }, [phenomenonCategory]);

  useEffect(() => {
    setPropertiesFiltered(propertiesBySeason);
  }, [propertiesBySeason]);

  useEffect(() => {
    if (allPhenomenon) {
      setPhenomenon(
        filterPhenomenon
          .sort((a, b) => {
            if (
              normalizeString(getNameByCurrentLanguage(a.name).toLowerCase()) >
              normalizeString(getNameByCurrentLanguage(b.name).toLowerCase())
            )
              return 1;
            if (
              normalizeString(getNameByCurrentLanguage(b.name).toLowerCase()) >
              normalizeString(getNameByCurrentLanguage(a.name).toLowerCase())
            )
              return -1;

            return 0;
          })
          .sort((a, b) => {
            if (
              normalizeString(getNameByCurrentLanguage(a.category.name).toLowerCase()) >
              normalizeString(getNameByCurrentLanguage(b.category.name).toLowerCase())
            )
              return 1;
            if (
              normalizeString(getNameByCurrentLanguage(b.category.name).toLowerCase()) >
              normalizeString(getNameByCurrentLanguage(a.category.name).toLowerCase())
            )
              return -1;

            return 0;
          })
      );
    }
  }, [allPhenomenon, filterPhenomenon, selectedPhenomenonCategory]);

  const handleTimeRange = (value: RangePickerValue) => {
    if (value?.[0] && value?.[1]) {
      setTimeRangeFrom(value[0].set({ hour: 0, minute: 0, second: 0, millisecond: 0 }));
      setTimeRangeTo(value[1].set({ hour: 0, minute: 0, second: 0, millisecond: 0 }));
    }
  };

  const getPropertiesBySelectedSeason = useCallback(
    (selectedSeasonId: React.SetStateAction<string | undefined>) => {
      return Object.values(allProperties).filter(property => property.seasons.find(season => season.id === selectedSeasonId));
    },
    [allProperties]
  );

  const handleSelectedSeason = useCallback(
    (value: React.SetStateAction<string | undefined>) => {
      const allPropertiesBySeason = getPropertiesBySelectedSeason(value);
      setPropertiesBySeason(allPropertiesBySeason);
      setSelectedSeason(value);
      setSelectedPhenomenonCategory(undefined);
      setSelectedPhenomenon([]);
      if (propertyId) {
        setSelectedProperty([propertyId]);
      } else {
        setSelectedProperty([]);
      }
    },
    [getPropertiesBySelectedSeason, propertyId]
  );

  const handleSeasonLayout = (event: CheckboxChangeEvent) => {
    setSeasonLayout(event.target.checked);
  };

  const closeDrawer = useCallback(() => {
    callbackDrawer();
    setSelectedSeason(undefined);
    setTimeRangeFrom(initialTimeRangeFrom);
    setTimeRangeTo(initialTimeRangeTo);
    setSelectedProperty([]);
    setSelectedPhenomenonCategory(undefined);
    setSelectedPhenomenon([]);
    setSeasonLayout(false);
  }, [callbackDrawer, initialTimeRangeTo, initialTimeRangeFrom]);

  const disabledDate = (current: Moment | null) => {
    return current ? current > moment().endOf('day').add(0, 'day') : false;
  };

  const generateReport = () => {
    if (selectedSeason && selectedProperty && selectedPhenomenon && user && companyId) {
      const requestBody: RequestReportBody = {
        id: generateUUID(),
        property_id: propertyId ?? firstPropertyId,
        type: reportType,
        data_request: {
          season_ids: [selectedSeason],
          property_ids: Object.values(selectedProperty).map(x => x),
          start_date: timeRangeFrom.format('YYYY-MM-DD'),
          end_date: timeRangeTo.format('YYYY-MM-DD'),
          category_ids: selectedPhenomenonCategory ? Object.values(selectedPhenomenonCategory).map(x => x) : [],
          phenomenon_ids: selectedPhenomenon,
          language: getCurrentLanguage(),
          user_id: user.id,
          company_id: companyId,
          season_layout: seasonLayout
        }
      };

      const payload: AnalyticsInfo = {
        companyId: company?.id ?? '',
        companyName: company?.name ?? '',
        propertyId,
        propertyName: propertyNew
          ? propertyNew.name
          : Object.values(propertiesFiltered)
              .filter(x => x.id === selectedProperty[0])
              .map(x => x.name)
              .toString(),
        selectedSeasonId: selectedSeason,
        selectedPropertyId: selectedProperty ? Object.values(selectedProperty).map(x => x) : [],
        reportType: requestBody.type,
        seasonLayout,
        from: 'Export Report'
      };
      segmentTracking.track('Export Report - Generated Monitoring Detailed ', payload);
      dispatch(RequestReport(requestBody));
    }
    closeDrawer();
  };

  const handleSelectedProperty = (values: string[]) => {
    const actualPropertyArray = propertyId ? Object.values([propertyId]) : [];
    const actualPropertyArrayWithValuesDistinctArray = _.uniqBy(actualPropertyArray.concat(values), e => {
      return e;
    });

    setPropertiesFiltered(propertiesBySeason);

    if (values.includes('all')) {
      if (values.length === propertiesBySeason.length + 1) {
        setSelectedProperty(actualPropertyArray);
      } else {
        setSelectedProperty(propertiesBySeason.map(el => el.id));
      }
      setOpenProperty(!openProperty);
    } else {
      setSelectedProperty(actualPropertyArrayWithValuesDistinctArray);
    }
  };

  const handlePropertySearch = useCallback(
    (value: string) => {
      const filteredProperty = propertiesBySeason.filter(p => textContains(p.name, value));
      setPropertiesFiltered(filteredProperty);
    },
    [propertiesBySeason]
  );

  const handleOpenProperty = () => {
    setPropertiesFiltered(propertiesBySeason);
    setOpenProperty(!openProperty);
  };

  const handleSelectedPhenomenonCategory = useCallback(
    (values: string[]) => {
      setPhenomenonCategoryFiltered(phenomenonCategory);

      if (values.includes('all')) {
        if (values.length === phenomenonCategory.length + 1) {
          setSelectedPhenomenonCategory(undefined);
          setSelectedPhenomenon([]);
        } else {
          setSelectedPhenomenonCategory(phenomenonCategory.map(el => el.id));
          setSelectedPhenomenon([]);
        }
        setOpenPhenomenonCategory(!openPhenomenonCategory);
      } else {
        setSelectedPhenomenonCategory(values);
        setSelectedPhenomenon([]);
      }
    },
    [openPhenomenonCategory, phenomenonCategory]
  );

  const handlePhenomenaCategorySearch = useCallback(
    (value: string) => {
      const filteredPhenomena = phenomenonCategory.filter(p => textContains(p.name, value));

      setPhenomenonCategoryFiltered(filteredPhenomena);
    },
    [phenomenonCategory]
  );

  const handleOpenPhenomenonCategory = useCallback(() => {
    setPhenomenonCategoryFiltered(phenomenonCategory);
    setOpenPhenomenonCategory(!openPhenomenonCategory);
  }, [openPhenomenonCategory, phenomenonCategory]);

  const displaySeason = useMemo(() => {
    if (!allSeasonIsLoaded) {
      return (
        <div className='st-report-drawer__loading_detailed-consolidated'>
          <Spin indicator={<Icon type='loading' style={{ fontSize: 16 }} spin />} />
        </div>
      );
    }

    if (allSeason) {
      return (
        <SelectSeason
          placeholder={t('modals.timeline_xray_report.placeholder.crop_cycle')}
          selectedSeason={selectedSeason}
          setSelectedSeason={handleSelectedSeason}
          showOnlySeasonsSelected
        />
      );
    }
  }, [allSeasonIsLoaded, allSeason, selectedSeason, handleSelectedSeason, t]);

  const displayPhenomenonCategory = useMemo(() => {
    if (!isLoadedPhenomenonCategory) {
      return (
        <div className='st-report-drawer__loading_detailed-consolidated'>
          <Spin indicator={<Icon type='loading' style={{ fontSize: 16 }} spin />} />
        </div>
      );
    }

    if (phenomenonCategoryFiltered && isLoadedPhenomenonCategory) {
      return (
        <Select
          size='large'
          value={selectedPhenomenonCategory}
          onChange={handleSelectedPhenomenonCategory}
          onSearch={handlePhenomenaCategorySearch}
          showSearch
          disabled={!isPhenomenonCategoryEnabled}
          filterOption={false}
          open={openPhenomenonCategory}
          onDropdownVisibleChange={handleOpenPhenomenonCategory}
          mode='multiple'
          data-testid='report-detailed-consolidated-phenomenons-category'>
          {!!phenomenonCategoryFiltered.length && (
            <Select.Option data-testid='report-detailed-consolidated-phenomenons-category-all' value='all'>
              {t('modals.timeline_xray_report.fields.phenomenon_category_all')}
            </Select.Option>
          )}
          {Object.values(phenomenonCategoryFiltered).map(phenomenonCategoryObj => (
            <Select.Option
              key={phenomenonCategoryObj.id}
              value={phenomenonCategoryObj.id}
              data-testid={`report-detailed-consolidated-phenomenons-category-${phenomenonCategoryObj.id}`}>
              {phenomenonCategoryObj.name}
            </Select.Option>
          ))}
        </Select>
      );
    }

    return null;
  }, [
    isLoadedPhenomenonCategory,
    phenomenonCategoryFiltered,
    selectedPhenomenonCategory,
    handleSelectedPhenomenonCategory,
    handlePhenomenaCategorySearch,
    isPhenomenonCategoryEnabled,
    openPhenomenonCategory,
    handleOpenPhenomenonCategory,
    t
  ]);

  useEffect(() => {
    if (finish) {
      setFinish(false);
      closeDrawer();
    }
  }, [finish, closeDrawer]);

  const isConfirmButtonDisabled = useCallback(() => {
    return !selectedSeason?.length || !selectedProperty.length || !selectedPhenomenon.length;
  }, [selectedSeason, selectedProperty, selectedPhenomenon]);

  return (
    <Drawer
      className='st-report-drawer'
      placement='right'
      visible
      width={400}
      title={<HeaderTitle icon={icon} type={reportType} />}
      onClose={closeDrawer}
      maskClosable>
      <div className='st-report-drawer__content' data-testid='report-detailed-consolidated-drawer'>
        <ExplicativeText type={reportType} />
        <div className='st-report-drawer__form' data-testid='report-detailed-consolidated-form'>
          <Form layout='vertical'>
            <Form.Item data-testid='report-detailed-consolidated-seasons-container' label={`${t('pages.tasks.task_create.season')}`}>
              {displaySeason}
            </Form.Item>
            <Form.Item data-testid='report-detailed-consolidated-type-timerange' label={t('modals.timeline_xray_report.fields.time_range')}>
              <RangePicker
                value={[timeRangeFrom, timeRangeTo]}
                format='L'
                size='large'
                separator='—'
                onChange={handleTimeRange}
                allowClear={false}
                disabledDate={disabledDate}
              />
            </Form.Item>
            <Form.Item
              data-testid='report-detailed-consolidated-properties-container'
              label={t('modals.timeline_xray_report.fields.property')}>
              {!isPropertiesLoaded && (
                <div className='st-report-drawer__loading'>
                  <Spin indicator={<Icon type='loading' style={{ fontSize: 16 }} spin />} />
                </div>
              )}
              {propertiesFiltered && (
                <Select
                  size='large'
                  value={selectedProperty}
                  onChange={handleSelectedProperty}
                  open={openProperty}
                  onDropdownVisibleChange={handleOpenProperty}
                  mode='multiple'
                  onSearch={handlePropertySearch}
                  showSearch
                  filterOption={false}
                  data-testid='report-detailed-consolidated-properties'>
                  <Select.Option value='all' data-testid='report-detailed-consolidated-properties-all'>
                    {t('modals.timeline_xray_report.fields.property_all')}
                  </Select.Option>
                  {propertiesFiltered.map(propertyObj => (
                    <Select.Option
                      key={propertyObj.id}
                      value={propertyObj.id}
                      data-testid={`report-detailed-consolidated-properties-${propertyObj.id}`}>
                      {propertyObj.name}
                    </Select.Option>
                  ))}
                </Select>
              )}
            </Form.Item>
            <Form.Item
              data-testid='report-detailed-consolidated-phenomenons-category-container'
              label={t('modals.timeline_xray_report.fields.phenomena_type')}>
              {displayPhenomenonCategory}
            </Form.Item>
            <Form.Item
              data-testid='report-detailed-consolidated-phenomenons-container'
              label={t('modals.timeline_xray_report.fields.phenomena')}>
              {phenomenon && (
                <PhenomenaSingleSelect
                  phenomenons={phenomenon}
                  selected={selectedPhenomenon}
                  disabled={!isPhenomenonSelectEnabled}
                  handleSetSelected={setSelectedPhenomenon}
                />
              )}
            </Form.Item>
            <Form.Item
              data-testid='report-detailed-consolidated-options-of-report'
              label={t('modals.timeline_xray_report.fields.options_of_report')}>
              <Checkbox checked={seasonLayout} onChange={handleSeasonLayout}>
                {t('modals.timeline_xray_report.fields.generate_report_in_season_format')}
              </Checkbox>
            </Form.Item>
          </Form>
        </div>
        <div className='st-report-drawer__actions'>
          <Popconfirm
            placement='bottomRight'
            icon={
              <div className='st-report-drawer__confirm-icon-container'>
                <SvgAlertGlyph fill='#F74141' />
              </div>
            }
            overlayClassName='st-report-drawer__confirm'
            title={
              <div className='st-report-drawer__confirm-delete'>
                <STTypo type={TypeOptions.P1}>{`${t('report.back')}`}</STTypo>
              </div>
            }
            onConfirm={() => closeDrawer()}
            okText={t('general.complete')}
            okButtonProps={{ style: { backgroundColor: '#F74141', border: 'none', borderRadius: '4px', paddingTop: '2px' } }}
            cancelButtonProps={{
              style: {
                backgroundColor: '#F2F4F6',
                border: 'none',
                borderRadius: '4px',
                paddingTop: '2px',
                color: COLOR_OPTIONS.PRIMARY
              }
            }}
            cancelText={t('general.back')}>
            <Button
              type='ghost'
              size='default'
              className='st-report-drawer_actions-cancel'
              data-testid='report-detailed-consolidated-cancel'>
              {t('general.cancel')}
            </Button>
          </Popconfirm>
          <Button
            type='primary'
            size='default'
            className='st-report-drawer_actions-submit'
            onClick={generateReport}
            disabled={isConfirmButtonDisabled()}
            loading={isLoading}
            data-testid='report-detailed-consolidated-submit'>
            {t('report.action')}
          </Button>
        </div>
      </div>
    </Drawer>
  );
};

export default STReportDetailedConsolidatedDrawer;
