import { getOrganizationInfo } from 'core/services/auth/auth.service';
import type { Action, UUID } from 'core/utils/basic.models';
import type { ActionsObservable, StateObservable } from 'redux-observable';
import { ofType } from 'redux-observable';
import type { AppState } from 'redux/app-state';
import { concat, of } from 'rxjs';
import { catchError, concatMap, map, mergeMap } from 'rxjs/operators';
import sentryService from 'sentry/service';
import { SetPermissionFlags } from '../../core/services/auth/auth.actions';
import { getLicensingStatus, getPermissionFlags } from '../../core/services/auth/auth.functions';
import type {
  LoadCompanies,
  LoadCurrentWorkspaceId,
  LoadOrgLicensingStatus,
  LoadWorkspaceLicensingStatus,
  ReloadCompanies
} from './company.actions';
import {
  CompanyActionsTypes,
  LoadCompaniesFailure,
  LoadCompaniesSuccess,
  LoadCurrentWorkspaceIdFailure,
  LoadCurrentWorkspaceIdSuccess,
  LoadOrgLicensingStatusFailure,
  LoadOrgLicensingStatusSuccess
} from './company.actions';
import type { ReloadCompaniesModel } from './company.models';
import { getCompaniesWithActiveContracts, getOrgLicensingStatus, getWorkspaceLicensingStatus } from './company.service';

export const handleLoadCompanies = (action$: ActionsObservable<Action<typeof LoadCompanies>>) =>
  action$.pipe(
    ofType(CompanyActionsTypes.LOAD_COMPANIES),
    mergeMap(() => {
      return getCompaniesWithActiveContracts().pipe(
        map(response => response.data),
        map(companyPage => LoadCompaniesSuccess(companyPage)),
        catchError(error => {
          sentryService.captureException(error, {
            fileName: 'company.epics.ts',
            method: 'handleLoadCompanies',
            description: 'Error to get companies with active contracts'
          });
          return of(LoadCompaniesFailure(error));
        })
      );
    })
  );

export const handleLoadOrgLicensingStatus = (
  action$: ActionsObservable<ReturnType<typeof LoadOrgLicensingStatus>>,
  state$: StateObservable<AppState>
) =>
  action$.pipe(
    ofType(CompanyActionsTypes.LOAD_LICENSING_STATUS),
    map(action => action.payload),
    concatMap((query: UUID) =>
      getOrgLicensingStatus(query).pipe(
        concatMap(companyPage =>
          concat([
            SetPermissionFlags(getPermissionFlags(companyPage, state$.value.uiState.auth.permissionFlags)),
            LoadOrgLicensingStatusSuccess(getLicensingStatus(companyPage))
          ])
        ),
        catchError((error: string) => {
          return of(LoadOrgLicensingStatusFailure(error));
        })
      )
    )
  );

export const handleLoadWorkspaceLicensingStatus = (
  action$: ActionsObservable<ReturnType<typeof LoadWorkspaceLicensingStatus>>,
  state$: StateObservable<AppState>
) =>
  action$.pipe(
    ofType(CompanyActionsTypes.LOAD_LICENSING_STATUS_BY_WORKSPACE),
    map(action => action.payload),
    concatMap((query: UUID) =>
      getWorkspaceLicensingStatus(query).pipe(
        concatMap(workspacePage => {
          return concat([
            SetPermissionFlags(getPermissionFlags(workspacePage, state$.value.uiState.auth.permissionFlags)),
            LoadOrgLicensingStatusSuccess(getLicensingStatus(workspacePage))
          ]);
        }),
        catchError((error: string) => {
          return of(LoadOrgLicensingStatusFailure(error));
        })
      )
    )
  );

export const handleLoadCurrentWorkspaceId = (action$: ActionsObservable<ReturnType<typeof LoadCurrentWorkspaceId>>) =>
  action$.pipe(
    ofType(CompanyActionsTypes.LOAD_CURRENT_WORKSPACE_ID),
    map(action => action.payload),
    concatMap((orgId: UUID) =>
      getOrganizationInfo(orgId).pipe(
        map(response => response.data),
        map(orgInfo => LoadCurrentWorkspaceIdSuccess(orgInfo.workspace_id)),
        catchError((error: string) => of(LoadCurrentWorkspaceIdFailure(error)))
      )
    )
  );

export const handleReloadCompanies = (action$: ActionsObservable<ReturnType<typeof ReloadCompanies>>) =>
  action$.pipe(
    ofType(CompanyActionsTypes.RELOAD_COMPANIES),
    map((action: Action<ReloadCompaniesModel>) => action.payload),
    mergeMap(() => {
      return getCompaniesWithActiveContracts().pipe(
        map(response => response.data),
        map(companyPage => LoadCompaniesSuccess(companyPage)),
        catchError(error => {
          sentryService.captureException(error, {
            fileName: 'company.epics.ts',
            method: 'handleReloadCompanies',
            description: 'Error to reload to get companies with active contracts'
          });
          return of(LoadCompaniesFailure(error));
        })
      );
    })
  );
