import type { ArgsProps } from 'antd/lib/notification';
import type { ExternalIntegrationSource, StaticPointCreation, StaticPointType } from 'entities/static-points/static-points.models';
import type {
  LoadSeveritiesRequestType,
  LoadSeveritiesSuccessParamsType,
  Station,
  StationActionType,
  UpsertStationSuccessCallbacks
} from './stations.models';

export const StationsActionsTypes = {
  LOAD_STATIONS: '[Stations] Load Stations',
  LOAD_STATIONS_SUCCESS: '[Stations] Load Stations Success',
  LOAD_STATIONS_FAILURE: '[Stations] Load Stations Failure',
  LOAD_SEVERITIES: '[Stations Severities] Load Stations Severities',
  NO_SEVERITIES_TO_LOAD: '[Stations Severities] No Stations Severities To Load',
  LOAD_SEVERITIES_SUCCESS: '[Stations Severities] Load Stations Success',
  LOAD_SEVERITIES_FAILURE: '[Stations Severities] Load Stations Failure',
  CREATE_STATION_SUCCESS: '[Stations] Create Station Success',
  DELETE_STATION: '[Stations] Delete Station',
  DELETE_STATION_SUCCESS: '[Stations] Delete Stations Success',
  TOGGLE_STATION: '[Stations] Toggle (enable/disable) Station',
  TOGGLE_STATION_LOADING: '[Stations] Loading Toggle (enable/disable) Station',
  TOGGLE_STATION_SUCCESS: '[Stations] Toggle (enable/disable) Station Success',
  EDIT_STATION: '[Stations] Edit Station',
  EDIT_STATION_SUCCESS: '[Stations] Edit Stations Success',
  EDIT_STATION_FAILED: '[Stations] Edit Stations Failed',
  DELETE_TRAPVIEW_DEVICE: '[Stations] Delete Trapview Device'
} as const;

export const LoadStations = (propertyId: string) => ({
  type: StationsActionsTypes.LOAD_STATIONS,
  payload: { propertyId }
});

export const LoadStationsSuccess = (stations: Station[]) => ({
  type: StationsActionsTypes.LOAD_STATIONS_SUCCESS,
  payload: stations
});

export const LoadStationsFailure = (error: string) => ({
  type: StationsActionsTypes.LOAD_STATIONS_FAILURE,
  payload: error
});

export const LoadAnalyticResultsHistogram = ({
  startDate,
  endDate,
  templateId,
  ids,
  allActions,
  clearCache
}: LoadSeveritiesRequestType) => ({
  type: StationsActionsTypes.LOAD_SEVERITIES,
  payload: { startDate, endDate, templateId, ids, allActions, clearCache }
});

export const LoadAnalyticResultsHistogramSuccess = ({
  severities,
  allActions,
  allIds,
  clearCache,
  allMonitoring
}: LoadSeveritiesSuccessParamsType) => ({
  type: StationsActionsTypes.LOAD_SEVERITIES_SUCCESS,
  payload: { severities, allActions, allIds, clearCache, allMonitoring }
});

export const NoAnalyticResultsHistogramToLoad = (allActions: LoadSeveritiesSuccessParamsType) => ({
  type: StationsActionsTypes.NO_SEVERITIES_TO_LOAD,
  payload: allActions
});

export const LoadAnalyticResultsHistogramFailure = (error: string) => ({
  type: StationsActionsTypes.LOAD_SEVERITIES_FAILURE,
  payload: error
});

export const CreateStationSuccess = (station: Station, callback: UpsertStationSuccessCallbacks) => ({
  type: StationsActionsTypes.CREATE_STATION_SUCCESS,
  payload: { station, callback }
});

export const DeleteStation = (
  stationType: StaticPointType,
  stationId: string,
  integrationSource: ExternalIntegrationSource,
  callback: unknown
) => ({
  type: StationsActionsTypes.DELETE_STATION,
  payload: { stationType, stationId, integrationSource, callback }
});

export const DeleteStationSuccess = (
  stationType: StaticPointType,
  stationId: string,
  integrationSource: ExternalIntegrationSource,
  callback: unknown
) => ({
  type: StationsActionsTypes.DELETE_STATION_SUCCESS,
  payload: { stationType, stationId, integrationSource, callback }
});

export const ToggleStation = (
  stationType: StaticPointType,
  stationId: string,
  actionType: StationActionType,
  autoCreateTask: boolean,
  callback: unknown
) => ({
  type: StationsActionsTypes.TOGGLE_STATION,
  payload: { stationType, stationId, actionType, autoCreateTask, callback }
});

export const ToggleStationLoading = (payload: { isLoading: boolean; stationId: string }) => ({
  type: StationsActionsTypes.TOGGLE_STATION_LOADING,
  payload
});

export const ToggleStationSuccess = (station: Station) => ({
  type: StationsActionsTypes.TOGGLE_STATION_SUCCESS,
  payload: station
});

export const EditStation = (stationType: StaticPointType, station: StaticPointCreation, callback: unknown) => ({
  type: StationsActionsTypes.EDIT_STATION,
  payload: { stationType, station, callback }
});

export const EditStationSuccess = (
  station: Station,
  callback: {
    notification: {
      success: ArgsProps;
    };
    tracking: {
      editedTrap: () => void;
    };
    clearDrawerAndSelectStation: (station?: Station) => void;
  }
) => ({
  type: StationsActionsTypes.EDIT_STATION_SUCCESS,
  payload: { station, callback }
});

export const EditStationFailed = () => ({
  type: StationsActionsTypes.EDIT_STATION_FAILED
});

export const DeleteTrapViewDevice = () => ({
  type: StationsActionsTypes.DELETE_TRAPVIEW_DEVICE
});
