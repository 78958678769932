import { notification } from 'antd';
import { NEMADIGITAL_APP_ID } from 'config/constants';
import { getSelectedSeasons } from 'core/core.reducer';
import { getSystemFlags } from 'core/core.selectors';
import { Entitlements } from 'core/shared/enums/entitlements.enum';
import useSegmentTracking from 'core/utils/segment/useSegmentTracking';
import type { AnalyticsInfo } from 'core/utils/segment/user-events.types';
import type { LicensingStatusAppsPlanEntitlementResponse } from 'entities/company/company.models';
import { getAppFromAppListByAppId, getCurrentWorkspaceId } from 'entities/company/company.selector';
import type { Season } from 'entities/season/season.models';
import { selectAllSeasons } from 'entities/season/season.reducer';
import { type Dictionary } from 'lodash';
import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router';
import type { AppState } from 'redux/app-state';
import { JobStatusEnum } from 'syngenta-digital-react-cropwise-nemadigital';
import type { RequestCreateJob } from 'syngenta-digital-react-cropwise-nemadigital/dist/module/nema-config/types/nema-config.types';
import {
  convertNemaDigitalJobToRequestCreateJob,
  convertRequestCreateJobToNemaDigitalJob,
  findJobInList,
  removeCropsWithoutCropType,
  selectedSoybeanSeasons
} from './nemadigital.domain';
import type { AvailableFields, FieldCropInfoQuery, NemaDigitalJob, NemaDigitalJobSuccess } from './nemadigital.model';
import { useNemaDigitalAvailableFields, useNemaDigitalFieldCropInfo, useNemaDigitalJobsByPropertyId } from './nemadigital.query';
import { anticipateNemaDigitalJob, createNemaDigitalJob, editNemaDigitalJob, ERROR_404 } from './nemadigital.service';
import type { NemaDigitalStore } from './nemadigital.zustand';
import { useNemaDigitalStore } from './nemadigital.zustand';

interface UseNemadigitalReturn {
  isLoadingCreate: boolean;
  isLoadingAvailableFields: boolean;
  isLoadingJob: boolean;
  companyId?: string;
  propertyId?: string;
  currentSoySeason?: Season;
  cropsByField: FieldCropInfoQuery[];
  availableFields?: AvailableFields[];
  contractId?: string;
  createJob: (requestCreateJob: RequestCreateJob) => void;
  anticipateJob: () => void;
  previousRequestCreateJob?: RequestCreateJob;
  currentJob?: NemaDigitalJob;
  workspaceId?: string;
  flags: Dictionary<string | boolean> | null;
  goBack: () => void;
  unauthorized: boolean;
  showEndSeasonButton?: boolean;
  cropIdentificationEntitlement?: boolean;
  setShowNemaCdlConfig?: (value: boolean) => void;
  showNemaCdlConfig: boolean;
  isMissingFieldsToConfigureCdl: boolean;
}

export const useNemadigital = (disabledAvailableFields?: boolean, disabledJobs?: boolean): UseNemadigitalReturn => {
  const navigate = useNavigate();
  const [t] = useTranslation();
  const [isLoadingCreate, setIsLoadingCreate] = useState(false);
  const [showNemaCdlConfig, setShowNemaCdlConfig] = useState(false);
  const workspaceId = useSelector((state: AppState) => getCurrentWorkspaceId(state));
  const allSeasons = useSelector((state: AppState) => selectAllSeasons(state?.entities?.season));
  const companies = useSelector((state: AppState) => state?.entities?.company?.entities);
  const idSelectedSeason = useSelector((state: AppState) => getSelectedSeasons(state));
  const properties = useSelector((state: AppState) => state?.entities?.property?.entities);
  const nemaContract = useSelector((state: AppState) =>
    getAppFromAppListByAppId(state?.entities?.company?.licensingStatus?.apps ?? [], NEMADIGITAL_APP_ID)
  );
  const flags = useSelector(getSystemFlags);
  const { companyId, propertyId } = useParams();
  const { data: jobs, isLoading: isLoadingJob, error } = useNemaDigitalJobsByPropertyId(!!disabledJobs);
  const { data: availableFields, isLoading: isLoadingAvailableFields } = useNemaDigitalAvailableFields(
    !!disabledAvailableFields,
    workspaceId
  );

  const hasCropIdentificationEntitlement = nemaContract?.plan?.entitlements?.some(
    (entitlement: LicensingStatusAppsPlanEntitlementResponse) => entitlement.key === Entitlements.NEMA_CROP_IDENTIFICATION_ENABLED
  );

  const { currentJobId }: NemaDigitalStore = useNemaDigitalStore((state: NemaDigitalStore) => ({
    currentJobId: state.currentJobId
  }));

  const useFieldCropInfoEnabled = !!hasCropIdentificationEntitlement && showNemaCdlConfig;

  const currentSoySeason: Season | undefined = useMemo(
    () =>
      selectedSoybeanSeasons(
        allSeasons,
        idSelectedSeason?.map(item => item)
      ),
    [allSeasons, idSelectedSeason]
  );

  const currentJob = useMemo(() => findJobInList(jobs, currentJobId, currentSoySeason), [jobs, currentJobId, currentSoySeason]);

  const cropsByField = useNemaDigitalFieldCropInfo(availableFields?.map(field => field?.id) ?? [], useFieldCropInfoEnabled, currentJob);

  const isMissingFieldsToConfigureCdl = useMemo(() => {
    if (!currentJob?.job_params?.length || !cropsByField?.length) {
      return true;
    }

    const jobAoiIds = currentJob?.job_params?.map(param => param?.aoi_id);

    return cropsByField?.some(field => field?.fieldId && !jobAoiIds?.includes(field?.fieldId));
  }, [currentJob, cropsByField]);

  const showEndSeasonButton = useMemo(() => {
    return (
      !!currentJob?.job_id &&
      currentJob?.pre_season_execution?.status === JobStatusEnum.FINISHED &&
      !currentJob?.post_season_execution?.status
    );
  }, [currentJob]);

  const payload: AnalyticsInfo = {
    companyId,
    companyName: companies ? companies[companyId!]?.name : '',
    propertyId,
    propertyName: properties ? properties[propertyId!]?.name : ''
  };

  const handleGoToBack = useCallback(() => {
    navigate(`/company/${companyId}/property/${propertyId}/timeline`);
  }, [companyId, navigate, propertyId]);

  const segmentTracking = useSegmentTracking();

  const handleNotification = useCallback(
    (resultRequest: NemaDigitalJobSuccess | undefined) => {
      if (resultRequest) {
        notification.success({
          message: t('pages.nemadigital.notification.success_title'),
          description: t('pages.nemadigital.notification.success_description')
        });
        handleGoToBack();
      } else {
        notification.error({
          message: t('pages.nemadigital.notification.error_title'),
          description: t('pages.nemadigital.notification.error_description')
        });
      }
    },
    [handleGoToBack, t]
  );

  const createJob = (requestCreateJob: RequestCreateJob): void => {
    void (async () => {
      setIsLoadingCreate(true);
      let resultRequest: NemaDigitalJobSuccess | undefined;
      
      const requestCreateJobWithoutDuplicates = removeCropsWithoutCropType(requestCreateJob);

      if (currentJob) {
        segmentTracking.track(`Nemadigital Configuration - edit configuration`, payload);
        const bodyEditNema = convertRequestCreateJobToNemaDigitalJob(requestCreateJobWithoutDuplicates, currentJob);
        resultRequest = await editNemaDigitalJob(bodyEditNema);
      } else {
        segmentTracking.track(`Nemadigital Configuration - create configuration`, payload);
        resultRequest = await createNemaDigitalJob(requestCreateJobWithoutDuplicates);
      }
      setIsLoadingCreate(false);

      handleNotification(resultRequest);
    })();
  };

  const anticipateJob = (): void => {
    void (async () => {
      segmentTracking.track(`Nemadigital Configuration - Anticipate configuration`, payload);

      const resultRequest = await anticipateNemaDigitalJob(currentJob?.job_id ?? 0);

      handleNotification(resultRequest);
    })();
  };

  return {
    isLoadingCreate,
    isLoadingJob,
    currentSoySeason,
    contractId: nemaContract?.contract?.id,
    createJob,
    cropsByField,
    anticipateJob,
    showEndSeasonButton,
    previousRequestCreateJob: convertNemaDigitalJobToRequestCreateJob(workspaceId ?? '', currentJob),
    currentJob,
    companyId,
    propertyId,
    isLoadingAvailableFields,
    availableFields,
    workspaceId: workspaceId ?? '',
    flags,
    goBack: handleGoToBack,
    unauthorized: !!error?.message?.includes(ERROR_404),
    cropIdentificationEntitlement: hasCropIdentificationEntitlement,
    setShowNemaCdlConfig,
    showNemaCdlConfig: showNemaCdlConfig,
    isMissingFieldsToConfigureCdl
  };
};
