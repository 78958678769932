import { COLOR_OPTIONS } from 'config/style';
import styled, { css } from 'styled-components';

const CropIconContainer = styled.div<{ cropEnded?: boolean; withBackground: boolean; backgroundColor?: string; smallIcon?: boolean }>(
  ({ withBackground, cropEnded, backgroundColor, smallIcon }) => css`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 24px;
    height: 24px;
    border-radius: 50%;

    ${withBackground &&
    css`
      background-color: #515253;
      color: #fff;
    `}

    ${cropEnded &&
    css`
      background-color: #c1c5c8;
    `}

    ${backgroundColor &&
    css`
      background-color: ${backgroundColor};
    `}

    ${smallIcon &&
    css`
      width: 16px;
      height: 16px;
    `}
      .cw-svg {
      width: 16px;
      height: 14px;
      path {
        fill: ${withBackground ? COLOR_OPTIONS.WHITE : COLOR_OPTIONS.NEUTRAL_60};
      }
    }
  `
);

export default CropIconContainer;
