import { create } from 'zustand';

export interface NemaDigitalStore {
  currentJobId?: number;

  setCurrentJobId?: (jobId: NemaDigitalStore['currentJobId']) => void;
}

export const useNemaDigitalStore = create<NemaDigitalStore>()(set => ({
  currentJobId: undefined,

  setCurrentJobId: currentJobId => set({ currentJobId })
}));
