import type { CropInfo } from '../shared/crop-icon/crop-icon.queries.ts';
import { getCurrentLanguage } from './functions';
import type { I18nMap } from './language.models';

export const getTranslationValue = (dict: I18nMap): string =>
  dict.localized_strings[getCurrentLanguage()]
    ? dict.localized_strings[getCurrentLanguage()]
    : dict.localized_strings[Object.keys(dict.localized_strings)[0]];

export const getLocalizedCropName = (crop: CropInfo) => {
  const cropName = crop?.name?.find(name => name.language.toLowerCase() === getCurrentLanguage().toLowerCase())?.name;
  const fallbackCropName = crop?.name?.find(name => name.language.toLowerCase() === 'en')?.name;

  if (!cropName) {
    return fallbackCropName ?? crop.name[0].name;
  }

  return cropName;
};
