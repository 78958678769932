import { useQuery } from '@tanstack/react-query';
import { TEN_MINUTES } from 'config/constants';
import { getPermissionFlags as getPermissionFlagsSelector } from 'core/core.selectors';
import { SetPermissionFlags } from 'core/services/auth/auth.actions';
import { getLicensingStatus, getPermissionFlags } from 'core/services/auth/auth.functions';
import { useWorkspaceMatch } from 'core/utils/hooks/usePathMatches';
import { LoadCurrentWorkspaceIdSuccess, LoadOrgLicensingStatusSuccess } from 'entities/company/company.actions';
import { getCurrentWorkspaceId } from 'entities/company/company.selector';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { LicensingStatusQueryType } from './licensing.model';
import { getLicensingStatusByWorkspace } from './licensing.services';

export const useGetLicensingStatusByWorkspace = () => {
  const workspaceId = useSelector(getCurrentWorkspaceId);
  const organizationMatch = useWorkspaceMatch();
  const permissionFlags = useSelector(getPermissionFlagsSelector);
  const dispatch = useDispatch();

  useEffect(() => {
    if (organizationMatch?.params?.workspaceId && !workspaceId) {
      dispatch(LoadCurrentWorkspaceIdSuccess(organizationMatch.params.workspaceId));
    }
  }, [organizationMatch, workspaceId, dispatch]);

  const { data } = useQuery(
    [LicensingStatusQueryType.GET_LICENSING_STATUS_BY_WORKSPACE, workspaceId],
    () => getLicensingStatusByWorkspace(workspaceId!),
    { staleTime: TEN_MINUTES, enabled: !!workspaceId }
  );

  useEffect(() => {
    if (data) {
      dispatch(SetPermissionFlags(getPermissionFlags(data, permissionFlags)));
      dispatch(LoadOrgLicensingStatusSuccess(getLicensingStatus(data)));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);
};
