import type { SVGProps } from 'react';

const MyCropwise = (props: SVGProps<SVGSVGElement>) => (
  <svg width='24' height='24' viewBox='0 0 24 24' fill={props.fill} xmlns='http://www.w3.org/2000/svg'>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M12 6L10 4H4C2.9 4 2.01 4.9 2.01 6L2 18C2 19.1 2.9 20 4 20H20C21.1 20 22 19.1 22 18V8C22 6.9 21.1 6 20 6H12ZM4 18H20V8H12C11.4696 8 10.9609 7.78929 10.5858 7.41421L9.17157 6H4.01L4 18Z'
      fill={props.fill}></path>
    <path
      fill={props.fill}
      fillRule='evenodd'
      clipRule='evenodd'
      d='M13.828 10.2262L13.8957 10.2136L13.9132 10.2104C14.5273 10.0977 15.0588 10.0279 15.281 10C15.1318 10.2423 15.0594 10.4066 14.9688 10.6121L14.9447 10.6667C14.8713 10.8367 14.7952 11.0421 14.7176 11.2911C14.6207 11.6022 14.5216 11.9813 14.4224 12.4449C14.4103 12.502 14.3981 12.5611 14.3855 12.622C14.2604 13.2278 14.1003 14.0035 13.6784 14.5714C13.1471 15.2887 12.5593 15.5848 11.7126 15.7307C11.1471 15.8274 10.6144 15.8661 10.1873 15.8958L10.1357 15.8994C9.8575 15.9187 9.59554 15.9369 9.31378 16C9.2269 15.688 9.15804 15.3862 9.11099 15.0942C9.09633 15.0032 9.08379 14.9132 9.07348 14.8242C9.01711 14.337 9.02745 13.8779 9.1233 13.4449C9.28401 12.7232 9.60693 12.2247 10.098 11.7708C10.8569 11.0685 11.9671 10.6354 13.1784 10.3601C13.3973 10.3105 13.6168 10.2659 13.828 10.2262ZM13.4017 11.54C12.2877 11.7976 11.4366 12.1672 10.9133 12.6515C10.5809 12.9587 10.3968 13.2482 10.295 13.705C10.2335 13.9834 10.2216 14.3055 10.2659 14.6872C10.6507 14.6593 11.0721 14.6227 11.5098 14.5479C11.8461 14.4899 12.0595 14.414 12.2198 14.3227C12.373 14.2354 12.5323 14.1029 12.7143 13.8571L12.7153 13.8558C12.9711 13.5115 13.08 12.9951 13.2236 12.3146L13.2492 12.1939C13.2993 11.9596 13.3501 11.7421 13.4017 11.54Z'></path>
  </svg>
);

export default MyCropwise;
